import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
      color: "black",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab6 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>6. Return of Funds by the School</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  The school must return the unearned aid for which the school is responsible (Box O) by repaying funds to the following sources, in order, up to the total net amount disbursed from each source.
                  
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
              <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Programs</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Amount for School to Return</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          1. Unsubsidized Direct Loan
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="unsubDirectLoanSchoolReturn"
                            key="unsubDirectLoanSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.unsubDirectLoanSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "unsubDirectLoanSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.unsubDirectLoanSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.unsubDirectLoanSchoolReturn
                                ? (data.errors.unsubDirectLoanSchoolReturn as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          2. Subsidized Direct Loan
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subDirectLoanSchoolReturn"
                            key="subDirectLoanSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subDirectLoanSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subDirectLoanSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.subDirectLoanSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subDirectLoanSchoolReturn
                                ? (data.errors.subDirectLoanSchoolReturn as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          3. Direct Grad PLUS Loan
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directGraduatePlusLoanSchoolReturn"
                            key="directGraduatePlusLoanSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.directGraduatePlusLoanSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directGraduatePlusLoanSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.directGraduatePlusLoanSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directGraduatePlusLoanSchoolReturn
                                ? (
                                    data.errors
                                      .directGraduatePlusLoanSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          4. Direct Parent PLUS Loan
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directParentPlusLoanSchoolReturn"
                            key="directParentPlusLoanSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.directParentPlusLoanSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directParentPlusLoanSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.directParentPlusLoanSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directParentPlusLoanSchoolReturn
                                ? (
                                    data.errors
                                      .directParentPlusLoanSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total loans the school must return</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="boxPResult"
                            key="boxPResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxPResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "boxPResult",
                                v.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>=P.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxPResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxPResult
                                ? (
                                    data.errors
                                      .boxPResult as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          5. Pell Grant
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantSchoolReturn"
                            key="pellGrantSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.pellGrantSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantSchoolReturn
                                ? (
                                    data.errors
                                      .pellGrantSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          6.  Iraq Afghanistan Service Grant
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantSchoolReturn"
                            key="iraqAfgGrantSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.iraqAfgGrantSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantSchoolReturn
                                ? (
                                    data.errors
                                      .iraqAfgGrantSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          7. FSEOG
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogSchoolReturn"
                            key="fseogSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.fseogSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogSchoolReturn
                                ? (
                                    data.errors
                                      .fseogSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          8. TEACH Grant
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantSchoolReturn"
                            key="teachGrantSchoolReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.teachGrantSchoolReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantSchoolReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantSchoolReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantSchoolReturn
                                ? (
                                    data.errors
                                      .teachGrantSchoolReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab6;
