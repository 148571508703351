import React, { Fragment } from "react";
import {
  makeStyles,
  createStyles,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { ChargeDefinitionPeriods } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import { Save, Cancel, Edit, Delete } from "@material-ui/icons";
import TransactionCodeAutoComplete from "../../../components/AutoComplete/TransactionCodeAutoComplete";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { FieldError, useForm, FormContext } from "react-hook-form";
import PeriodTypeAutoComplete from "../../../components/AutoComplete/PeriodTypeAutoComplete";

type IncrementDetailsGridRowProps = {
  row: ChargeDefinitionPeriods;
  readonly?: boolean;
  updateRow: (property: string, rowId: string, newValue: any) => void;
  handleEdit:(rowId:string) => void;
  handleSaveClick:(rowId:string) => void;
  handleCancel:(rowId:string) => void;
  removeRow: (rowId: string) => void;
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    readOnlyAutoComplete: {
      pointerEvents: "none",
      "& .MuiAutocomplete-endAdornment": { display: "none" },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInput-underline:before": { borderBottom: "none" },
      "& .MuiInput-underline:after": { borderBottom: "none" },
    },
  })
);

const IncrementDetailsGridRow = (props: IncrementDetailsGridRowProps) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });
  let incrementValueComponent,
    chargeAmountComponent,
    PeriodTypeComponent,
    transactionCodeComponent,
    optionsComponent;

  const handleSaveClick = () => {
    data.triggerValidation().then((success) => {
      if (success) {
        props.handleSaveClick(props.row.incrementId);
      }
    });
  };

  const handleEditClick = () => {
    props.handleEdit(props.row.incrementId);
  };

  const handleDeleteClick = (rowId: string) => {
    props.removeRow(rowId);
  };

  const handleModelChange = (fieldId: string, value: any) => {
    props.updateRow(fieldId, props.row.incrementId, value);
  };

  const handleCancel = (
    rowId:string
  ) => {
    props.handleCancel(rowId);
  };

  if (props.row.isEditModeOn) {
    PeriodTypeComponent = (
      <React.Fragment>
        <PeriodTypeAutoComplete
          id="periodTypeId"
          name="periodTypeId"
          key={"periodTypeId"}
          hideLabel={true}
          valueFilter={
            props.row.periodTypeId !== undefined
              ? { key: "value", values: [props.row.periodTypeId] }
              : undefined
          }
          filterHandle={(e: any) => {
            const newValue = e == null ? null : e.value;
            handleModelChange("periodTypeId", newValue);
          }}
          error={!!data.errors.periodTypeId}
          inputRef={data.register({ required: "Period Type Required" })}
          helperText={
            data.errors.periodTypeId
              ? (data.errors.periodTypeId as FieldError).message
              : null
          }
        />
      </React.Fragment>
    );

    transactionCodeComponent = (
      <React.Fragment>
        <TransactionCodeAutoComplete
          readOnly={false}
          id="transCodeId"
          name="transCodeId"
          key={"transCodeId"}
          hideLabel={true}
          disableClearable={false}
          disabled={false}
          filterHandle={(e: any) => {
            handleModelChange("transCodeId", e ? e.value : null);
            handleModelChange("transactionCode", e ? e.text : null);
          }}
          error={!!data.errors.transCodeId}
          inputRef={data.register({ required: "Transaction Code Required" })}
          helperText={
            data.errors.transCodeId
              ? (data.errors.transCodeId as FieldError).message
              : null
          }
          valueFilter={
            props.row.transCodeId !== undefined && props.row.transCodeId !== null
              ? {
                  key: "value",
                  values: [props.row.transCodeId],
                }
              : undefined
          }
        />
      </React.Fragment>
    );
    chargeAmountComponent = (
      <React.Fragment>
        <InputField
          type={InputType.NUMBER}
          key="chargeAmount"
          id="chargeAmount"
          label=""
          name="chargeAmount"
          defaultValue={props.row.chargeAmount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleModelChange("chargeAmount", e.target.value);
          }}
          decimal
          decimalPlaces={2}
          error={!!data.errors.chargeAmount}
          inputRef={data.register({
            validate: {
              greaterThan0: (value) =>
                Number(value) > 0 || "Charge Amount should be greater than 0",
            },
          })}
          helperText={
            data.errors.chargeAmount
              ? (data.errors.chargeAmount as FieldError).message
              : undefined
          }
        />
      </React.Fragment>
    );

    incrementValueComponent = (
      <React.Fragment>
        <InputField
          type={InputType.NUMBER}
          key={"incrementValue"}
          id={"incrementValue"}
          label=""
          name={"incrementValue"}
          defaultValue={
            props.row.incrementValue
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleModelChange("incrementValue", e.target.value);
          }}
          decimal
          decimalPlaces={2}
          error={
            !!data.errors.incrementValue
          }
          inputRef={data.register({
            validate: {
              greaterThan0: (value) =>
                Number(value) > 0 || "Increment should be greater than 0",
            },
          })}
          helperText={
            data.errors.incrementValue
                ? (data.errors.incrementValue as FieldError).message
                : undefined
          }
        />
      </React.Fragment>
    );
    optionsComponent = (
      <Fragment>
        <Button
          startIcon={<Save />}
          onClick={(e: any) => {
            handleSaveClick();
          }}
        ></Button>
        <Button
          startIcon={<Cancel />}
          onClick={(e: any) => {
            handleCancel(props.row.incrementId);
          }}
        ></Button>
      </Fragment>
    );
  } else {
    transactionCodeComponent = (
      <TransactionCodeAutoComplete
        id="transCodeId"
        readOnly={true}
        hideLabel={true}
        className={classes.readOnlyAutoComplete}
        valueFilter={
          props.row.transCodeId
            ? { key: "value", values: [props.row.transCodeId] }
            : undefined
        }
      />
    );
    chargeAmountComponent = (
      <React.Fragment>
        {Number(props.row.chargeAmount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </React.Fragment>
    );
    PeriodTypeComponent = (
      <PeriodTypeAutoComplete
        id="periodTypeId"
        readOnly={true}
        hideLabel={true}
        className={classes.readOnlyAutoComplete}
        valueFilter={
          props.row.periodTypeId !== undefined
            ? { key: "value", values: [props.row.periodTypeId] }
            : undefined
        }
      />
    );

    incrementValueComponent = (
      <React.Fragment>
        {props.row.incrementValue}
      </React.Fragment>
    );
    optionsComponent = (
      <Fragment>
        <Button
          startIcon={<Edit />}
          onClick={(e: any) => {
            handleEditClick();
          }}
        ></Button>
        <Button
          startIcon={<Delete />}
          onClick={(e: any) => {
            handleDeleteClick(props.row.incrementId);
          }}
        ></Button>
      </Fragment>
    );
  }
  return (
    <FormContext {...data}>
      <React.Fragment>
        <TableRow key={"increment_" + props.row.incrementId}>
          <TableCell>{incrementValueComponent}</TableCell>
          <TableCell>{chargeAmountComponent}</TableCell>
          <TableCell>{PeriodTypeComponent}</TableCell>
          <TableCell>{transactionCodeComponent}</TableCell>
          <TableCell>
            {props?.readonly == true ? "" : optionsComponent}
          </TableCell>
        </TableRow>
      </React.Fragment>
    </FormContext>
  );
};

export default IncrementDetailsGridRow;
