import * as React from "react";
import { useState } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Divider,
} from "@material-ui/core";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { EmptyGuid } from "../../../../utils/constants";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import IClassDetails from "../../../../interfaces/setup/creditHour/IClassDetails";
import IClassSearchParam from "../../../../interfaces/setup/creditHour/IClassSearchParam";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { FieldError, useForm, FormContext } from "react-hook-form";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import CreditProgramVersionAutoComplete from "../../../../components/AutoComplete/CreditProgramVersionAutoComplete";
import CampusForCurrentUserAutoComplete from "../../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import CoursesAutoComplete from "../../../../components/AutoComplete/CoursesAutoComplete";
import ClassLocationAutoComplete from "../../../../components/AutoComplete/ClassLocationAutoComplete";
import ClassScheduleAutoComplete from "../../../../components/AutoComplete/ClassScheduleAutoComplete";
import TermCalenderAutoComplete from "../../../../components/AutoComplete/TermCalenderAutoComplete";
import TermCalenderDatesAutoComplete from "../../../../components/AutoComplete/TermCalenderDatesAutoComplete";
import InstructorsAutoComplete from "../../../../components/AutoComplete/InstructorsAutoComplete";
import AttendanceUnitTypeAutoComplete from "../../../../components/AutoComplete/AttendanceUnitTypeAutoComplete";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  UpsertClass,
  GetClassById,
  SearchClasses,
  deleteClassById,
} from "../../../../api/setup/creditHour/classApi";
import { format } from "date-fns";
import AddTermModal from "../Terms/AddTermModal";
import AddCourseModal from "../Terms/AddCourseModal";
import AddScheduleModal from "../Terms/AddScheduleModal";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 90,
    },
    searchButton: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    searchResults: {
      paddingTop: theme.spacing(1),
    },
    tableContainer: {
      maxHeight: "400px",
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    dialogCloseButton: {
      float: "right",
    },
    courseSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    fileInput: {
      display: "none",
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    alignCenter: {
      textAlign: "center",
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
    cardMain: {
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

const ClassesSetup = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [classList, setClassList] = React.useState<IClassDetails[]>([]);
  const classes = useStyles({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [isFormSearch, setIsFormSearch] = React.useState<boolean>(false);
  var baseDateMin = new Date();
  var baseDateMax = new Date();
  baseDateMin.setFullYear(baseDateMin.getFullYear() - 10);
  baseDateMax.setFullYear(baseDateMax.getFullYear() + 10);
  const [minDate, setMinDate] = React.useState<Date>(baseDateMin);
  const [maxDate, setMaxDate] = React.useState<Date>(baseDateMax);

  var startDate = new Date();
  var endDate = new Date();

  var baseModel: IClassDetails = {
    instrClassTermId: EmptyGuid,
    campusId: null,
    name: "",
    startDate: startDate,
    endDate: endDate,
  };
  const [model, setModel] = React.useState<IClassDetails>(baseModel);

  var baseSearch: IClassSearchParam = {};
  const [searchModel, setSearchModel] =
    React.useState<IClassSearchParam>(baseSearch);

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const [confirmationDialogue, setConfirmationDialogue] = React.useState<{
    onOk?: () => void;
    open: boolean;
    onCancel?: () => void;
  }>({ open: false });

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const data = useForm<IClassDetails>({
    mode: "onBlur",
    defaultValues: model,
  });

  const handleFieldChange = (
    fieldId: keyof IClassDetails,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);

    if (fieldId == "startDate" || fieldId == "endDate") {
      data.triggerValidation("startDate");
      data.triggerValidation("endDate");
    } else {
      data.triggerValidation(fieldId);
    }
  };

  const handleTermFieldChange = (
    fieldId: keyof IClassDetails,
    value: any,
    tStartDate: any,
    tEndDate: any,
    mapFunction?: Function
  ) => {
    if (value === null) {
      setMinDate(baseDateMin);
      setMaxDate(baseDateMax);
    } else {
      setMinDate(tStartDate);
      setMaxDate(tEndDate);
    }

    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);

    if (fieldId === "termCalendarId" && !isFormSearch) {
      let updatedModel = model;
      (updatedModel as any)["startDate"] = tStartDate;
      (updatedModel as any)["endDate"] = tEndDate;
      setModel({ ...updatedModel });
      data.setValue(fieldId, value);
      data.clearError("startDate");
      data.clearError("endDate");
    }
  };

  React.useEffect(() => {
    handleSearchFieldChange("campusId", userSelectedCampus);
    handelSearchClasses();
  }, [userSelectedCampus]);

  const handleSearchFieldChange = (
    fieldId: keyof IClassSearchParam,
    value: any
  ) => {
    let updatedModel = searchModel;
    (updatedModel as any)[fieldId] = value;
    setSearchModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const saveCourseDetails = async (confirm?: boolean) => {
    if (!loading) {
      setLoading(true);
      try {
        const newDetails = await UpsertClass(model);
        setModel(baseModel);
        setSearchModel(baseSearch);
        setClassList([]);
        setSnackBarProps({
          variant: "success",
          showSnackBar: true,
          messageInfo: newDetails.message,
        });
      } catch (err) {
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: (err as Error).message,
        });
      }
      setLoading(false);
    }
  };

  const handelSearchClasses = async (confirm?: boolean) => {
    if (
      !loading &&
      (searchModel.courseId ||
        searchModel.instructorId ||
        searchModel.progCatYearId ||
        searchModel.termCalendarId)
    ) {
      setLoading(true);
      try {
        const searchResults = await SearchClasses(searchModel);
        setClassList(searchResults?.data);
      } catch (err) {
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: (err as Error).message,
        });
      }
      setLoading(false);
    }
  };

  const getClassDetails = async (classId: string) => {
    if (!loading && classId) {
      setLoading(true);
      if (classId !== EmptyGuid) {
        try {
          setIsFormSearch(true);
          const newDetails = await GetClassById(classId);
          setModel(newDetails);
        } catch (err) {
          setSnackBarProps({
            variant: "error",
            showSnackBar: true,
            messageInfo: (err as Error).message,
          });
        }
      }
      setLoading(false);
    }
  };

  const deleteClass = async (confirm?: boolean) => {
    if (
      !loading &&
      model.instrClassTermId &&
      model.instrClassTermId !== EmptyGuid
    ) {
      setLoading(true);
      if (!confirm && model.courseId !== EmptyGuid) {
        setConfirmationDialogue({
          open: true,
          onOk: () => deleteClass(true),
          onCancel: () => setLoading(false),
        });
        return;
      }
      if (model.instrClassTermId !== EmptyGuid) {
        try {
          const result = await deleteClassById(model.instrClassTermId);
          if (result && result == true) {
            setModel(baseModel);
            data.reset(baseModel);
            setClassList([]);

            setSnackBarProps({
              variant: "success",
              showSnackBar: true,
              messageInfo: "Class deleted successfully.",
            });
          }
        } catch (err) {
          setSnackBarProps({
            variant: "error",
            showSnackBar: true,
            messageInfo: (err as Error).message,
          });
        }
      }
      setLoading(false);
    }
  };

  const cancel = () => {
    setSearchModel(baseSearch);
    setModel(baseModel);
    data.reset(baseModel);
    setIsFormSearch(false);
    setClassList([]);
  };

  const handleAdd = () => {
    setModel({ ...model, name: searchText });
    data.setValue("name", searchText);
    data.triggerValidation("name");
    setSearchText("");
    setClassList([]);
  };

  const [reloadKey, setReloadKey] = useState(0);

  const handleRelaodButtonClick = () => {
    setReloadKey(reloadKey + 1);
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true} elevation={12} className={classes.cardMain}>
        <CardContent>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <Grid container direction="column" spacing={8} wrap="nowrap">
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={3} md={3}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Class Search"></CustomCardTitle>
                        <CardContent>
                          <Grid container direction="column" wrap="nowrap">
                            <Grid item xs={12} sm={12} md={12}></Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <CreditProgramVersionAutoComplete
                                id="searchProgCatYearId"
                                name="searchProgCatYearId"
                                label="Program Version"
                                key="searchProgCatYearId"
                                campusId={userSelectedCampus}
                                valueFilter={
                                  searchModel?.progCatYearId
                                    ? {
                                        key: "value",
                                        values: [searchModel?.progCatYearId],
                                      }
                                    : undefined
                                }
                                filterHandle={(v: any) => {
                                  handleSearchFieldChange(
                                    "progCatYearId",
                                    v ? v.value : null
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <TermCalenderAutoComplete
                                id="searchTermCalendarId"
                                name="searchTermCalendarId"
                                label="Term"
                                key="searchTermCalendarId"
                                valueFilter={
                                  searchModel?.termCalendarId
                                    ? {
                                        key: "value",
                                        values: [searchModel?.termCalendarId],
                                      }
                                    : undefined
                                }
                                filterHandle={(v: any) => {
                                  handleSearchFieldChange(
                                    "termCalendarId",
                                    v ? v.value : null
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <CoursesAutoComplete
                                id="searchCourseId"
                                name="searchCourseId"
                                label="Course"
                                key="searchCourseId"
                                requestParameter={{
                                  campusId: null,
                                }}
                                valueFilter={
                                  searchModel?.courseId
                                    ? {
                                        key: "value",
                                        values: [searchModel?.courseId],
                                      }
                                    : undefined
                                }
                                filterHandle={(v: any) => {
                                  handleSearchFieldChange(
                                    "courseId",
                                    v ? v.value : null
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <InstructorsAutoComplete
                                id="searchInstructor"
                                name="searchInstructor"
                                label="Instructor"
                                key="Instructor"
                                valueFilter={
                                  searchModel?.instructorId
                                    ? {
                                        key: "value",
                                        values: [searchModel?.instructorId],
                                      }
                                    : undefined
                                }
                                filterHandle={(v: any) => {
                                  handleSearchFieldChange(
                                    "instructorId",
                                    v ? v.value : null
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <ProgressSaveButton
                                text="Search"
                                onClick={handelSearchClasses}
                                loading={loading}
                                disabled={false}
                                buttonClassName={classes.searchButton}
                                size="small"
                              ></ProgressSaveButton>
                            </Grid>
                            <Grid
                              item
                              alignContent="flex-start"
                              alignItems="flex-start"
                              justify="flex-start"
                            >
                              <TableContainer
                                component={Paper}
                                square
                                className={classes.tableContainer}
                              >
                                <Table
                                  stickyHeader
                                  size="small"
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Classes</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {loading ? (
                                      <TableRow>
                                        <TableCell
                                          className={classes.preLoaderCell}
                                        >
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      classList.map((program, index) => (
                                        <TableRow key={`row-${index}`}>
                                          <TableCell
                                            key={`courseName-${index}`}
                                            className={
                                              classes.courseSelectionCell
                                            }
                                            onClick={() =>
                                              getClassDetails(
                                                program.instrClassTermId ?? ""
                                              )
                                            }
                                          >
                                            {program.name}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Class Detail"></CustomCardTitle>
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            spacing={8}
                            wrap="nowrap"
                          >
                            <Grid
                              item
                              alignContent="flex-start"
                              alignItems="flex-start"
                              justify="flex-start"
                            >
                              <FormContext {...data}>
                                <form
                                  onSubmit={handleSubmit(() =>
                                    saveCourseDetails(false)
                                  )}
                                >
                                  {(!loading && (
                                    <React.Fragment>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} sm={6} md={6}>
                                          <InputField
                                            type={InputType.TEXT}
                                            key="name"
                                            id="name"
                                            label="Name *"
                                            name="name"
                                            defaultValue={model.name}
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleFieldChange(
                                                "name",
                                                e.target.value
                                              );
                                            }}
                                            error={!!data.errors.name}
                                            inputRef={data.register({
                                              required: "Name is required.",
                                            })}
                                            helperText={
                                              data.errors.name
                                                ? (
                                                    data.errors
                                                      .name as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <InputField
                                            type={InputType.TEXT}
                                            key="code"
                                            id="code"
                                            label="Code *"
                                            name="code"
                                            defaultValue={model.code}
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleFieldChange(
                                                "code",
                                                e.target.value
                                              );
                                            }}
                                            error={!!data.errors.code}
                                            inputRef={data.register({
                                              required: "Code is required.",
                                            })}
                                            helperText={
                                              data.errors.code
                                                ? (
                                                    data.errors
                                                      .code as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} sm={6} md={6}>
                                          <CampusForCurrentUserAutoComplete
                                            id="campusId"
                                            name="campusId"
                                            label="Campus *"
                                            params={{
                                              fullWidth: true,
                                            }}
                                            filterHandle={(v: any) => {
                                              handleFieldChange(
                                                "campusId",
                                                v ? v.value : undefined
                                              );
                                            }}
                                            valueFilter={
                                              model.campusId
                                                ? {
                                                    key: "value",
                                                    values: [model.campusId],
                                                  }
                                                : undefined
                                            }
                                            error={!!data.errors.campusId}
                                            inputRef={data.register({
                                              required: true,
                                            })}
                                            helperText={
                                              model.campusId
                                                ? "Campus is required."
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <InputField
                                            type={InputType.TEXT}
                                            key="classLocation"
                                            id="classLocation"
                                            label="Class Location *"
                                            name="classLocation"
                                            defaultValue={model.classLocation}
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleFieldChange(
                                                "classLocation",
                                                e.target.value
                                              );
                                            }}
                                            error={!!data.errors.classLocation}
                                            inputRef={data.register({
                                              required:
                                                "Class location is required.",
                                            })}
                                            helperText={
                                              data.errors.classLocation
                                                ? (
                                                    data.errors
                                                      .classLocation as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} sm={6} md={6}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <TermCalenderDatesAutoComplete
                                              key={reloadKey}
                                              id="termCalendarId"
                                              name="termCalendarId"
                                              label="Term *"
                                              valueFilter={
                                                model.termCalendarId
                                                  ? {
                                                      key: "value",
                                                      values: [
                                                        model.termCalendarId,
                                                      ],
                                                    }
                                                  : undefined
                                              }
                                              filterHandle={(v: any) => {
                                                handleTermFieldChange(
                                                  "termCalendarId",
                                                  v ? v.value : null,
                                                  v ? v.startDate : null,
                                                  v ? v.endDate : null
                                                );
                                              }}
                                              error={
                                                !!data.errors.termCalendarId
                                              }
                                              inputRef={data.register({
                                                required: "Term is required.",
                                              })}
                                              helperText={
                                                data.errors.termCalendarId
                                                  ? (
                                                      data.errors
                                                        .termCalendarId as FieldError
                                                    ).message
                                                  : undefined
                                              }
                                            />
                                            <div
                                              onClick={handleRelaodButtonClick}
                                            >
                                              <AddTermModal />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <ClassScheduleAutoComplete
                                              id="classScheduleId"
                                              name="classScheduleId"
                                              label="Class Schedule *"
                                              valueFilter={
                                                model.classScheduleId
                                                  ? {
                                                      key: "value",
                                                      values: [
                                                        model.classScheduleId,
                                                      ],
                                                    }
                                                  : undefined
                                              }
                                              filterHandle={(v: any) => {
                                                handleFieldChange(
                                                  "classScheduleId",
                                                  v ? v.value : null
                                                );
                                              }}
                                              error={
                                                !!data.errors.classScheduleId
                                              }
                                              inputRef={data.register({
                                                required:
                                                  "Schedule is required.",
                                              })}
                                              helperText={
                                                data.errors.classScheduleId
                                                  ? (
                                                      data.errors
                                                        .classScheduleId as FieldError
                                                    ).message
                                                  : undefined
                                              }
                                            />
                                            <div>
                                              <AddScheduleModal />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              disableToolbar
                                              minDate={minDate}
                                              maxDate={maxDate}
                                              variant="inline"
                                              clearable
                                              format={"MM/dd/yyyy"}
                                              placeholder="mm/dd/yyyy"
                                              id="startDate"
                                              name="startDate"
                                              label="Start Date *"
                                              TextFieldComponent={
                                                ThemeTextField
                                              }
                                              className={
                                                classes.datePickerField
                                              }
                                              value={
                                                model.startDate
                                                  ? model.startDate
                                                  : null
                                              }
                                              onChange={(value: any) => {
                                                handleFieldChange(
                                                  "startDate",
                                                  value ? value : null
                                                );
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              autoOk={true}
                                              // required
                                              error={!!data.errors["startDate"]}
                                              innerRef={(ref: any) => {
                                                data.register("startDate", {
                                                  required:
                                                    "Start Date is required",
                                                  validate: {
                                                    beforeEndDate: (value) =>
                                                      !value ||
                                                      moment(
                                                        new Date(value)
                                                      ).isBefore(
                                                        new Date(
                                                          data.getValues()[
                                                            "endDate"
                                                          ]
                                                        ),
                                                        "day"
                                                      ) ||
                                                      "Start Date must be before end date ",
                                                    withinSelectedTerm: (
                                                      value
                                                    ) =>
                                                      moment(
                                                        new Date(value)
                                                      ).isSameOrAfter(
                                                        minDate,
                                                        "day"
                                                      ) ||
                                                      "Start Date must be within selected term ",
                                                    withinSelectedEndTerm: (
                                                      value
                                                    ) =>
                                                      moment(
                                                        new Date(value)
                                                      ).isBefore(
                                                        maxDate,
                                                        "day"
                                                      ) ||
                                                      "Start Date must be within selected term",
                                                  },
                                                });
                                                data.setValue(
                                                  "startDate",
                                                  model.startDate
                                                );
                                              }}
                                              helperText={
                                                data.errors["startDate"]
                                                  ? (
                                                      data.errors[
                                                        "startDate"
                                                      ] as FieldError
                                                    ).message
                                                  : undefined
                                              }
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              disableToolbar
                                              minDate={minDate}
                                              maxDate={maxDate}
                                              variant="inline"
                                              clearable
                                              format={"MM/dd/yyyy"}
                                              placeholder="mm/dd/yyyy"
                                              id="endDate"
                                              name="endDate"
                                              label="End Date *"
                                              TextFieldComponent={
                                                ThemeTextField
                                              }
                                              className={
                                                classes.datePickerField
                                              }
                                              value={
                                                model.endDate
                                                  ? model.endDate
                                                  : null
                                              }
                                              onChange={(value: any) => {
                                                handleFieldChange(
                                                  "endDate",
                                                  value ? value : null
                                                );
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              autoOk={true}
                                              // required
                                              error={!!data.errors["endDate"]}
                                              innerRef={(ref: any) => {
                                                data.register("endDate", {
                                                  required:
                                                    "End Date is required",
                                                  validate: {
                                                    beforeEndDate: (value) =>
                                                      !value ||
                                                      moment(
                                                        new Date(value)
                                                      ).isAfter(
                                                        new Date(
                                                          data.getValues()[
                                                            "startDate"
                                                          ]
                                                        ),
                                                        "day"
                                                      ) ||
                                                      "End Date must be after Start date",
                                                    withinSelectedTerm: (
                                                      value
                                                    ) =>
                                                      moment(
                                                        new Date(value)
                                                      ).isSameOrAfter(
                                                        minDate,
                                                        "day"
                                                      ) ||
                                                      "End Date must be within selected term ",
                                                    withinSelectedEndTerm: (
                                                      value
                                                    ) =>
                                                      moment(
                                                        new Date(value)
                                                      ).isSameOrBefore(
                                                        maxDate,
                                                        "day"
                                                      ) ||
                                                      "End Date must be within selected term",
                                                  },
                                                });
                                                data.setValue(
                                                  "endDate",
                                                  model.endDate
                                                );
                                              }}
                                              helperText={
                                                data.errors["endDate"]
                                                  ? (
                                                      data.errors[
                                                        "endDate"
                                                      ] as FieldError
                                                    ).message
                                                  : undefined
                                              }
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} sm={6} md={6}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <CoursesAutoComplete
                                              id="courseId"
                                              name="courseId"
                                              label="Course *"
                                              key="courseId"
                                              requestParameter={{
                                                campusId: null,
                                              }}
                                              valueFilter={
                                                model.courseId
                                                  ? {
                                                      key: "value",
                                                      values: [model.courseId],
                                                    }
                                                  : undefined
                                              }
                                              filterHandle={(v: any) => {
                                                handleFieldChange(
                                                  "courseId",
                                                  v ? v.value : null
                                                );
                                              }}
                                              error={!!data.errors.courseId}
                                              inputRef={data.register({
                                                required: "Course is required.",
                                              })}
                                              helperText={
                                                data.errors.courseId
                                                  ? (
                                                      data.errors
                                                        .courseId as FieldError
                                                    ).message
                                                  : undefined
                                              }
                                            />
                                            <div
                                              onClick={handleRelaodButtonClick}
                                            >
                                              <AddCourseModal />
                                            </div>
                                          </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                          <InstructorsAutoComplete
                                            id="instructorId"
                                            name="instructorId"
                                            label="Instructor *"
                                            valueFilter={
                                              model.instructorId
                                                ? {
                                                    key: "value",
                                                    values: [
                                                      model.instructorId,
                                                    ],
                                                  }
                                                : undefined
                                            }
                                            filterHandle={(v: any) => {
                                              handleFieldChange(
                                                "instructorId",
                                                v ? v.value : null
                                              );
                                            }}
                                            error={!!data.errors.instructorId}
                                            inputRef={data.register({
                                              required:
                                                "Instructor is required.",
                                            })}
                                            helperText={
                                              data.errors.instructorId
                                                ? (
                                                    data.errors
                                                      .instructorId as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} sm={6} md={6}>
                                          <InputField
                                            type={InputType.NUMBER}
                                            key="maxStudents"
                                            id="maxStudents"
                                            label="Max Students"
                                            name="maxStudents"
                                            defaultValue={model.maxStudents}
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleFieldChange(
                                                "maxStudents",
                                                e.target.value
                                              );
                                            }}
                                            inputRef={data.register({
                                              validate: {
                                                max: () =>
                                                  !model.maxStudents ||
                                                  model.maxStudents < 10000
                                                    ? true
                                                    : "The max student(s) should be less then 10,000.",
                                                min: () =>
                                                  !model.maxStudents ||
                                                  model.maxStudents >= 0
                                                    ? true
                                                    : "The max student(s) should be greater then and equal to 0.",
                                              },
                                            })}
                                            error={!!data.errors.maxStudents}
                                            helperText={
                                              data.errors.maxStudents
                                                ? (
                                                    data.errors
                                                      .maxStudents as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <StatusAutoComplete
                                            id="statusId"
                                            name="statusId"
                                            label="Status *"
                                            valueFilter={
                                              model.statusId
                                                ? {
                                                    key: "value",
                                                    values: [model.statusId],
                                                  }
                                                : undefined
                                            }
                                            filterHandle={(v: any) => {
                                              handleFieldChange(
                                                "statusId",
                                                v ? v.value : null
                                              );
                                            }}
                                            error={!!data.errors.statusId}
                                            inputRef={data.register({
                                              required: "Status is required.",
                                            })}
                                            helperText={
                                              data.errors.statusId
                                                ? (
                                                    data.errors
                                                      .statusId as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <AttendanceUnitTypeAutoComplete
                                            id="attendanceUnitTypeId"
                                            name="attendanceUnitTypeId"
                                            label="Attendance Unit Type *"
                                            valueFilter={
                                              model.attendanceUnitTypeId
                                                ? {
                                                    key: "value",
                                                    values: [
                                                      model.attendanceUnitTypeId,
                                                    ],
                                                  }
                                                : undefined
                                            }
                                            filterHandle={(v: any) => {
                                              handleFieldChange(
                                                "attendanceUnitTypeId",
                                                v ? v.value : null
                                              );
                                            }}
                                            error={
                                              !!data.errors.attendanceUnitTypeId
                                            }
                                            inputRef={data.register({
                                              required: "Attendance unit type is required.",
                                            })}
                                            optionsFilter={(data:any) => {
                                              //alert(JSON.stringify(data))
                                              return data?.text == "Minutes" ? false : true;
                                            }}
                                            helperText={
                                              data.errors.attendanceUnitTypeId
                                                ? (
                                                    data.errors
                                                      .attendanceUnitTypeId as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  )) || (
                                    <div className={classes.alignCenter}>
                                      <CircularProgress />
                                    </div>
                                  )}
                                </form>
                              </FormContext>
                            </Grid>
                            <Grid
                              item
                              alignContent="flex-start"
                              alignItems="flex-start"
                              justify="flex-start"
                            >
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                wrap="nowrap"
                              >
                                <Grid
                                  item
                                  alignContent="flex-start"
                                  alignItems="flex-start"
                                  justify="flex-start"
                                >
                                  <ProgressSaveButton
                                    text="Save"
                                    onClick={handleSubmit(() =>
                                      saveCourseDetails(false)
                                    )}
                                    loading={loading}
                                    disabled={false}
                                    buttonClassName={classes.saveButton}
                                    size="small"
                                  ></ProgressSaveButton>
                                </Grid>
                                <Grid
                                  item
                                  alignContent="flex-start"
                                  alignItems="flex-start"
                                  justify="flex-start"
                                >
                                  <Button
                                    disabled={loading}
                                    onClick={cancel}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    type="button"
                                  >
                                    Add New
                                  </Button>
                                </Grid>
                                {/* <Grid
																	item
																	alignContent="flex-start"
																	alignItems="flex-start"
																	justify="flex-start"
																>
																	<Button
																		size="small"
																		color="primary"
																		variant="contained"
																		type="button"
																		onClick={() => deleteClass(false)}
																		className={classes.buttonColorTertiary}
																	>
                                    DELETE
																	</Button>{" "}
																</Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog"
        open={confirmationDialogue.open}
        onClose={() => {
          setConfirmationDialogue({ open: false });
          confirmationDialogue.onCancel && confirmationDialogue.onCancel();
        }}
      >
        <DialogContent dividers>
          <Typography>Are you sure you want to delete this class?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              confirmationDialogue.onOk && confirmationDialogue.onOk();
              setConfirmationDialogue({ open: false });
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setConfirmationDialogue({ open: false });
              confirmationDialogue.onCancel && confirmationDialogue.onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ClassesSetup;
