import {
  Step,
  StepLabel,
  Stepper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import * as enrollmentApi from "../../../api/student/academics/enrollmentApi";
import * as leadsApi from "../../../api/admissions/leadsApi";
import * as studentTransferApi from "../../../api/student/transfer/studentTransferApi";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import ConfirmationModal from "../../../components/_Layout/Modal/ConfirmationModal";
import { keys } from "../../../constants/urlparameters.contants";
import { ILead } from "../../../interfaces/admissions/ILead";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import {
  AutomaticChargingDetails,
  EnrollmentGeneral,
  EnrollmentProgramAndGroups,
  EnrollmentSchedule,
  EnrollmentTransferDetails,
  StudentBadgeNumberAndNotes,
} from "../../../interfaces/student/academics/EnrollmentProgram";
import { BestContactInfo } from "../../../interfaces/student/profile/contactInfo/BestContactInfo";
import {
  IStudentInfo,
  IStudentInfoAdmissions,
  IStudentInfoDemographics,
  NewIStudentInfoModel,
} from "../../../interfaces/student/profile/studentInfo/IStudentInfo";
import { ITransferStudentToProgramDetail } from "../../../interfaces/student/transfer/IStudentTransferInfo";
import { EmptyGuid } from "../../../utils/constants";
import EnrollmentInfo from "./EnrollmentInfo";
import FinalizeEnrollment from "./FinalizeEnrollment";
import ImportLeadDialog from "./ImportLeadDialog";
import ProfileInfo from "./ProfileInfo";
import ProgramAndSchedule from "./ProgramAndSchedule";
import StudentInfo from "./StudentInfo";
import NotificationModal from "../../../components/_Layout/Modal/NotificationModal";
import { useForm, FormContext } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    paperRoot: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.paper.background,
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    actionContainer: {
      marginTop: theme.spacing(3),
    },

    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    contactInfo: {
      marginTop: theme.spacing(2),
    },
    contactInfoHeader: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    visible: {
      display: "block",
      visibility: "visible",
    },
    hidden: {
      display: "none",
      visibility: "hidden",
    },
    dialogBox: {
      width: "400px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    titleBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  })
);

function getSteps() {
  return [
    "Student Info",
    "Program & Schedule",
    "Enrollment Info",
    "Profile Info",
    "Finalize Enrollment",
  ];
}

const NewEnrollment = (props: any) => {
  const classes = useStyles({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [shouldValidate, setShouldValidate] = React.useState<boolean>(false);
  const [isHighSchoolMandatory, setHighSchoolMandatory] =
    React.useState<boolean>(true);
  const steps = getSteps();

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const admissionModule = appSettings.AdmissionModule;
  const [actionAfterFinalize, setActionAfterFinalize] = React.useState("quit");
  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const [studentInfoModel, setStudentInfoModel] = React.useState<
    IStudentInfo | any
  >(NewIStudentInfoModel() as IStudentInfo);
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  let isNewQueryVal = params.get("isNew");
  let isImportQueryVal = params.get("isImport");
  let campusId = params.get("campusId") || "";
  let isNewValue =
    isNewQueryVal && (isNewQueryVal === "true" || isNewQueryVal === "True")
      ? true
      : false;
  const [isNew, setIsNew] = React.useState<boolean>(isNewValue);
  let isImportValue =
    isImportQueryVal && isImportQueryVal === "true" ? true : false;

  let leadIdQueryId = keys.leadId;
  let leadIdQueryString = params.get(leadIdQueryId);

  const [isImport, setIsImport] = React.useState<boolean>(isImportValue);
  const [leadId, setLeadId] = React.useState<string | undefined>(
    leadIdQueryString ?? undefined
  );

  let externalLeadSource = params.get("externalLeadSource");
  let externalLeadId = params.get("externalLeadId");

  React.useEffect(() => {
    if (!!externalLeadSource && !!externalLeadId) {
      leadsApi
        .pullExternalCrmLead(externalLeadId, campusId)
        .then((response) => {
          console.log(response);
          console.log("populate object");
          if (response.data) {
            let studentInfoModel = NewIStudentInfoModel();
            console.log(response.data);
            if (response.data.student) {
              studentInfoModel = { ...response.data.student };
              let contactInfo = { ...response.data.contact };
              let enrollmentInfo = { ...response.data.enrollment };
              setStudentInfoModel(studentInfoModel);
              setContactInfoData(contactInfo);

              enrollmentInfo.isFirstTimeInSchool =
                enrollmentInfo.isFirstTimeInSchool
                  ? { text: "Yes", id: 1 }
                  : { text: "No", id: 0 };
              enrollmentInfo.isFirstTimePostSecSchool =
                enrollmentInfo.isFirstTimePostSecSchool
                  ? { text: "Yes", id: 1 }
                  : { text: "No", id: 0 };
              setEnrollmentGeneral(enrollmentInfo);
              console.log(enrollmentInfo);
            }
          } else {
          }

          setLoading(false);
        });
    }
  }, [externalLeadId, externalLeadSource]);

  const crmPostProcessTypes = ["LeadSquared"]; // pull from config setting
  const externalLeadSourceName = admissionModule; //pull from some config setting
  let postProcessLead = crmPostProcessTypes.includes(externalLeadSourceName);

  const handleImport = (lead: ILead) => {
    if (lead && postProcessLead) {
      let newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("externalLeadId", lead.externalEntityId);
      newSearchParams.set("externalLeadSource", admissionModule);
      newSearchParams.set("isImport", "false");

      history.push({
        pathname: location.pathname,
        search: newSearchParams.toString(),
      });
    } else if (lead) {
      if (lead.isEnrolled) {
        history.push(
          "/Student/NewEnrollment?isNew=false&isImported=true&campusId=" +
            lead.campusId +
            "&studentId=" +
            lead.studentId
        );
      } else {
        setLeadId(lead.leadId);
        history.push(
          "/Student/NewEnrollment?isNew=false&isImported=true&campusId=" +
            userSelectedCampus +
            "&leadId=" +
            lead.leadId
        );
      }
    }
  };

  const removeQuery = (key: string) => {
    let pathname = location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.delete(key);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const addQuery = (key: any, value: any) => {
    let pathname = location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.set(key, value);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  React.useEffect(() => {
    setIsNew(isNewValue);
  }, [isNewValue]);

  React.useEffect(() => {
    setIsImport(isImportValue);
  }, [isImportValue]);

  React.useEffect(() => {
    setLeadId(leadIdQueryString ?? undefined);
  }, [leadIdQueryString]);

  const [contactInfoData, setContactInfoData] =
    React.useState<BestContactInfo | null>(null);

  const [enrollmentProgramAndGroups, setEnrollmentProgramAndGroups] =
    React.useState<EnrollmentProgramAndGroups | null>(null);

  const [enrollmentBadgeAndNotes, setEnrollmentBadgeAndNotes] =
    React.useState<StudentBadgeNumberAndNotes | null>(null);

  const [enrollmentSchedule, setEnrollmentSchedule] =
    React.useState<EnrollmentSchedule | null>(null);

  const [demographics, setDemographics] =
    React.useState<IStudentInfoDemographics | null>(null);

  const [admissions, setAdmissions] =
    React.useState<IStudentInfoAdmissions | null>(null);

  const [enrollmentGeneral, setEnrollmentGeneral] =
    React.useState<EnrollmentGeneral | null>(null);
  const [chargeDefinition, setChargeDefinition] =React.useState<ChargeDefinition | null>(null);
  const [enrollmentTransferDetails, setEnrollmentTransferDetails] =
    React.useState<EnrollmentTransferDetails | null>(null);

  const [isTransferredStudent, setIsTransferredStudent] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  /*Load data in case of transfer student*/

  const firstTimeInSchool = (fieldId: string, value: any) => {
    let modelToUpdate = enrollmentGeneral;
    (enrollmentGeneral as any)[fieldId] = value;
    setEnrollmentGeneral({ ...modelToUpdate });
  };
  const isTransferred = params.get("isTransferred");
  let programId: string | null = null;
  let programVersionId: string | null = null;
  let transferHours: number | null = null;
  let transferDate: string | null = null;
  let oldEnrollmentId: string | null = null;
  if (isTransferred == "true") {
    programId = params.get("toProgram");
    programVersionId = params.get("toProgramVersion");
    transferHours = Number(params.get("transferHours"));
    transferDate = params.get("transferDate");
    oldEnrollmentId = params.get("enrollmentId");
  }
  if (
    isTransferredStudent == false &&
    programId &&
    programVersionId &&
    transferDate &&
    oldEnrollmentId
  ) {
    setIsTransferredStudent(true);
  }

  const finalizeEnrollment = () => {
    firstTimeInSchool(
      "isFirstTimeInSchool",
      enrollmentGeneral?.isFirstTimeInSchool.id
    );
    firstTimeInSchool(
      "isFirstTimePostSecSchool",
      enrollmentGeneral?.isFirstTimePostSecSchool.id
    );
    let buildStudentInfoToSend: IStudentInfo | any = {
      firstName: studentInfoModel.firstName,
      middleName: studentInfoModel.middleName,
      lastName: studentInfoModel.lastName,
      dateOfBirth: studentInfoModel.dateOfBirth,
      stateBoardId: studentInfoModel.stateBoardId,
      afaStudentId: studentInfoModel.afaStudentId,
      stateBoardRegDate: studentInfoModel.stateBoardRegDate,
      ssn: studentInfoModel.ssn,
      prefixId: studentInfoModel.prefixId,
      suffixId: studentInfoModel.suffixId,
      maskedSSN: studentInfoModel.maskedSSN,
      excludeFrom1098: studentInfoModel.excludeFrom1098,
      studentId: isNew ? EmptyGuid : studentInfoModel.studentId,
      leadId: leadId,
      genderId: demographics?.genderId ?? "",
      ethnicityId: demographics?.ethnicityId ?? "",
      isDisabled: demographics?.isDisabled ?? false,
      dependencyTypeId: demographics?.dependencyTypeId ?? "",
      dependents: demographics?.dependents ?? "",
      headOfHousehold: demographics?.headOfHousehold ?? false,
      housingId: demographics?.housingId ?? "",
      milesFromSchool: demographics?.milesFromSchool ?? "",
      geographicAreaId: demographics?.geographicAreaId ?? "",
      maritalStatusId: demographics?.maritalStatusId ?? "",
      familyIncomeAmount: demographics?.familyIncomeAmount ?? undefined,
      citizenshipId: demographics?.citizenshipId ?? "",
      isInternationalStudent: studentInfoModel.isInternationalStudent,
      educationCompletedId: enrollmentGeneral?.educationCompletedId ?? "",
      highSchoolId: admissions?.highSchoolId ?? "",
      highSchoolGraduationDate: admissions?.highSchoolGraduationDate ?? "",
      currentlyAttendingHighSchool:
        admissions?.currentlyAttendingHighSchool ?? false,
      admissionsRepId: admissions?.admissionsRepId ?? "",
      admissionsRepAssigned: admissions?.admissionsRepAssigned ?? "",
    };

    let modelToSend = {
      chargingDetails: chargeDefinition,
      contact: contactInfoData,
      enrollment: enrollmentGeneral,
      program: enrollmentProgramAndGroups,
      schedule: enrollmentSchedule,
      student: buildStudentInfoToSend,
      transferDetails: enrollmentTransferDetails,
      badgeAndNotes: enrollmentBadgeAndNotes,
    };
    enrollmentApi.createEnrollment(modelToSend).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          setLoading(false);
          setLeadId(undefined);
          if (
            isTransferredStudent &&
            (transferDate ||
              enrollmentTransferDetails?.transferDateToNewProgram)
          ) {
            if (response.result?.enrollment?.studentEnrollmentId) {
              markStudentAsTransferred(
                response.result.enrollment.studentEnrollmentId,
                response.result.student.studentId
              );
              redirectAfterSaveEnrollment(response.result.student.studentId);
            }
          } else {
            redirectAfterSaveEnrollment(response.result.student.studentId);
          }

          setSnackBarProps((props: any) => {
            return {
              variant: "success",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          setLoading(false);
          setSnackBarProps((props: any) => {
            if (response.data == "Error: the badge number already in use.") {
              return {
                variant: "warning",
                showSnackBar: true,
                messageInfo:
                  "Badge number already is in use, refresh badge number and try again.",
              };
            }
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.data,
            };
          });
        }
        setLoading(false);
      },
      (exception: any) => {
        setLoading(false);
        setSnackBarProps((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const redirectAfterSaveEnrollment = (studentId: string) => {
    let redirectUrl: string = "";
    if (actionAfterFinalize === "quit") {
      //redirect to dashboard
      redirectUrl =
        "/dashboard?campusId=" + userSelectedCampus + "&studentId=" + studentId;
    }

    if (actionAfterFinalize === "new") {
      //redirect to new student
      redirectUrl = "/Student/NewEnrollment?isNew=true";
    }

    if (actionAfterFinalize === "reuse") {
      //redirect to new student
      redirectUrl =
        "/Student/NewEnrollment?isNew=true&programId=" +
        enrollmentProgramAndGroups?.programId +
        "&programVersionId=" +
        enrollmentProgramAndGroups?.programVersionId +
        "&scheduleId=" +
        enrollmentSchedule?.scheduleId;
    }
    history.push(redirectUrl);
    history.replace(redirectUrl + "&reloadEnrollmentSelector=true");

    if (actionAfterFinalize === "reuse" || actionAfterFinalize === "new") {
      history.go(0);
    }
  };

  /*call this method after new enrollment is created*/
  const markStudentAsTransferred = (
    newEnrollmentId: string,
    studentId: string
  ) => {
    let modelTransfer: ITransferStudentToProgramDetail = {
      EnrollmentId: oldEnrollmentId
        ? oldEnrollmentId
        : enrollmentTransferDetails?.oldStuEnrollId,
      NewEnrollmentId: newEnrollmentId,
      NewProgramId: programId
        ? programId
        : enrollmentProgramAndGroups?.programId,
      OldProgramVersionId: enrollmentTransferDetails?.oldProgramVersionId,
      NewProgramVersionId: programVersionId
        ? programVersionId
        : enrollmentProgramAndGroups?.programVersionId,
      TransferHours: transferHours
        ? transferHours
        : enrollmentTransferDetails?.transferHoursThis,
      TransferDate: transferDate
        ? transferDate
        : enrollmentTransferDetails?.transferDateToNewProgram,
      CompleteTransfer: true,
    };
    studentTransferApi.TransferStudentToProgram(modelTransfer).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          setLoaderState(false);
          redirectAfterSaveEnrollment(studentId);
        } else {
          setLoaderState(false);
        }
      },
      (exception: any) => {
        setLoaderState(false);
      }
    );
  };

  const scrollToTop = () => {
    setTimeout(() => {
      let element: any = document.querySelector(".stepperController");
      if (element !== undefined && element !== null) {
        element.scrollTop = 0;
      }
    }, 500);
  };

  const handleNext = () => {
    scrollToTop();
    if (activeStep === 0) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    scrollToTop();

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });
  const [notificationPopupState, setNotificationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  const [emailAlert, setEmailAlert] = React.useState<boolean>(false);
  const [emailAlertMessage, setEmailAlertMessage] = React.useState<any>(null);
  const [bestEmailTemp, setBestEmailTemp] = React.useState<any>(null);

  const handleStudentInfoForm = (
    studentInfo: IStudentInfo,
    contactInfo: BestContactInfo | null
  ) => {
    enrollmentApi
      .checkEnrollmentEmails(contactInfo?.email)
      .then((response: any) => {
        if (
          (response.resultStatusMessage && isNew == true) ||
          (response.resultStatusMessage &&
            isNew == false &&
            contactInfo?.email !== bestEmailTemp)
        ) {
          setEmailAlert(true);
          setEmailAlertMessage(response.resultStatusMessage);
        } else {
          setStudentInfoModel(studentInfo);
          setContactInfoData(contactInfo);
          handleNext();
          setEmailAlertMessage(null); 
        }
      });
  };

  React.useEffect(() => {
    console.log("emailAlertMessage changed:", emailAlertMessage);
  }, [emailAlertMessage]);

  const userSelectedCampus: string = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleProgramAndSchedule = (
    enrollmentProgramAndGroups: EnrollmentProgramAndGroups | null,
    enrollmentSchedule: EnrollmentSchedule | null
  ) => {
    if (enrollmentProgramAndGroups) {
      enrollmentProgramAndGroups.campusId = userSelectedCampus;
    }
    setEnrollmentProgramAndGroups(enrollmentProgramAndGroups);
    setEnrollmentSchedule(enrollmentSchedule);
    handleNext();
  };

  const handleBadgeAndNotes = (badgeAndNotes: StudentBadgeNumberAndNotes) => {
    setEnrollmentBadgeAndNotes(badgeAndNotes);
  };

  const handleEnrollmentInfo = (
    enrollmentGeneral: EnrollmentGeneral,
    chargeDefinition: ChargeDefinition | null,
    enrollmentTransferDetails: EnrollmentTransferDetails
  ) => {
    setEnrollmentGeneral(enrollmentGeneral);
    setChargeDefinition(chargeDefinition);
    setEnrollmentTransferDetails(enrollmentTransferDetails);
    handleNext();
  };

  const handleProfileInfo = (
    admissions: IStudentInfoAdmissions,
    demographics: IStudentInfoDemographics
  ) => {
    setDemographics(demographics);
    setAdmissions(admissions);
    handleNext();
  };

  const [studentName, setStudentName] = React.useState<string>("");

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  return (
    <Paper
      className={clsx(classes.paperRoot, "stepperController")}
      square={true}
    >
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>

      <Typography color="textPrimary" variant="h5">
        Enrolling: {studentName}
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={activeStep === 0 ? classes.visible : classes.hidden}>
        <StudentInfo
          activeStep={activeStep}
          steps={steps.length}
          handleBack={handleBack}
          handleNext={handleStudentInfoForm}
          isTransferredStudent={isTransferredStudent}
          isNewEnrollment={isNew}
          leadId={leadId}
          confimationModalProps={confirmationPopupState}
          setConfirmationModalProps={setConfirmationPopupState}
          setStudentName={setStudentName}
          studentInfoModelPassthrough={studentInfoModel}
          studentContactModelPassthrough={contactInfoData}
          emailAlert={emailAlert}
          setEmailAlert={setEmailAlert}
          emailAlertMessage={emailAlertMessage}
          setEmailAlertMessage={setEmailAlertMessage}
          bestEmailTemp={bestEmailTemp}
          setBestEmailTemp={setBestEmailTemp}
        ></StudentInfo>
      </div>
      <div className={activeStep === 1 ? classes.visible : classes.hidden}>
        <ProgramAndSchedule
          activeStep={activeStep}
          steps={steps.length}
          handleBack={handleBack}
          handleNext={handleProgramAndSchedule}
          isTransferredStudent={isTransferredStudent}
          programId={programId}
          programVersionId={programVersionId}
          isNewEnrollment={isNew}
          confimationModalProps={confirmationPopupState}
          setConfirmationModalProps={setConfirmationPopupState}
        ></ProgramAndSchedule>
      </div>
      <div className={activeStep === 2 ? classes.visible : classes.hidden}>
        <EnrollmentInfo
          activeStep={activeStep}
          steps={steps.length}
          handleBack={handleBack}
          handleNext={handleEnrollmentInfo}
          isNewEnrollmentTransfer={false}
          programVersionId={enrollmentProgramAndGroups?.programVersionId}
          isNewEnrollment={isNew}
          programVersionSAPPolicyInfo={
            enrollmentProgramAndGroups?.programVersionSAPPolicyInfo
          }
          scheduleId={enrollmentSchedule?.scheduleId}
          isTransferredStudent={isTransferredStudent}
          confimationModalProps={confirmationPopupState}
          setConfirmationModalProps={setConfirmationPopupState}
          notificationModal={notificationPopupState}
          setNotificationModalProps={setNotificationPopupState}
          setSnackBar={setSnackBarProps}
          setIsTransferredStudent={setIsTransferredStudent}
          isNewStudentWorkflow={true}
          setHighSchoolMandatory={setHighSchoolMandatory}
          enrollmentGeneralModelPassthrough={enrollmentGeneral}
          enrollmentTransferDetailsModelPassthrough={enrollmentTransferDetails}
        ></EnrollmentInfo>
      </div>
      <div className={activeStep === 3 ? classes.visible : classes.hidden}>
        <ProfileInfo
          isTransferredStudent={isTransferredStudent}
          isNewEnrollment={isNew}
          handleBack={handleBack}
          handleNext={handleProfileInfo}
          currentProfileData={studentInfoModel}
          confimationModalProps={confirmationPopupState}
          setConfirmationModalProps={setConfirmationPopupState}
          isHighSchoolMandatory={isHighSchoolMandatory}
        ></ProfileInfo>
      </div>

      <div className={activeStep === 4 ? classes.visible : classes.hidden}>
        <FinalizeEnrollment
          activeStep={activeStep}
          steps={steps.length}
          handleBack={handleBack}
          finalizeEnrollment={finalizeEnrollment}
          isNewEnrollment={isNew}
          setBadgeAndNotes={handleBadgeAndNotes}
          setActionAfterFinalize={setActionAfterFinalize}
          isNewEnrollmentTransfer={false}
          loading={loading}
          setLoading={setLoading}
        ></FinalizeEnrollment>
      </div>

      <ConfirmationModal {...confirmationPopupState} />
      <NotificationModal {...notificationPopupState} />
      <ImportLeadDialog
        visible={isImport}
        onCancel={() => {
          addQuery("isImport", "false");
        }}
        onImport={handleImport}
        postProcessLeadImport={postProcessLead}
      ></ImportLeadDialog>
    </Paper>
  );
};

export default withRouter(NewEnrollment);
