import * as React from "react";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

export const IncrementTypeApiAutoComplete = {
  config: {
    acId: "incrementTypes",
    cacheTime: 5,
    route: "/AcademicRecords/Increment/GetIncrementTypes",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: true,
    storeInCache: true,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  }
};

const IncrementTypeAutoComplete = (props: FilterParams) => {
  
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const inputRef: any = React.useRef(null);
  
  const filterHandle = props.filterHandle;
  const programsOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
  };

  let stuEnrollId = "";
  if (selectedEnrollment) {
    stuEnrollId = selectedEnrollment.stuEnrollId;
  }
  const [disabled, setIsDisabled] = React.useState<any | null>();
  React.useEffect(() => {
    setIsDisabled(props.disabled);
  }, [props.disabled]);
  return (
    <div>
      <ApiAutoComplete
        disabled={disabled}
        config={IncrementTypeApiAutoComplete.config}
        loading={selectedEnrollment ? false : true}
        reqParams={{ stuEnrollId: stuEnrollId }}
        classes={{
          option: "text-black"
        }}
        label="Increment Type"
        onChange={(e: any) => {
          programsOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default IncrementTypeAutoComplete;
