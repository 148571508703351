import {
  CustomHeadCell,
  Order,
} from "../../../../interfaces/common/Table/CustomHeadCell";
import CustomTableHeader from "../../../../components/Tables/CustomTableHeader";
import React, { useEffect, Fragment } from "react";
import {
  TableBody,
  makeStyles,
  createStyles,
  TableRow,
  TableCell,
  Switch,
  Link,
} from "@material-ui/core";
import * as TableFunctions from "../../../../utils/tableFunctions";

import {
  IScheduleDetailsGridRow,
  ScheduleDetailsGridRow,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleDetailsGridRow";
import {
  IClassScheduleDetails,
  ClassScheduleDetails,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleDetails";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parse } from "date-fns";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      height: "calc(100% - 200px)",
      overflowY: "auto",
      paddingBottom: theme.spacing(2),
    },
    mainBody: {
      height: "100%",
    },
    timeField: {
      width: "120px",
      height: "46px",
    },
    cellWidth: {
      minWidth:100,
    }
  })
);

type SchedulesDetailsGridProps = {
  model: Array<IClassScheduleDetails>;
  setModel: any; //set array
  readonly: boolean; 
};
const SchedulesDetailsGrid = (props: SchedulesDetailsGridProps) => {
  const classes = useStyles({});

  const headerCells: CustomHeadCell<IScheduleDetailsGridRow>[] = [
    {
      id: "optionName",
      numeric: false,
      disablePadding: false,
      label: "",
      widthType: "Remainder",
    },

    {
      id: "sunday",
      numeric: false,
      disablePadding: false,
      label: "Sunday",
      widthType: "M",
    },
    {
      id: "monday",
      numeric: false,
      disablePadding: false,
      label: "Monday",
      widthType: "M",
    },
    {
      id: "tuesday",
      numeric: false,
      disablePadding: false,
      label: "Tuesday",
      widthType: "M",
    },
    {
      id: "wednesday",
      numeric: false,
      disablePadding: false,
      label: "Wednesday",
      widthType: "M",
    },
    {
      id: "thrusday",
      numeric: false,
      disablePadding: false,
      label: "Thursday",
      widthType: "M",
    },
    {
      id: "friday",
      numeric: false,
      disablePadding: false,
      label: "Friday",
      widthType: "M",
    },
    {
      id: "saturday",
      numeric: false,
      disablePadding: false,
      label: "Saturday",
      widthType: "M",
    },
  ];

  const [scheduleDetails, setScheduleDetails] = React.useState<
    Array<IScheduleDetailsGridRow>
  >([]);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IScheduleDetailsGridRow>(
    "order"
  );
  const [confirmationPopupState, setConfirmationPopupState] = React.useState<
    any
  >({
    id: "simple-popup",
    message: "",
  });

  const transpose = (data: Array<IClassScheduleDetails>) => {
    let result: Array<IScheduleDetailsGridRow> = new Array<
      IScheduleDetailsGridRow
    >();
    let columnValues = [
      "timein",
      "lunchout",
      "lunchin",
      "timeout",
      "maximumHoursWithoutLunch",
      "total",
      "allowEarlyin",
      "allowLateout",
      "allowExtraHours",
      "checkTardyin",
      "maxBeforeTardy",
      "tardyIntime",
      "minimumHoursToBePresent",
      "maximumHoursPerDay",
    ];
    let checkModel = new ClassScheduleDetails(7, new Date());

    Object.keys(checkModel).forEach((key) => {
      if (columnValues.indexOf(key) > -1) {
        let newRow = new ScheduleDetailsGridRow();
        newRow.key = key;
        newRow.type = typeof checkModel[key as keyof IClassScheduleDetails];

        if (key === "timein") {
          newRow.optionName = "Time In";
          newRow.order = 1;
        }
        if (key === "lunchout") {
          newRow.optionName = "Lunch Out";
          newRow.order = 2;
        }
        if (key === "lunchin") {
          newRow.optionName = "Lunch In";
          newRow.order = 3;
        }
        if (key === "timeout") {
          newRow.optionName = "Time Out";
          newRow.order = 4;
        }
        if (key === "maximumHoursWithoutLunch") {
          newRow.optionName = "Maximum Hours w/o Lunch";
          newRow.order = 5;
        }
        if (key === "total") {
          newRow.optionName = "Scheduled Hours";
          newRow.order = 6;
        }
        if (key === "allowEarlyin") {
          newRow.optionName = "Allow Early In";
          newRow.order = 7;
        }
        if (key === "allowLateout") {
          newRow.optionName = "Allow Late Out";
          newRow.order = 8;
        }
        if (key === "allowExtraHours") {
          newRow.optionName = "Allow Extra Hours";
          newRow.order = 9;
        }
        if (key === "checkTardyin") {
          newRow.optionName = "Check Tardy In";
          newRow.order = 10;
        }
        if (key === "maxBeforeTardy") {
          newRow.optionName = "Max In Before Tardy";
          newRow.order = 11;
        }
        if (key === "tardyIntime") {
          newRow.optionName = "Assign Tardy In Time";
          newRow.order = 12;
        }
        if (key === "minimumHoursToBePresent") {
          newRow.optionName = "Minimum Hours Considered";
          newRow.order = 13;
        }
        if (key === "maximumHoursPerDay") {
          newRow.optionName = "Maximum Time Allowed";
          newRow.order = 14;
          newRow.defaultValue = 24;
        }
        result.push(newRow);
      }
    });

    if (props.model) {
      props.model
        .sort((e1, e2) => {
          if (e1.dayOfWeek % 7 > e2.dayOfWeek % 7) {
            return 1;
          }
          if (e1.dayOfWeek % 7 < e2.dayOfWeek % 7) {
            return -1;
          }
          return 0;
        })
        .forEach((detail) => {
          let column: keyof ScheduleDetailsGridRow =
            detail.dayOfWeek === 0
              ? "sunday"
              : detail.dayOfWeek === 1
                ? "monday"
                : detail.dayOfWeek === 2
                  ? "tuesday"
                  : detail.dayOfWeek === 3
                    ? "wednesday"
                    : detail.dayOfWeek === 4
                      ? "thrusday"
                      : detail.dayOfWeek === 5
                        ? "friday"
                        : "saturday";

          Object.keys(detail).forEach((objKey) => {
            let rowToUpdate: any;
            rowToUpdate = result.find((obj) => obj.key === objKey);
            if (rowToUpdate !== undefined) {
              let val = detail[objKey as keyof IClassScheduleDetails];
              rowToUpdate[column] = val as any;
            }
          });
        });
    }

    return result;
  };

  useEffect(() => {
    setScheduleDetails(transpose(props.model));
  }, [props.model]);

  const handleFieldChange = (
    property: string,
    dayOfWeek: number,
    newValue: any
  ) => {
    let arrayToUpdate: IClassScheduleDetails[] = [...props.model];
    let modelToUpdate: any;
    modelToUpdate = arrayToUpdate.find((prop) => prop.dayOfWeek === dayOfWeek);
    if (modelToUpdate !== undefined) {
      modelToUpdate[property as keyof IClassScheduleDetails] = newValue;
    }
    props.setModel([...arrayToUpdate]);
  };
  const createDetailsSwitch = (key: string, value: any, day: number) => {
    return (
      <Switch
        checked={value}
        name={key + "_" + day}
        id={key + "_" + day}
        onChange={(e: any) => {
          handleFieldChange(key, day, e ? e.target.checked : false);
        }}
        color="primary"
      />
    );
  };

  const createNumberInput = (key: string, value: any, day: number, row? : IScheduleDetailsGridRow) => {
    return (
      <InputField
        type={InputType.NUMBER}
        key={key + "_" + day}
        id={key + "_" + day}
        name={key + "_" + day}
        defaultValue={value == null && row?.defaultValue != null ? row.defaultValue : value}
        decimal
        decimalPlaces={2}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          handleFieldChange(key, day, e.target.value);        
        }}
      // inputRef={ref=> null}
      />
    );
  };

  const getTime = (value: any) => {
    if (value != null) {
      const formattedTime = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      return formattedTime;
    } else {
      return "";
    }
  }

  const defaultYear = 1990;
  const defaultMonthIndex = 0;
  const defaultDate = 1;

  const createTimePickerInput = (key: string, value: any, day: number) => {
    return (
      props.readonly ? getTime(value) :
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          readOnly = {props.readonly}
          className={classes.timeField}
          id={key + "_" + day}
          mask="__:__ _M"
          name={key + "_" + day}
          value={value}
          onChange={(_date, _value) => {
            let parsedVal = parse(
              _value ? (_value as string) : "",
              "hh:mm a",
              Date.UTC(defaultYear, defaultMonthIndex, defaultDate, 0, 0, 0)
            ).toLocaleString();
            handleFieldChange(key, day, parsedVal === 'Invalid Date' ? '' : parsedVal);
          }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const createCell = (type: string, key: string, value: any, day: number, row? : IScheduleDetailsGridRow) => {
    return (
      <Fragment>
        {type === "boolean" && createDetailsSwitch(key, value, day)}
        {type === "number" && createNumberInput(key, value, day, row)}
        {type === "object" && createTimePickerInput(key, value, day)}
      </Fragment>
    );
  };
  return (
    <div className={classes.tableWrapper}>
      <CustomTableHeader<IScheduleDetailsGridRow>
        cells={headerCells}
        defaultOrder={order}
        defaultOrderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        key={"scheduleDetailGrid"}
      />
      <TableBody className={classes.mainBody}>
        {TableFunctions.stableSort(
          scheduleDetails,
          TableFunctions.getSorting(order, orderBy) as any
        ).map((row: IScheduleDetailsGridRow, index: any) => {
          return (
            <React.Fragment>
              <TableRow key={"details_" + row.optionName}>
                <TableCell className={classes.cellWidth}>{row.optionName}</TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.sunday, 0, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.monday, 1, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.tuesday, 2, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.wednesday, 3, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.thrusday, 4, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.friday, 5, row)}
                </TableCell>
                <TableCell className={classes.cellWidth}>
                  {createCell(row.type, row.key, row.saturday, 6, row)}
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>

    </div>
  );
};

export default SchedulesDetailsGrid;
