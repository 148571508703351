import API from "../../apiWrapper";
import {ITransactionCode} from "../../../interfaces/setup/financial/ITransactionCode";

export const GetTransactionCodesByCampusId = (campusId: string) => {
  return API()
    .get("/FinancialAid/TransactionCode/GetTransactionCodesByCampus", {
      params: {
        campusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const GetTransactionCodeById = (transCodeId: string) => {
  return API()
    .get("/FinancialAid/TransactionCode/GetTransactionCodeById", {
      params: {
        transCodeId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const UpsertTransactionCode = (params: ITransactionCode) => {
  return API()
    .post("/FinancialAid/TransactionCode/UpsertTransactionCode", params)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};
