import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import {
	Typography,
	Card,
	CardActions,
	CardContent,
	InputAdornment,
	Box,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import StudentGroupsAutoComplete from "../../../../components/AutoComplete/StudentGroupsAutoComplete";
import { EnrollmentProgramAndGroups } from "../../../../interfaces/student/academics/EnrollmentProgram";
import ProgramsAutoComplete from "../../../../components/AutoComplete/ProgramsAutoComplete";
import ProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramVersionAutoComplete";

import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import * as studentTransferApi from "../../../../api/student/transfer/studentTransferApi";
import { IChangeProgramVersionDetail } from "../../../../interfaces/student/transfer/IStudentTransferInfo";

import { useFormContext, FieldError } from "react-hook-form";

import { getProgramVersionSAPDetailsPolicies } from "../../../../api/academicRecords/programVersionApi";
import IProgramVersionSAPPolicyInfo from "../../../../interfaces/academicRecords/IProgramVersionSAPPolicyInfo";
import ProgramScheduleSelect from "../../../../components/AutoComplete/ProgramScheduleAutoComplete";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background,
		},
		cardTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		},

		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		fullWidthField: {
			width: "100%",
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
	})
);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiFormLabel-root": {
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				width: "80%",
			},
		},
	})
)(TextField);

type SnackBarStateProps = {
	showSnackBar: boolean;
	messageInfo: string | undefined;
	variant: "success" | "error" | "warning" | "info";
};

type transferProps = {
	newSchedule: string;
	newStudentGroups: string[];
	newScheduleError: string;
	newStudentGroupsError: string;
};

type ProgramAndGroupsProps = {
	enrollmentProgramAndGroups: EnrollmentProgramAndGroups;
	setEnrollmentProgramAndGroups: (
		enrollmentProgramAndGroups: EnrollmentProgramAndGroups
	) => void;
	isNewEnrollment: boolean;
	isTransferredStudent?: boolean;
	isNewEnrollmentWorkFlow?: boolean;
	showInactivePrograms: boolean;
};
const ProgramAndGroups = (props: ProgramAndGroupsProps) => {
	const classes = useStyles({});
	const data = useFormContext();
	const userSelectedCampus: string = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const para = new URLSearchParams(window.location.search);
	let newStudent = para.get("isNew");

	const history = useHistory();
	const studentId = useSelector((state: any) => state.student.studentId);

	const { enrollmentProgramAndGroups, setEnrollmentProgramAndGroups } = props;

	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [transfer, setTransfter] = React.useState<transferProps>({
		newSchedule: "",
		newStudentGroups: [],
		newScheduleError: "",
		newStudentGroupsError: "",
	});
	const [newScheduleErrorMessage, setNewScheduleErrorMessage] =
		React.useState<string>("");
	const [newStudentGroupsErrorMessage, setNewStudentGroupsErrorMessage] =
		React.useState<string>("");
	const [snackBarProps, setSnackBarProps] = React.useState<SnackBarStateProps>({
		showSnackBar: false,
		messageInfo: undefined,
		variant: "info",
	});
	const [confirmationPopupState, setConfirmationPopupState] =
		React.useState<any>({
			id: "confirmation-popup",
			message: "",
			cancelText: "No",
			open: false,
		});
	const [reConfirmationPopupState, setReConfirmationPopupState] =
		React.useState<any>({
			id: "re-confirmation-popup",
			message: "",
			maxWidth: "md",
			open: false,
		});
	const params = new URLSearchParams(window.location.search);
	let programId: string | null = null;
	let programVersionId: string | null = null;
	let scheduleId: string | null = null;

	useEffect(() => {
		programId = params.get("programId");
		if (programId) {
			let updateModel = enrollmentProgramAndGroups;
			updateModel.programId = programId;
			setEnrollmentProgramAndGroups({ ...updateModel });
		}
	}, []);
	
	useEffect(() => {
		programId = params.get("programId");
		programVersionId = params.get("programVersionId");
		if (programId && programVersionId) {
			let updateModel = enrollmentProgramAndGroups;
			updateModel.programVersionId = programVersionId;
			setEnrollmentProgramAndGroups({ ...updateModel });
		}
	}, [enrollmentProgramAndGroups.programId]);

	const handleFieldOnChange = (data: any, oldProgramVersionId?: string, isCreditHour?: boolean) => {
		if (
			!props.isTransferredStudent &&
			(props.isNewEnrollmentWorkFlow === undefined ||
				props.isNewEnrollmentWorkFlow === false)
		) {
			if (data.value != oldProgramVersionId) {
				setConfirmationPopupState((state: any) => {
					return {
						...state,
						open: true,
						confirmationText:
							"Do you want to change the program version of the enrollment.",
						confirmationButton: "Yes",
						title: "Change Program Version",
						onConfirmEvent: () => {
							let updateModel = enrollmentProgramAndGroups;
							updateModel.programVersionId = oldProgramVersionId;
							updateModel.isCreditHour = isCreditHour;
							setEnrollmentProgramAndGroups({ ...updateModel });
							let getDetailModel: IChangeProgramVersionDetail = {
								EnrollmentId: enrollmentProgramAndGroups.stuEnrollId,
								NewProgramVersionId: data.value,
								OldProgramVersionId: oldProgramVersionId,
							};
							studentTransferApi
								.GetDetailOnProgramVersionChanges(getDetailModel)
								.then((response: any) => {
									if (response != null) {
										setLoaderState(false);
										reConfirmationWithRules(
											data.value,
											oldProgramVersionId,
											response
										);
									} else {
										setLoaderState(false);
										setSnackBarProps((props: any) => {
											return {
												variant: "error",
												showSnackBar: true,
												messageInfo: "Please try again.",
											};
										});
									}
								});
							setConfirmationPopupState((updatedState: any) => {
								return { ...updatedState, open: false };
							});
						},
						onCancelEvent: () => {
							let updateModel = enrollmentProgramAndGroups;
							updateModel.programVersionId = oldProgramVersionId;
							updateModel.isCreditHour = isCreditHour;
							setEnrollmentProgramAndGroups({ ...updateModel });

							setConfirmationPopupState((updatedState: any) => {
								return { ...updatedState, open: false };
							});
						},
					};
				});
			}
		} else {
			getProgramVersionSAPDetailsPolicies(data.value, undefined)
				.then((promisedData: IProgramVersionSAPPolicyInfo) => {
					let updateModel = enrollmentProgramAndGroups;
					updateModel.programVersionSAPPolicyInfo = promisedData;
					updateModel.programVersionId = data.value;
					updateModel.isCreditHour = isCreditHour;
					setEnrollmentProgramAndGroups({ ...updateModel });
				})
				.catch((exception: any) => {
					let updateModel = enrollmentProgramAndGroups;
					updateModel.programVersionId = data.value;
					updateModel.isCreditHour = isCreditHour;
					setEnrollmentProgramAndGroups({ ...updateModel });
				});

			setConfirmationPopupState((updatedState: any) => {
				return { ...updatedState, open: false };
			});
			setConfirmationPopupState((updatedState: any) => {
				return { ...updatedState, open: false };
			});
		}
	};

	const setReconfirmationUpdatedState = (
		gradesMessage: string,
		addCourseMessage: string,
		deleteCourseMessage: string,
		newProgramVersion: string,
		oldProgramVersionId: string
	) => {
		setReConfirmationPopupState((state: any) => {
			return {
				...state,
				open: true,
				confirmationText: (
					<div>
						<p>
							<b>
								i. The effects due to the change in Program Versions as under.
							</b>
						</p>
						<Box m={2} pt={3}>
							<ol>
								{gradesMessage != "" ? <li>{gradesMessage}</li> : ""}
								{addCourseMessage != "" ? <li>{addCourseMessage}</li> : ""}
								{deleteCourseMessage != "" ? (
									<li>{deleteCourseMessage}</li>
								) : (
									""
								)}
								<li>
									Current schedule would be removed and new had to be specified
								</li>
								<li>
									Charging method would be changed due to the change in program
									version
								</li>
							</ol>
						</Box>
						<p>
							<b>
								ii. Fields to specify the Groups/s and Time Clock schedule for
								the enrollment with the new program version. The fields are
								mandatory to confirm the change in Program Version
							</b>
						</p>
						<Grid container direction="row" spacing={2}>
							<Grid item sm={12} md={12}>
								<ProgramScheduleSelect
									programVersion={newProgramVersion}
									// disabled={props.disableSelection}
									valueFilter={
										transfer
											? {
													key: "value",
													values: [transfer.newSchedule],
											  }
											: undefined
									}
									filterHandle={(v: any) => {
										let td = transfer;
										td.newSchedule = v ? v.value : undefined;
										setTransfter({ ...td });
										data.clearError("newSchedule");
										let newTransferData = transfer;
										newTransferData.newScheduleError = v
											? ""
											: transfer.newScheduleError;
										setTransfter({ ...newTransferData });
										setReconfirmationUpdatedState(
											gradesMessage,
											addCourseMessage,
											deleteCourseMessage,
											newProgramVersion,
											oldProgramVersionId
										);
									}}
									name="newSchedule"
									id="newSchedule"
									key="newSchedule"
									error={!!transfer.newScheduleError}
									helperText={transfer.newScheduleError}
									label={"Schedule *"}
								/>
							</Grid>
							<Grid item sm={12} md={12}>
								<StudentGroupsAutoComplete
									id="newStudentGroups"
									filterHandle={(groups: any) => {
										let newStudentGroupsUpdate = groups.map(
											(g: any) => g.value
										);
										let td = transfer;
										td.newStudentGroups = newStudentGroupsUpdate;
										setTransfter({ ...td });
										data.clearError("newStudentGroups");
										let newTransferData = transfer;
										newTransferData.newStudentGroupsError =
											newStudentGroupsUpdate.length > 0
												? ""
												: transfer.newStudentGroupsError;
										setTransfter({ ...newTransferData });
										setReconfirmationUpdatedState(
											gradesMessage,
											addCourseMessage,
											deleteCourseMessage,
											newProgramVersion,
											oldProgramVersionId
										);
									}}
									name="newStudentGroups"
									label="Groups *"
									multiple={true}
									includeAllValue={false}
									valueFilter={
										transfer
											? {
													key: "value",
													values: transfer.newStudentGroups,
											  }
											: undefined
									}
									inputRef={data.register({
										required: false,
									})}
									error={!!transfer.newStudentGroupsError}
									helperText={transfer.newStudentGroupsError}
								/>
							</Grid>
						</Grid>
					</div>
				),
				confirmationButton: "Confirm",
				title: "Confirmation to Change Program Version",
				onConfirmEvent: () => {
					let modelTransfer: IChangeProgramVersionDetail = {
						EnrollmentId: enrollmentProgramAndGroups.stuEnrollId,
						NewProgramVersionId: newProgramVersion,
						OldProgramVersionId: oldProgramVersionId,
						newScheduleId: transfer.newSchedule,
						newStudentGroups: transfer.newStudentGroups,
					};
					// setLoaderState(true);
					let isError: boolean = false;
					let newTransferData = transfer;
					if (transfer.newSchedule === "" || transfer === undefined) {
						data.setError("newSchedule", "newSchedule", "Schedule is required");
						setNewScheduleErrorMessage("Schedule is required");
						isError = true;
						newTransferData.newScheduleError = "Schedule is required";
					} else {
						data.clearError("newSchedule");
						setNewScheduleErrorMessage("");
						isError = false;
						newTransferData.newScheduleError = "";
					}

					if (transfer.newStudentGroups.length < 1) {
						data.setError(
							"newStudentGroups",
							"newStudentGroups",
							"At least one group is required"
						);
						setNewStudentGroupsErrorMessage("At least one group is required");
						isError = true;
						newTransferData.newStudentGroupsError =
							"At least one group is required";
					} else {
						data.clearError("newStudentGroups");
						setNewStudentGroupsErrorMessage("");
						newTransferData.newStudentGroupsError = "";
						isError = false;
					}

					setTransfter({ ...newTransferData });

					if (isError === false) {
						studentTransferApi.UpdateStudentProgramVersion(modelTransfer).then(
							(response: any) => {
								if (response.resultStatus === 0) {
									setLoaderState(false);
									let updateModel = enrollmentProgramAndGroups;
									updateModel.programVersionId = newProgramVersion;
									setEnrollmentProgramAndGroups({ ...updateModel });
									setSnackBarProps((props: any) => {
										return {
											variant: "success",
											showSnackBar: true,
											messageInfo:
												"The group and time clock schedule are update to the new ones specified ",
										};
									});
									let redirectUrl =
										"/student/1/profile/enrollments?campusId=" +
										userSelectedCampus +
										"&studentId=" +
										studentId +
										"&reloadEnrollmentSelector=true";
									history.replace(redirectUrl);
								} else {
									setLoaderState(false);
									let updateModel = enrollmentProgramAndGroups;
									updateModel.programVersionId = oldProgramVersionId;
									setEnrollmentProgramAndGroups({ ...updateModel });
									setSnackBarProps((props: any) => {
										return {
											variant: "error",
											showSnackBar: true,
											messageInfo:
												"Student program version is not changed. Please try again.",
										};
									});
								}
							},
							(exception: any) => {
								setLoaderState(false);
								setSnackBarProps((snackBarProps: any) => {
									return {
										variant: "error",
										showSnackBar: true,
										messageInfo: exception,
									};
								});
							}
						);
						setReConfirmationPopupState((updatedState: any) => {
							return { ...updatedState, open: false };
						});
					} else {
						setReconfirmationUpdatedState(
							gradesMessage,
							addCourseMessage,
							deleteCourseMessage,
							newProgramVersion,
							oldProgramVersionId
						);
					}
				},
				onCancelEvent: () => {
					data.clearError("newStudentGroups");
					data.clearError("newSchedule");
					let updateModel = enrollmentProgramAndGroups;
					updateModel.programVersionId = oldProgramVersionId;
					setEnrollmentProgramAndGroups({ ...updateModel });

					setReConfirmationPopupState((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				},
			};
		});
	};
	const reConfirmationWithRules = (
		newProgramVersion: any,
		oldProgramVersionId: any,
		response: any
	) => {
		let gradesMessage = "";
		let addCourseMessage = "";
		let deleteCourseMessage = "";
		response.forEach((element: any) => {
			switch (element.actionDescription) {
				case "Added":
					if (element.detail) {
						addCourseMessage =
							element.detail +
							" courses that have to be added as they are part of the new program version";
					}
					break;
				case "Removed":
					if (element.detail) {
						deleteCourseMessage =
							element.detail +
							" courses that have to be removed as they are not part of the new program version";
					}
					break;
				case "Grades":
					if (element.detail != "0") {
						gradesMessage =
							"Any already posted grades for component/s which are not part of the new program version and thus now won't be applicable after change";
					}
					break;
			}
		});
		setReconfirmationUpdatedState(
			gradesMessage,
			addCourseMessage,
			deleteCourseMessage,
			newProgramVersion,
			oldProgramVersionId
		);
	};

	return (
		<Card square={true}>
			<Typography className={clsx(classes.cardTitle)}>
				&nbsp; Program And Groups
			</Typography>
			<CardContent className={classes.cardContent}>
				<Grid container direction="row" spacing={2}>
					<Grid item sm={12} md={4}>
						{!props.isNewEnrollmentWorkFlow ? (
							<ThemeTextField
								disabled
								id="programOfStudy"
								label="Program Of Study"
								className={classes.fullWidthField}
								value={enrollmentProgramAndGroups.programDescription}
							/>
						) : (
							<ProgramsAutoComplete
								id="programId"
								name="programId"
								label="Program *"
								activePrograms={props.showInactivePrograms ? false :  true}
								includeCreditPrograms={true}
								valueFilter={
									enrollmentProgramAndGroups.programId
										? {
												key: "value",
												values: [enrollmentProgramAndGroups.programId],
										  }
										: undefined
								}
								inputRef={data.register({
									required: {
										value: true,
										message: "Program is required.",
									},
								})}
								helperText="Program is required."
								error={!!data.errors.programId}
								filterHandle={(value: any) => {
									if (value !== null) {
										/*on change of program empty program version*/
										let updateModel = enrollmentProgramAndGroups;

										if (value.value != enrollmentProgramAndGroups.programId) {
											updateModel.programVersionId = undefined;
											updateModel.cipCode = value.cipCode || "";
										}
										updateModel.programId = value.value;
										setEnrollmentProgramAndGroups({ ...updateModel });
									}
								}}
							></ProgramsAutoComplete>
						)}
					</Grid>
					<Grid item sm={12} md={4}>
						<ProgramVersionAutoComplete
							id="programVersionId"
							name="programVersionId"
							label="Program Version *"
							valueFilter={
								enrollmentProgramAndGroups.programVersionId
									? {
											key: "value",
											values: [enrollmentProgramAndGroups.programVersionId],
									  }
									: undefined
							}
							programId={enrollmentProgramAndGroups.programId}
							inputRef={data.register({
								required: {
									value: true,
									message: "Program Version is required.",
								},
							})}
							helperText="Program Version is required."
							error={!!data.errors.programVersionId}
							filterHandle={(v: any) => {
								if (v !== null) {
									handleFieldOnChange(
										v,
										enrollmentProgramAndGroups.programVersionId,
										v?.isCredit
									);
								}
							}}
							shouldCheckForEffectiveEndDate={true}
							showInactivePrograms={(newStudent == "true" || newStudent == "false") ? false : true}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
							<ThemeTextField
								disabled
								id="cipCode"
								label="CIP Code"
								className={classes.fullWidthField}
								value={enrollmentProgramAndGroups.cipCode ?? ""}
							/>
					</Grid>
				</Grid>

				<Grid container direction="row" spacing={2}>
					<Grid item xs={7}>
						<h5></h5>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={2}>
					<Grid item sm={12} md={8}>
						<StudentGroupsAutoComplete
							id="studentGroupIds"
							filterHandle={(groups: any) => {
								let updateModel = enrollmentProgramAndGroups;
								updateModel.studentGroupIds = groups.map((g: any) => g.value);
								setEnrollmentProgramAndGroups({ ...updateModel });
							}}
							name="studentGroupIds"
							label= {enrollmentProgramAndGroups?.isCreditHour == true ? "Groups" : "Groups *"}
							multiple={true}
							valueFilter={
								enrollmentProgramAndGroups.studentGroupIds
									? {
											key: "value",
											values: enrollmentProgramAndGroups.studentGroupIds,
									  }
									: undefined
							}
							  params={{
                			    inActiveStudentGroup: true,
                			  }}
							includeAllValue = {false}
							inputRef={data.register({
								validate: {
									atLeastOneRequired: (value) =>
										(enrollmentProgramAndGroups.studentGroupIds &&
											enrollmentProgramAndGroups.studentGroupIds.length > 0) ||
											enrollmentProgramAndGroups?.isCreditHour == true ||
										"Group(s) are required.",
								},
							})}
							error={!!data.errors.studentGroupIds}
							helperText={
								data.errors.studentGroupIds
									? (data.errors.studentGroupIds as FieldError).message
									: undefined
							}
						/>
					</Grid>
				</Grid> 	
				
			</CardContent>
			<ConfirmationModal {...confirmationPopupState} />
			<ConfirmationModal {...reConfirmationPopupState} />
			<CardActions></CardActions>
		</Card>
	);
};

export default ProgramAndGroups;
