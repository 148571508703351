import React from "react";
import ChargeDefinitionSetup from "../chargeDefinition/ChargeDefinitionSetup";
import ConfigAppSettings from "../../../pages/setup/appSettings/ConfigAppSettings";

const ComponentViewer = (props: any) => {

  return (
    <React.Fragment>
      {props.component &&
        props.history.location.pathname.indexOf("chargeDefinitionSetup") >
          -1 && <ChargeDefinitionSetup></ChargeDefinitionSetup>}
      {props.history.location.pathname ===
      "/setup/systemConfiguration/applicationSettings" ? (
        <ConfigAppSettings />
      ) : null}
    </React.Fragment>
  );
};

export default ComponentViewer;
