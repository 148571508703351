import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Typography, Grid, Card, CardContent, Checkbox, Switch, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useForm, FormContext, FieldError } from 'react-hook-form';
import CampusForCurrentUserAutoComplete from '../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import ProgramVersionAutoComplete from '../../components/AutoComplete/ProgramVersionAutoComplete';
import CustomCardTitle from '../../interfaces/common/card/CustomCardTitle';
import { IGradDateCalculator } from '../../interfaces/Tools/IGradDateCalculator';
import ScheduleProgramAutoComplete from '../../components/AutoComplete/ScheduleProgramAutoComplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeTextField } from '../../components/_Layout/Inputs/ThemeTextField';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import * as holidaysApi from "../../api/setup/holiday/holidaysApi";
import IsoOutlinedIcon from '@material-ui/icons/IsoOutlined';
import { Holiday } from '../../interfaces/setup/holiday/holiday';
import * as ProgramVersionApi from "../../api/setup/academics/programVersionApi";
import { EmptyGuid } from '../../utils/constants';
import { IScheduleGridRow } from '../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow';
import * as CalculateGradeDateApi from '../../api/Common/calculateGradeDateApi';
import { useState } from 'react';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			minHeight: '100%',
			marginTop: theme.spacing(2),
			paddingTop: theme.spacing(1),
			overflowX: 'hidden',
			overflowY: 'auto',
			backgroundColor: theme.palette.background.paper,
			flexDirection: "column",
			borderRadius: theme.shape.borderRadius,
			'& .MuiTypography-root': {
				fontWeight: 'bold',
			},
		},
		card: {
			margin: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.shape.borderRadius,
		},
		tabContent: {
			marginTop: theme.spacing(2),
			padding: theme.spacing(3),
		},
		label: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			fontWeight: 600,
			paddingRight: theme.spacing(2),
		},
		dropdown: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		cardContent: {
			padding: 14,
		},
		calculationTab: {
			marginTop: theme.spacing(4),
			marginLeft: theme.spacing(2),
		},
		width100: {
			width: '100%',
		},
		fullWidthElement: {
			width: "100%",
		},
		holidayListContainer: {
			maxHeight: 400,
			maxWidth: 600,
			overflowY: "auto",
			borderRadius: theme.shape.borderRadius,
			padding: theme.spacing(2),
			backgroundColor: "#f9f9f9",
			marginTop: theme.spacing(2),
			boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
			border: "1px solid #e0e0e0",

			"&::-webkit-scrollbar": {
				width: "8px",
			},
			"&::-webkit-scrollbar-track": {
				background: "#f1f1f1",
				borderRadius: "10px",
			},
			"&::-webkit-scrollbar-thumb": {
				background: "#bbb",
				borderRadius: "10px",
			},
			"&::-webkit-scrollbar-thumb:hover": {
				background: "#999",
			},
		},

		holidayItem: {
			padding: theme.spacing(1.5),
			marginBottom: theme.spacing(1),
			backgroundColor: "#fff",
			borderRadius: "8px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
			border: "1px solid #ddd",
			"&:nth-of-type(odd)": {
				backgroundColor: "#f4f4f4",
			},
		},
		switch: {
			display: "flex",
			alignItems: "flex",
			padding: theme.spacing(0, 0.5),
			height: theme.spacing(8.6),
		},
	})
);

const GradDateCalculator = (props: any) => {

	const classes = useStyles({});

	const [loading, setLoading] = useState<boolean>(false);

	const [snackBarProps, setSnackBarProps] = React.useState<any>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [value, setValue] = React.useState(0);

	const data = useForm<any>({ mode: "onBlur" });

	const [holidays, setHolidays] = React.useState<Array<Holiday>>([]);

	const [schedules, setSchedules] = React.useState<Array<IScheduleGridRow>>(
		new Array<IScheduleGridRow>()
	);

	const resetWeeklyHours = () => ({
		sunday: 0.00,
		monday: 0.00,
		tuesday: 0.00,
		wednesday: 0.00,
		thursday: 0.00,
		friday: 0.00,
		saturday: 0.00,
	});

	const initialState: IGradDateCalculator = {
		campusId: userSelectedCampus,
		programVersionId: "",
		scheduleId: "",
		includeHolidays: true,
		startDate: null,
		totalProgramHours: 0.00,
		graduationDate: "N/A",
		weeklyHours: resetWeeklyHours(),
	};

	const convertModelForBackend = (model: IGradDateCalculator) => {
		return {
			CampusId: model.campusId,
			StudentStartDate: model.startDate,
			TotalProgramHours: model.totalProgramHours,
			MondayHours: model.weeklyHours?.monday ?? 0.00,
			TuesdayHours: model.weeklyHours?.tuesday ?? 0.00,
			WednesdayHours: model.weeklyHours?.wednesday ?? 0.00,
			ThursdayHours: model.weeklyHours?.thursday ?? 0.00,
			FridayHours: model.weeklyHours?.friday ?? 0.00,
			SaturdayHours: model.weeklyHours?.saturday ?? 0.00,
			SundayHours: model.weeklyHours?.sunday ?? 0.00,
			IncludeHolidays: model.includeHolidays,
			DailyHours: [
				{ DayOfWeek: 0, Hours: model.weeklyHours?.sunday ?? 0.00 },
				{ DayOfWeek: 1, Hours: model.weeklyHours?.monday ?? 0.00 },
				{ DayOfWeek: 2, Hours: model.weeklyHours?.tuesday ?? 0.00 },
				{ DayOfWeek: 3, Hours: model.weeklyHours?.wednesday ?? 0.00 },
				{ DayOfWeek: 4, Hours: model.weeklyHours?.thursday ?? 0.00 },
				{ DayOfWeek: 5, Hours: model.weeklyHours?.friday ?? 0.00 },
				{ DayOfWeek: 6, Hours: model.weeklyHours?.saturday ?? 0.00 },
			]
		};
	};

	const [model, setModel] = React.useState<IGradDateCalculator>(initialState);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};


	const validateModel = () => {
		if (!model.campusId) return "Campus ID is required.";
		if (!model.programVersionId) return "Program Version ID is required.";
		if (!model.scheduleId) return "Schedule ID is required.";
		if (!model.startDate) return "Student Start Date is required.";
		if (!model.totalProgramHours || model.totalProgramHours <= 0) return "Total Program Hours must be greater than zero.";

		return null;
	};

	const isModelValid = validateModel() === null;

	const gettotalProgramHours = () => {
		ProgramVersionApi.GetHoursInProgramByProgramVersionId(model.programVersionId).then(
			(response: any) => {
				if (response) {
					setModel(prevState => ({
						...prevState,
						totalProgramHours: response.data,
					}));
				} else {
					props.setSnackBar((props: any) => {
						return {
							variant: "error",
							showSnackBar: true,
							messageInfo: response.resultStatusMessage,
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBar((snackBarProps: any) => {
					return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					};
				});
			}
		);
	};

	const getSchedules = () => {
		if (model.scheduleId) {
			ProgramVersionApi.GetSchedules(model.programVersionId).then(
				(response: Array<IScheduleGridRow>) => {
					if (response && response.length > 0) {
						setSchedules(response);

						const selectedSchedule = response
							.find(sch => sch.scheduleId === model.scheduleId)
							?.programScheduleDetails?.slice()
							.sort((a, b) => a.dayOfWeek - b.dayOfWeek) || [];

						if (selectedSchedule) {
							setModel(prevState => ({
								...prevState,
								weeklyHours: {
									sunday: selectedSchedule[0]?.total ?? 0.00,
									monday: selectedSchedule[1]?.total ?? 0.00,
									tuesday: selectedSchedule[2]?.total ?? 0.00,
									wednesday: selectedSchedule[3]?.total ?? 0.00,
									thursday: selectedSchedule[4]?.total ?? 0.00,
									friday: selectedSchedule[5]?.total ?? 0.00,
									saturday: selectedSchedule[6]?.total ?? 0.00,
								}
							}));
						}
						else {
							setModel((prevState) => ({
								...prevState,
								scheduleId: "",
								weeklyHours: resetWeeklyHours(),
							}));
						}
					} else {
						setSchedules([]);
						setModel((prevState) => ({
							...prevState,
							scheduleId: "",
							weeklyHours: resetWeeklyHours(),
						}));
					}
				},
				(exception: any) => {
					console.error("Error fetching schedules", exception);
				}
			);
		}
	};

	React.useEffect(() => {
		if (model.programVersionId) {
			gettotalProgramHours();
		}
	}, [model.programVersionId]);

	React.useEffect(() => {
		if (model.scheduleId) {
			getSchedules();
		}
	}, [model.scheduleId]);

	const handleFieldOnChange = (fieldId: string, value: any) => {
		setModel((prevModel) => {
			let updatedModel = { ...prevModel };

			if (fieldId === "campusId") {
				updatedModel.campusId = value;
				updatedModel.programVersionId = "";
				updatedModel.scheduleId = "";
				updatedModel.totalProgramHours = 0;
				updatedModel.weeklyHours = resetWeeklyHours();
			} else if (fieldId === "programVersionId") {
				updatedModel.programVersionId = value;
				updatedModel.scheduleId = "";
				updatedModel.weeklyHours = resetWeeklyHours();
			} else if (fieldId === "scheduleId") {
				updatedModel.scheduleId = value;
				if (!value) {
					updatedModel.weeklyHours = resetWeeklyHours();
				}
			} else if (fieldId === "totalProgramHours") {
				updatedModel.totalProgramHours = parseFloat(parseFloat(value).toFixed(2)) || 0.0;
			} else if (fieldId.startsWith("weeklyHours.")) {
				const day = fieldId.split(".")[1];
				updatedModel.weeklyHours = {
					...prevModel.weeklyHours,
					[day]: parseFloat(value) || 0.00,
				};
			} else {
				(updatedModel as any)[fieldId] = value;
			}

			return updatedModel;
		});
	};

	const loadHolidays = () => {
		if (userSelectedCampus) {
			holidaysApi.getHolidayByCampus(userSelectedCampus).then(
				(response: any) => {
					if (response && response.data) {
						setHolidays(response.data);
					}
				},
				(exception: any) => { }
			);
		}
	}

	React.useEffect(() => {
		loadHolidays();
	}, [userSelectedCampus]);

	const onSubmit = async () => {
		const validationError = validateModel();
		if (validationError) {
			setSnackBarProps({
				variant: "error",
				showSnackBar: true,
				messageInfo: validationError
			});
			return;
		}
		try {
			setLoading(true);
			const backendModel = convertModelForBackend(model);
			const response = await CalculateGradeDateApi.CalculateContractedGraduationDate(backendModel);
			if (response) {
				const graduationDateString = new Date(response.result).toLocaleDateString();
				setModel(prevState => ({ ...prevState, graduationDate: graduationDateString }));
			} else {
				setModel(prevState => ({ ...prevState, graduationDate: "" }));
			}
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : "";
			setModel(prevState => ({ ...prevState, graduationDate: "" }));
			setSnackBarProps({
				variant: "error",
				showSnackBar: true,
				messageInfo: errorMessage
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={classes.root}>
			<FormContext {...data}>
				<form>
					<Card className={classes.card}>
						<CustomCardTitle title="Grad Date Calculator"></CustomCardTitle>
						<CardContent className={classes.cardContent}>
							<Grid container spacing={1}>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="Grad Date Calculator Tabs"
									indicatorColor="primary"
									textColor="primary"
									centered
								>
									<Tab label="Grad Date Calculation" style={{ textTransform: "none" }} />
									<Tab label="Holidays & Closed Days" style={{ textTransform: "none" }} />
								</Tabs>
								<Grid className={classes.calculationTab} container spacing={1} style={{ display: value === 0 ? "block" : "none" }}>
									<Grid item md={10} sm={10} xs={12}>
										<Typography variant="body1" style={{ fontWeight: "normal" }}>
											Enter the student's hours for each day the school is open, the student's start date and the total hours in the program. Verify that the "Holidays & Closed Days" are accurate, then click the "Calculate Graduation Date" button.
										</Typography>
									</Grid>
									<Grid item md={8} sm={8} xs={12}>
										<Grid container alignItems="center" spacing={1}>
											<Grid item xs={12} sm={4} className={classes.label}>
												<Typography>Campus</Typography>
											</Grid>
											<Grid item xs={12} sm={4} className={classes.dropdown}>
												<CampusForCurrentUserAutoComplete
													name="campusId"
													valueFilter={
														model.campusId
															? {
																key: "value",
																values: [model.campusId],
															}
															: undefined
													}
													filterHandle={(v: any) => {
														handleFieldOnChange(
															"campusId",
															v ? v.value : undefined
														);
													}}
													error={!!data.errors.campusId}
													helperText={
														data.errors.campusId && data.errors.campusId.message
													}
													inputRef={data.register({
														required: "Campus is required.",
													})}
												/>
											</Grid>

											{/* Force new line */}
											<Grid item xs={12} />

											<Grid item xs={12} sm={4} className={classes.label}>
												<Typography>Program Version</Typography>
											</Grid>
											<Grid item xs={12} sm={4} className={classes.dropdown}>
												<Grid style={{ width: "95%" }}>
													<ProgramVersionAutoComplete
														params={{
															fullWidth: true,
														}}
														id="programVerId"
														name="programVerId"
														label="Program Version *"
														valueFilter={
															model.programVersionId
																? {
																	key: "value",
																	values: [model.programVersionId],
																}
																: undefined
														}
														campusId={model.campusId}
														error={!!data.errors.programVerId}
														inputRef={data.register({ required: true })}
														helperText={
															data.errors.programVerId
																? "Program version is required."
																: undefined
														}
														filterHandle={(v: any) => {
															handleFieldOnChange(
																"programVersionId",
																v ? v.value : undefined
															);
														}}
														disabled={!model.campusId}
													/>
												</Grid>

											</Grid>

											{/* Force new line */}
											<Grid item xs={12} />

											<Grid item xs={12} sm={4} className={classes.label}>
												<Typography>Load from schedule</Typography>
											</Grid>
											<Grid item xs={12} sm={4} className={classes.dropdown}>
												<ScheduleProgramAutoComplete
													label="Schedule"
													programVersion={model?.programVersionId}
													params={{
														inActiveSchedule: false,
													}}
													multiple={false}
													valueFilter={
														model?.scheduleId
															? {
																key: "value",
																values: [model?.scheduleId],
															}
															: undefined
													}
													filterHandle={(v: any) => {
														handleFieldOnChange(
															"scheduleId",
															v ? v.value : undefined
														);
													}}
													name="newSchedule"
													id="newSchedule"
													key="newSchedule"
													disabled={!model.campusId || !model.programVersionId || model.programVersionId == null || model.programVersionId == ""}
												/>
											</Grid>

											{/* Force new line */}
											<Grid item xs={12} />

											<Grid item md={4} sm={6} xs={6}>
												<Typography>Include Holidays</Typography>
											</Grid>
											<Grid item md={4} sm={6} xs={6} className={classes.dropdown}>
												<FormControlLabel
													id="includeHolidays"
													className={classes.switch}
													control={<Switch
														checked={model.includeHolidays}
														name="includeHolidays"
														onChange={(e: any) => {
															handleFieldOnChange("includeHolidays", e.target.checked);
														}}
														color="primary" />} label={undefined} />
											</Grid>

											<Grid item xs={12} />

											<Grid item md={2} sm={2} xs={6} className={classes.label}>
												<Typography>Student Start Date</Typography>
											</Grid>

											<Grid item md={3} sm={3} xs={6}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														disableToolbar
														variant="inline"
														format="MM/dd/yyyy"
														id="startDate"
														name="startDate"
														label=""
														className={classes.width100}
														value={model.startDate ? model.startDate : null}
														onChange={(e: any, value: any) =>
															handleFieldOnChange('startDate', value ? value : undefined)
														}
														KeyboardButtonProps={{
															'aria-label': 'start date',
														}}
														error={!!data.errors["startDate"]}
														innerRef={(ref: any) => {
															data.register("startDate", {
																required: true
															});
															data.setValue("startDate", model.startDate);
														}}
														helperText={
															data.errors["startDate"]
																? (data.errors["startDate"] as FieldError).message
																: undefined
														}
														autoOk={true}
														required={true}
													/>
												</MuiPickersUtilsProvider>
											</Grid>

											<Grid item xs={2} />

											<Grid item md={2} sm={2} xs={6} className={classes.label}>
												<Typography>Total Prog. Hours</Typography>
											</Grid>
											<Grid item md={2} sm={2} xs={6}>
												<ThemeTextField
													id="totalProgramHours"
													key="totalProgramHours"
													name="totalProgramHours"
													label=""
													type="number"
													InputProps={{
														inputProps: { min: 0.00, step: "0.01" }
													}}
													error={!!data.errors.totalProgramHours}
													inputRef={data.register({ required: true })}
													helperText={
														data.errors.totalProgramHours ? 'Total hours is required' : undefined
													}
													value={model.totalProgramHours ?? 0.00}
													onChange={(e: any) => {
														handleFieldOnChange('totalProgramHours', e.target.value);
													}}
												/>
											</Grid>

											<Grid item xs={12} />

											<Grid item xs={1} />

											<Grid item md={6} sm={8} xs={12}>
												<Grid container spacing={2}>
													{["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (
														<React.Fragment key={day}>
															<Grid item xs={6} sm={3} md={3} className={classes.label}>
																<Typography>{day}</Typography>
															</Grid>
															<Grid item xs={6} sm={3} md={3}>
																<ThemeTextField
																	id={`${day.toLowerCase()}Hours`}
																	name={`${day.toLowerCase()}Hours`}
																	label=""
																	type="number"
																	InputProps={{
																		inputProps: { min: 0.00 }
																	}}
																	error={!!data.errors.weeklyHours?.[day.toLowerCase()]}
																	inputRef={data.register({ required: true })}
																	helperText={data.errors.weeklyHours?.[day.toLowerCase()] ? `${day} hours is required` : ""}
																	value={model.weeklyHours?.[day.toLowerCase()] ?? "0.00"}
																	onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																		handleFieldOnChange(`weeklyHours.${day.toLowerCase()}`, parseFloat(e.target.value) || 0.00);
																	}}
																/>
															</Grid>
														</React.Fragment>
													))}
												</Grid>
											</Grid>

											<Grid item xs={12} />

											<Grid item md={2} sm={2} xs={6} className={classes.label}>
												<Typography>Graduation Date:</Typography>
											</Grid>

											<Grid item md={2} sm={2} xs={6} className={classes.label}>
												<Typography>{model.graduationDate}</Typography>
											</Grid>

											<Grid item xs={12} />

											<Grid item md={6} sm={6} xs={8} className={classes.label}>
												<ProgressSaveButton
													text="Calculate Graduation Date"
													onClick={async () => {
														await onSubmit();
													}}
													loading={loading}
													startIcon={<IsoOutlinedIcon />}
													disabled={!isModelValid}
												></ProgressSaveButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container style={{ display: value !== 0 ? "block" : "none" }}>
									<Grid item xs={12}>
										<Typography variant="h6" style={{ fontWeight: "bold" }}>Holidays & Closed Days</Typography>
										<Box className={classes.holidayListContainer}>
											{holidays.length > 0 ? (
												holidays.map((holiday, index) => {
													const start = new Date(holiday.startDate ?? new Date());
													const end = new Date(holiday.endDate ?? new Date());
													const startDateStr = start.toLocaleDateString();
													const endDateStr = end.toLocaleDateString();
													const dateText = startDateStr === endDateStr ? startDateStr : `${startDateStr} - ${endDateStr}`;

													return (
														<Box key={index} className={classes.holidayItem}>
															<Typography variant="body1" style={{ fontWeight: "bold" }}>
																{holiday.description}
															</Typography>
															<Typography variant="body2" color="textSecondary">
																{dateText}
															</Typography>
														</Box>
													);
												})
											) : (
												<Typography variant="body1">No holidays found.</Typography>
											)}
										</Box>
									</Grid>
								</Grid>

							</Grid>
						</CardContent>
					</Card>
				</form>
			</FormContext>
		</div>
	);
};

export default GradDateCalculator;