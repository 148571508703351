import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropDownListItem } from '../../../interfaces/DropDownListItem';
import TextField from '@material-ui/core/TextField';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import { Grid  , FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel, Switch,} from '@material-ui/core';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import { ISAPRecheckedReportParams } from "../../../interfaces/reports/studentAccounts/ISAPRecheckedReportParams";
import * as SAPRecheckedReportApi from '../../../api/reports/studentAccounts/SAPRecheckedReportApi';
import { ReportOutput } from '../../../enums/ReportOutput';
import ReportOverview from '../../../components/_Layout/Reports/ReportOverview';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';


const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			maxWidth: '1050px',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		startDateField: {
			width: '100%',
		},
	})
);

const SAPRecheckedReport = (props: any) => {
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState({
		campusIds: [],
		asOf: undefined as Date | undefined,
		FASAPChk :true,
		exportType: 1 as ReportOutput,
		
	});


	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: (values: any, key: string) => Array<string>
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value, 'value') : value;
		(updatedModel as any)[fieldId] = newValue;
		
		setModel({ ...updatedModel });
		
	};




	const setCampuses = (values: any, key: string) => {
		let newValues: string[] = [];
	
		if (values !== null) {
			if (Array.isArray(values)) {
				newValues = values.map((gr: any) => gr[key]);
			} else {
				newValues.push(values.value);
			}
		}
		return newValues;
		
	};
	const { handleSubmit } = data;

	const exportTypes: Array<DropDownListItem> = [
		{ text: 'PDF', id: '1' },
		{ text: 'Excel', id: '2' },
		{ text: 'Word', id: '3' },
	];

	const onSubmit = async (isPreview: any) => {
		let params = (await getReportParameters()) as ISAPRecheckedReportParams;
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				setPreviewLoader(true);
				SAPRecheckedReportApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await SAPRecheckedReportApi.generateReport(params);
		}
	};

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
		} else {
			setModel({
				campusIds: [],
				asOf: undefined as Date | undefined,
				FASAPChk :true,
				exportType: 1 as ReportOutput,
		
			});
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			
			let params: ISAPRecheckedReportParams = {
				campusIds: model.campusIds,
				asOf: model.asOf,
				FASAPChk : model.FASAPChk,
				exportType: model.exportType,
				
			};
			return params;
		}
		return {} as ISAPRecheckedReportParams;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id === value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
					<Grid item md={9} sm={9} xs={12}>
							<CampusForCurrentUserAutoComplete
								id="campusIds"
								name="campusIds"
								label="Campus(es) *"
								multiple
								includeAllValue={true}
								showIncludeAllValue={true}
								filterHandle={(v: any) => {
									handleFieldOnChange('campusIds', v, setCampuses);
								}}
								valueFilter={
									model.campusIds
										? {
											key: 'value',
											values: model.campusIds,
										}
										: undefined
								}
								error={!!data.errors.campusIds}
								inputRef={data.register({
									validate: (_value) =>
										model.campusIds && model.campusIds.length > 0
											? true
											: false,
								})}
								helperText={
									data.errors.campusIds ? 'Campus(es) are required.' : undefined
								}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								disableClearable
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										'exportType',
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(
									exportTypes,
									model.exportType.toString()
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? 'Export Type is required.'
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="asOf"
									name="asOf"
									label="As Of *"
									value={model.asOf ? model.asOf : null}
									onChange={(e: any, value: any) =>
										handleFieldOnChange('asOf', value ? value : undefined)
									}
									className={classes.startDateField}
									KeyboardButtonProps={{
										'aria-label': 'report date',
									}}
									error={!!data.errors["asOf"]}
													  innerRef={(ref: any) => {
														data.register("asOf", {
														  required: true,
														});
														data.setValue("asOf", model.asOf);
													  }}
													  helperText={
														data.errors["asOf"]
														  ? 'As of date is required.'
														  : undefined
													  }
								/>
							</MuiPickersUtilsProvider>
						</Grid>
							{/* <Grid item md={4} sm={4} xs={4}>
									  <FormControl>
									<FormControlLabel
										  label="Show FA SAP"
										  control={
											<Switch
											  checked={model.FASAPChk}
											  value="FASAPChk"
											  onChange={(e: any) => {
												setModel({...model,FASAPChk:e.target.checked})
											  }}
											  color="primary"
											/>
										  }
										/>
										</FormControl>
									</Grid>  */}
					</Grid>
				</form>
			</FormContext>
		</div>
	);
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};
export default SAPRecheckedReport;
