import { Fragment, useState } from "react";
import {
    makeStyles,
    createStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button,
} from "@material-ui/core";
import React from "react";
import { ThemeTextField } from "../../../../components/_Layout/Inputs/ThemeTextField";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { CreateNewGuid } from "../../../../utils/constants";
import { ChargeDefinition } from "../../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import { ChargeDefinitionPeriods, ChargeDefinitionVersion } from "../../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import { cloneDeep } from "lodash";
import * as chargeDefinitionApi from "../../../../api/setup/chargeDefinition/chargeDefinitionApi";
import IncrementTypeAutoComplete from "../../../../components/AutoComplete/IncrementTypeAutoComplete";
import { useFormContext } from "react-hook-form";
import IncrementDetailsGrid from "../../../setup/chargeDefinition/IncrementDetailsGrid";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            "& .MuiPaper-rounded": {
                borderRadius: 0,
            },
            "& .MuiDialogContent-root": {
                minHeight: 250,
            },
        },
        dialogHeader: {
            backgroundColor: theme.palette.site.secondary,
            "& .MuiTypography-root": {
                fontWeight: theme.typography.fontWeightBold,
                fontSize: "12pt",
            },
        },
        buttonSection: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        saveButton: {
            width: 360,
        },
        fullWidthField: {
            width: "100%",
        }
    })
);

type StudentChargeDefinitionPopupProps = {
    open: boolean;
    campusId:string;
    stuEnrollId?:string;
    setOpen: (open: boolean) => void;
    setSnackBar: (snackBarProps: any) => void;
    chargeDefinition?: ChargeDefinition;
    setChargeDefinition: (model: ChargeDefinition) => void;
    isNewEnrollment: boolean;
    isEditable: boolean;
};
const StudentChargeDefinitionPopup = (props: StudentChargeDefinitionPopupProps) => {
    const classes = useStyles({});
    const data = useFormContext<ChargeDefinition>();

    const [areRowsValid, setAreRowsValid] = useState<boolean>(false);

    const initialChargeDefinitionVersion: ChargeDefinitionVersion = {
        asOfDateType: 'EnrollmentDate',
        chargeDefinitionId: null,
        chargeDefinitionVersionId: null,
        description: 'Enrollment Specific Charge Definition Version',
        effectiveDate: new Date(),
        excAbsencesPercent: 0,
        periods: [],
        totalChargeAmount: 0,
    };

    const initialModel: ChargeDefinition = {
        campusId: props.campusId,
        chargeDefinitionDescription: '',
        chargeDefinitionId: null,
        chargeDefinitionProgramVersions: [],
        chargeDefinitionVersions: [initialChargeDefinitionVersion],
        incrementTypeId: null,
        periodTypeId: null,
        statusId: null,
    };

    const [model, setModel] = useState<ChargeDefinition>(props.chargeDefinition ?? initialModel);

    const checkAllRowsAreValid = () => {
        let isValid = false;

        if (!!model && !!model.chargeDefinitionVersions && model.chargeDefinitionVersions.length > 0) {
            const currentVersion = model.chargeDefinitionVersions[0];

            if (!!currentVersion && !!currentVersion.periods && currentVersion.periods.length > 0) {
                const periods = currentVersion.periods;
                let invalidRowsArray: ChargeDefinitionPeriods[];
                invalidRowsArray = periods.filter(
                    (row) =>
                        row.isEditModeOn === true
                );

                isValid = invalidRowsArray.length === 0;
            }
        }

        return isValid;
    };

    React.useEffect(() => {
        if(props.open){
            setModel(props.chargeDefinition ?? initialModel);
        }
    },[props.chargeDefinition, props.open]);

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = cloneDeep(model);
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        setModel(updatedModel);
    };

    const handleAddNewperiod = () => {
        if (!!model) {
            let modelToUpdate = cloneDeep(model);

            if (!modelToUpdate.chargeDefinitionVersions || modelToUpdate.chargeDefinitionVersions.length <= 0) {
                modelToUpdate.chargeDefinitionVersions = [{...cloneDeep(initialChargeDefinitionVersion), chargeDefinitionId:modelToUpdate.chargeDefinitionId}];
            }

            let version = modelToUpdate.chargeDefinitionVersions[0];
            let periods = version.periods;
            periods.unshift({
                incrementValue: 0,
                chargeAmount: 0,
                chargeDefinitionVersionId: version.chargeDefinitionVersionId,
                hours: 0,
                incrementId: CreateNewGuid(),
                isEditModeOn: true,
                options: '',
                periodTypeId: null,
                scheduledHours: 0,
                transactionCode: '',
                transCodeId: '',
            });

            setModel(modelToUpdate);
        }
    }

    React.useEffect(() => {
        setAreRowsValid(checkAllRowsAreValid());
    }, [model]);

    const onSubmit = (e: any) => {
        data.triggerValidation().then(result => {
            if(result){
                let modelToSend = cloneDeep(model);

                if (!props.isNewEnrollment && !!props.stuEnrollId) {
                    chargeDefinitionApi.UpsertStudentChargeDefinition(props.stuEnrollId, modelToSend).then(
                        (response: any) => {
                            if (response.resultStatus === 0) {
                                props.setChargeDefinition(response.result);
        
                                props.setSnackBar((props: any) => {
                                    return {
                                        variant: "success",
                                        showSnackBar: true,
                                        messageInfo: response.resultStatusMessage,
                                    };
                                });
                                props.setOpen(false);
                            } else {
                                props.setSnackBar((props: any) => {
                                    return {
                                        variant: "error",
                                        showSnackBar: true,
                                        messageInfo: response.resultStatusMessage,
                                    };
                                });
                            }
                        },
                        (exception: any) => {
                            props.setSnackBar((snackBarProps: any) => {
                                return {
                                    variant: "error",
                                    showSnackBar: true,
                                    messageInfo: exception,
                                };
                            });
                        }
                    );
                }else{
                    props.setChargeDefinition(modelToSend);
                    props.setOpen(false);
                }
            }
        });
    };
    const handleClose = () => {
        props.setOpen(false);
    };

    const setDetails = (periods: ChargeDefinitionPeriods[]) => {
        let modelToUpdate = cloneDeep(model);
        let versions = modelToUpdate.chargeDefinitionVersions;

        if (!!versions && versions.length > 0) {
            let version = versions[0];
            version.periods = periods;
        }

        setModel(modelToUpdate);
    }

    return (
        <Fragment>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
                disableBackdropClick
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
                    {"Customize Automatic Charge Definition"}
                </DialogTitle>
                <DialogContent>
                    {model !== undefined && (
                        <Grid container direction="row" spacing={2}>
                            <Grid item md={6} sm={6} xs={12}>
                                <IncrementTypeAutoComplete
                                    id="incrementTypeId"
                                    name="incrementTypeId"
                                    label="Increment Type *"
                                    valueFilter={
                                        model.incrementTypeId
                                            ? {
                                                key: "value",
                                                values: [model.incrementTypeId],
                                            }
                                            : undefined
                                    }
                                    error={!!data.errors.incrementTypeId}
                                    inputRef={data.register({
                                        required: true,
                                    })}
                                    helperText={
                                        data.errors.incrementTypeId
                                            ? "Increment type is required"
                                            : null
                                    }
                                    filterHandle={(v: any) => {
                                        handleFieldOnChange("incrementTypeId", v ? v.value : undefined);
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <ThemeTextField
                                    id="chargeDefinitionDescription"
                                    label="Description"
                                    name="chargeDefinitionDescription"
                                    className={classes.fullWidthField}
                                    onChange={(e: any) => {
                                        handleFieldOnChange("chargeDefinitionDescription", e ? e.target.value : undefined);
                                    }}
                                    value={model?.chargeDefinitionDescription ?? ""}
                                    error={!!data.errors.chargeDefinitionDescription}
                                    inputRef={data.register({
                                        required: true,
                                    })}
                                    helperText={
                                        data.errors.chargeDefinitionDescription
                                            ? "Description is required"
                                            : null
                                    }
                                />
                            </Grid>

                            {props.isEditable && <Grid item container direction="row" spacing={4} xs={12}>
                                <Grid item xs={4}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="button"
                                        onClick={handleAddNewperiod}
                                    >
                                        ADD INCREMENT
                                    </Button>
                                </Grid>
                            </Grid>}
                            <IncrementDetailsGrid
                                details={!!model && !!model.chargeDefinitionVersions && model.chargeDefinitionVersions.length > 0 && !!model.chargeDefinitionVersions[0].periods && model.chargeDefinitionVersions[0].periods.length > 0 ? cloneDeep(model.chargeDefinitionVersions[0].periods) : []}
                                isEditable={props.isEditable}
                                setModel={setDetails}
                            />
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions className={classes.buttonSection}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={2}></Grid>
                        <Grid
                            item
                            container
                            xs={4}
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <ProgressSaveButton
                                text="SAVE DEFINITION FOR ENROLLMENT"
                                onClick={(e: any) => {
                                    onSubmit(e);
                                }}
                                loading={false}
                                disabled={!areRowsValid}
                                buttonClassName={classes.saveButton}
                            ></ProgressSaveButton>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={4}
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                variant="contained"
                                type="button"
                            >
                                CANCEL
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default StudentChargeDefinitionPopup;
