import * as React from "react";
import { useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    fullWidth: { width: "100%", }
  })
);

const CampusForCurrentUserAutoComplete = (props: FilterParams) => {

  const classes = useStyles({});
  const inputRef: any = useRef(null);

  const CampusOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  const excludeIdsString = Array.isArray(props.campusExists)
  ? props.campusExists.join(",")
  : "";

  return (
    <div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>
      <ApiAutoComplete
        //config={apiAutoCompleteConfig.campus}
        config={apiAutoCompleteConfig.campusExcluded}
        reqParams={{
          excludeIds: excludeIdsString, 
        }}
  

        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Campus"}
        placeholder={
          (props.valueFilter &&
            props.valueFilter.values &&
            props.valueFilter.values.length > 0)
            || props.hidePlaceholder
            ? ""
            : "Select a campus"
        }
        onChange={(e: any) => {
          CampusOnChange(e);
        }}
        disabled={props.disabled}
        includeAllValue={props.includeAllValue ? props.includeAllValue : false}
        showIncludeAllValue={props.showIncludeAllValue ? props.showIncludeAllValue : false}
        multiple={props.multiple ? props.multiple : false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        maxTags={props.maxTags}
        chipSize={props.chipSize || 'medium'}
        // configOverride={props.configOverride}
        
      ></ApiAutoComplete>
    </div>
  );
};
export default CampusForCurrentUserAutoComplete;
