import * as api from '../api/reports/progressReport/reportApi';
import IReportConfig from '../interfaces/reports/IReportConfig';
import { ReportUrl } from '../constants/report.url';

const progressReportActions = {
	getProgressReport,
	getTranscriptReport,
	getOfficialTranscriptReport,
	getLedger,
	getTitleIVSAPNoticeReportConfig,
	getAttendanceHistoryReport,
	getAidRecievedReport,
	getDisbursementNoticeReport,
	getIllinoisTranscriptReport,
};

function getIllinoisTranscriptReport(studentEnrollmentId: string, campusId: string) {
	let params = {
		campusId: campusId,
		stuEnrollId: studentEnrollmentId,
		showSignature: true,
	};
	return api.getIllinoisTranscriptReport(params, 'IllinoisTranscriptReport.pdf');
}

function getProgressReport(studentEnrollmentId: string, showOnlySummary?: boolean, fileName?: string) {
	let params = {
		studentEnrollmentId: studentEnrollmentId,
		showWorkUnitGrouping: showOnlySummary,
	};
	return api.getProgressReport(params, fileName);
}
function getTranscriptReport(sIdList: string, campusId?: string) {
	let params = {
		campusId: campusId,
		stuEnrollIdList: sIdList,
		showCourseComponents: true,
		coursesLayout: 'ShowCoursesOnly',
	};
	return api.getTranscriptReport(params, 'UnofficialTranscript.pdf');
}



function getOfficialTranscriptReport(sIdList: string, campusId?: string) {
	let params = {
		campusId: campusId,
		stuEnrollIdList: sIdList,
		isOfficialTranscript: true,
		showCourseComponents: true,
		coursesLayout: 'ShowCoursesOnly',
	};
	return api.getTranscriptReport(params, 'OfficialTranscript.pdf');
}
function getLedger(stuEnrollId: string, isVoided?: boolean) {
	let params = {
		stuEnrollId: stuEnrollId,
		isVoided: isVoided,
	};
	return api.getLedgerReport(params, 'LedgerReport.pdf');
}

function getTitleIVSAPNoticeReportConfig(stuEnrollId: string, fileName: string = 'TitleIVSAPNotice.pdf'): IReportConfig {
	return {
		RequestUrl: ReportUrl.TitleIVSAPNotice,
		RequestBody: stuEnrollId,
		FileName: fileName
	};
}

function getAttendanceHistoryReport(stuEnrollId: string[]) {
	return api.getAttendanceHistoryReport(
		stuEnrollId,
		'AttendanceHistoryReport.pdf'
	);
}
function getAidRecievedReport(stuEnrollId: string[]) {
	return api.getAidRecievedReport(stuEnrollId, 'AidRecievedReport.pdf');
}
function getDisbursementNoticeReport(stuEnrollId: string[]) {
	return api.getDisbursementNoticeReport(stuEnrollId, 'DisbursementNotice.pdf');
}
export default progressReportActions;
