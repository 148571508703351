import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import progressReportActions from '../../../../actions/reportActions';
import { useSelector } from 'react-redux';
import { StudentProfile } from '../../../../interfaces/student/profile/StudentProfile';
import { toasterActions } from '../../../../actions/toasterActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GenerateReport from '../../../../components/Common/GenerateReport/GenerateReport';
import ReportMethod from '../../../../constants/reportMethod';
import AccessManager from '../../../../components/security/AccessManager';
import { security } from '../../../../constants/Security/securityConstants';
import { system } from '../../../../constants/system/systemConstants';
import { generateStudentAttendanceReport } from '../../../../api/reports/attendance/attendanceSummaryApi';

const BreadCrumbPrintMenu = (props: any) => {
	let currentDateTime = new Date();
	let dated = `${currentDateTime.getDay()}-${currentDateTime.getMonth() + 1}-${currentDateTime.getFullYear()}-${currentDateTime.getHours()}-${currentDateTime.getMinutes()}`;
	const [open, setOpen] = React.useState<any>();
	const student = useSelector((state: any) => state.student) as StudentProfile;

	React.useEffect(() => {
		setOpen(props.menuProps);
	}, [props.menuProps]);

	const handleClose = () => {
		setOpen({ isOpen: false });
	};

	const appSettings = useSelector((state: any) => {
		return state.settings.settings;
	});
	const isIllinoisEnabled = (appSettings[system.settings.enableIllinoisTranscript] as string || 'no').toLowerCase() === 'yes';
	const isCreditHourEnabled = (appSettings[system.settings.enableClockHourCreditHour] as string || 'false').toLowerCase() === 'true';

	return (
		<Menu
			id="breadcrumb-menu"
			anchorEl={props.anchorEl}
			open={open && open.isOpen}
			keepMounted
			onClose={handleClose}
			TransitionComponent={Fade}
		>
			<AccessManager
				allowedPermissions={[security.permissions.academics.progressReport]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getProgressReport(student.selectedEnrollment.stuEnrollId, false, 'ProgressReportSummary.pdf')
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Progress Report Summary
				</MenuItem>
			</AccessManager>
			<AccessManager
				allowedPermissions={[security.permissions.academics.progressReport]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getProgressReport(student.selectedEnrollment.stuEnrollId, true, 'ProgressReportDetail.pdf')
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Progress Report Detail
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.academics.transcript]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getTranscriptReport(
									student.selectedEnrollment.stuEnrollId,
									student.selectedEnrollment.campusId
								)
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Unofficial Transcript
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.academics.transcript]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getOfficialTranscriptReport(
									student.selectedEnrollment.stuEnrollId,
									student.selectedEnrollment.campusId
								)
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Official Transcript
				</MenuItem>
			</AccessManager>
			{isIllinoisEnabled && <AccessManager
				allowedPermissions={[security.permissions.academics.IllinoisTranscriptReport]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getIllinoisTranscriptReport(
									student.selectedEnrollment.stuEnrollId,
									student.selectedEnrollment.campusId
								)
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Illinois Transcript
				</MenuItem>
			</AccessManager>}

			<AccessManager
				allowedPermissions={[security.permissions.student.viewLedger, security.permissions.student.updateLedger]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getLedger(student.selectedEnrollment.stuEnrollId, false)
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Student Ledger
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.student.viewLedger, security.permissions.reports.printTitleIVSAPNotices]}
				renderNoAccess={() => null}
			>
				<MenuItem>
					<GenerateReport
						reportConfig={progressReportActions.getTitleIVSAPNoticeReportConfig(student?.selectedEnrollment?.stuEnrollId ?? "", `${student.studentName}-${dated}.pdf`)}
						reportMethod={ReportMethod.Download}
						success={() => {
							props.setBreadCrumbBarLoader(false);
						}}
						error={() => {
							props.setBreadCrumbBarLoader(false);
						}}
						clicked={() => {
							props.setBreadCrumbBarLoader(true);
							handleClose();
						}}
						invalidCriteria={() => {
							props.setBreadCrumbBarLoader(false);
						}}
					>
                        Title IV SAP Notice
					</GenerateReport>
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.student.viewMonthlyAttendance, security.permissions.student.updateMonthlyAttendance, security.permissions.attendance.editScheduledHours, security.permissions.attendance.editPunches, security.permissions.attendance.postAttendance]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getAttendanceHistoryReport([
									student.selectedEnrollment.stuEnrollId,
								])
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Attendance History
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.student.viewAwards, security.permissions.student.updateAwards, security.permissions.student.deleteAwards]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getAidRecievedReport([student.selectedEnrollment.stuEnrollId])
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Aid Received
				</MenuItem>
			</AccessManager>

			<AccessManager
				allowedPermissions={[security.permissions.student.viewAwards, security.permissions.student.updateAwards, security.permissions.student.deleteAwards]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							progressReportActions
								.getDisbursementNoticeReport([
									student.selectedEnrollment.stuEnrollId,
								])
								.then(
									(_response: any) => {
										props.setBreadCrumbBarLoader(false);
									},
									(_exception: any) => {
										props.setBreadCrumbBarLoader(false);
									}
								);
						}
						handleClose();
					}}
				>
                    Disbursement Notice
				</MenuItem>
			</AccessManager>

			{isCreditHourEnabled  && !!student.selectedEnrollment && student.selectedEnrollment.isCreditHour == true && <AccessManager
				allowedPermissions={[security.permissions.attendance.ViewAttendanceReport]}
				renderNoAccess={() => null}
			>
				<MenuItem
					onClick={function (_e: any) {
						if (student.selectedEnrollment?.stuEnrollId) {
							props.setBreadCrumbBarLoader(true);
							generateStudentAttendanceReport(student.selectedEnrollment.stuEnrollId)
							.then(
								(_response: any) => {
									props.setBreadCrumbBarLoader(false);
								},
								(_exception: any) => {
									props.setBreadCrumbBarLoader(false);
								}
							);	
						}

						handleClose();
					}}
				>
                    Student Attendance
				</MenuItem>
			</AccessManager>}

		</Menu>
	);
};
BreadCrumbPrintMenu.prototype = {
	showToaster: PropTypes.func
};

let actionCreators = {
	showToaster: toasterActions.showToaster
};

export default connect(null, actionCreators)(BreadCrumbPrintMenu);
