import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Switch,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Tooltip,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useSelector } from "react-redux";
import { useFormContext, Controller, FieldError } from "react-hook-form";

import AttendanceStatusAutoComplete from "../../../../components/AutoComplete/AttendanceStatusAutoComplete";
import EnrollmentStatusAutoComplete from "../../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import DistanceEducationStatusAutoComplete from "../../../../components/AutoComplete/DistanceEducationStatusAutoComplete";
import DegCertSeekingAutoComplete from "../../../../../src/components/AutoComplete/DegCertSeekingAutoComplete";
import AdminCriteriaAutoComplete from "../../../../../src/components/AutoComplete/AdminCriteriaAutoComplete";
import FinAidAdvisorAutoComplete from "../../../../../src/components/AutoComplete/FinAidAdvisorAutoComplete";
import AcademicAdvisorAutoComplete from "../../../../../src/components/AutoComplete/AcademicAdvisorAutoComplete";
import IProgramVersionSAPPolicyInfo from "../../../../interfaces/academicRecords/IProgramVersionSAPPolicyInfo";
import InstructorAutoComplete from "../../../../../src/components/AutoComplete/InstructorAutoComplete";

import { EnrollmentGeneral } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { getProgramVersionSAPDetailsPolicies } from "../../../../api/academicRecords/programVersionApi";
import { getContractedGradDate } from "../../../../api/Common/gradDateCalculator";
import * as KlassAppApi from "../../../../api/Common/KlassAppApi";
import AdvantageViewer from "../../../../components/Common/AdvantageViewer";
import { Campus } from "../../../../interfaces/systemCatalog/ICampus";
import getAdvantageLink from "../../../../utils/getAdvantageLink";
import SchoolIcon from "@material-ui/icons/School";
import { ThemeTextField } from "../../../../components/_Layout/Inputs/ThemeTextField";
import EducationCompletedAutoComplete from "../../../../../src/components/AutoComplete/EducationCompletedAutoComplete";
import { DropDownListNum } from "../../../../interfaces/DropDownListNum";
import { security } from "../../../../constants/Security/securityConstants";
import { checkPermissions } from "../../../../utils/securityFunctions";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import * as appSettingsApi from "../../../../api/setup/appSettings/appSettingsApi";
import * as quickInfoApi from "../../../../api/student/quickInfo/quickInfoApi";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      // marginRight: theme.spacing(1),
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    fullWidthFieldWithIcon: {
      width: "90%",
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    appBar: {
      position: "relative",
    },
    appBarTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: theme.palette.white,
    },
    iconWidth: {
      width: "10%",
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
  })
);
const getFormattedDate = (date: Date) => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};
const firstTimeInSchool: Array<DropDownListNum> = [
  { text: "Yes", id: 1 },
  { text: "No", id: 0 },
];
const firstTimeInSchoolAutoComplete = {
  options: firstTimeInSchool,
  getOptionLabel: (option: DropDownListNum) => option.text,
};
const firstTimePostSecSchool: Array<DropDownListNum> = [
  { text: "Yes", id: 1 },
  { text: "No", id: 0 },
];
const firstTimePostSecSchoolAutoComplete = {
  options: firstTimePostSecSchool,
  getOptionLabel: (option: DropDownListNum) => option.text,
};
// const [updateInstructorPermission, setUpdateInstructorPermission] = React.useState(
//     checkPermissions([security.permissions.maintenance.EditInstructorField])
//   );
type GeneralProps = {
  general: EnrollmentGeneral;
  setGeneral: any;
  isNewEnrollment: boolean;
  programVersionSAPPolicyInfo?: IProgramVersionSAPPolicyInfo;
  loadOnlyFutureStartStatus?: boolean;
  showMandatory?: boolean;
  isNewStudentWorkflow?: boolean;
  isDroppedDueToCovid19?: boolean;
  setHighSchoolMandatory?: any;
  handleDateChange: any;
};

/*
The Enrollment General Info Component
 */
const GeneralInfo = (props: GeneralProps) => {
  const classes = useStyles({});
  const data = useFormContext();

  const { general, setGeneral } = props;

  const gradDateCalculatorLink = getAdvantageLink("/tools/gradDateCalculator");

  const campusId = useSelector(
    (state: any) => state.student.enrollments[0].campusId
  );
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const [usesKlassApp, setUsesKlassApp] = React.useState<boolean>(false);

  const GetKlassAppConfigSettings = () => {
    KlassAppApi.GetKlassAppConfigSettings(campusId).then(
      (response: any) => {
        setUsesKlassApp(response);
      },
      (exception: any) => {
        setUsesKlassApp(false);
      }
    );
  };

  const [programVersionSAPPolicyInfo, setProgramVersionSAPPolicyInfo] =
    React.useState<IProgramVersionSAPPolicyInfo | undefined>(undefined);

  const [showGradDateCalculator, setShowGradDateCalculator] =
    React.useState<boolean>(false);

  const updateSAPPolicy = (enrollmentId: any) => {
    getProgramVersionSAPDetailsPolicies(undefined, enrollmentId)
      .then((promisedData: any) => {
        setProgramVersionSAPPolicyInfo(promisedData);
      })
      .catch((exception: any) => {
        console.log(exception);
      });
  };

  if (
    programVersionSAPPolicyInfo == undefined &&
    !props.isNewEnrollment &&
    selectedEnrollment &&
    selectedEnrollment.stuEnrollId
  ) {
    updateSAPPolicy(selectedEnrollment.stuEnrollId);
  }

  React.useEffect(() => {
    if (
      (!props.isNewEnrollment && selectedEnrollment) ||
      (selectedEnrollment && selectedEnrollment.stuEnrollId)
    ) {
      updateSAPPolicy(selectedEnrollment.stuEnrollId);
      GetKlassAppConfigSettings();
      /* Get Schedule Id from enrollment */
    }
  }, [selectedEnrollment, props.isNewEnrollment]);

  React.useEffect(() => {
    if (props.isNewEnrollment) {
      setProgramVersionSAPPolicyInfo(props.programVersionSAPPolicyInfo);
      setUsesKlassApp(false);
    }
  }, [props.programVersionSAPPolicyInfo]);

  const handleEnrollmentIdChange = (e: any) => {
    setGeneral((state: EnrollmentGeneral | null) => {
      return { ...state, enrollmentId: e.target.value };
    });
  };
  const setEnrollmentStatus = (data: any) => {
    if (data) {
      let enrollmentStatus = data.value;
      setGeneral((state: EnrollmentGeneral | null) => {
        return { ...state, statusCodeId: enrollmentStatus };
      });
    } else {
      setGeneral((state: EnrollmentGeneral | null) => {
        return { ...state, statusCodeId: undefined };
      });
    }
  };
  const setAttendanceStatus = (data: any) => {
    let modelToUpdate = general;
    general.attendanceTypeId = data;
    setGeneral({ ...modelToUpdate });
  };
  const setDegCertSeeking = (data: any) => {
    let modelToUpdate = general;
    general.degcertseekingid = data;
    setGeneral({ ...modelToUpdate });
  };
  const setAcaDemicAdv = (data: any) => {
    let modelToUpdate = general;
    general.academicAdvisor = data;
    setGeneral({ ...modelToUpdate });
  };
  const setFinAidAdv = (data: any) => {
    let modelToUpdate = general;
    general.faadvisorId = data;
    setGeneral({ ...modelToUpdate });
  };

  const setInstructor = (data: any) => {
    let modelToUpdate = general;
    general.instructorId = data;
    setGeneral({ ...modelToUpdate });
  };
  const setAdminCriteria = (data: any, text: string) => {
    let modelToUpdate = general;
    general.adminCriteriaId = data;
    if (props?.setHighSchoolMandatory) {
      if (text == "GED") {
        props.setHighSchoolMandatory(false);
      } else {
        props.setHighSchoolMandatory(true);
      }
    }
    setGeneral({ ...modelToUpdate });
  };

  const handleDistanceEdStatusChange = (data: any) => {
    let modelToUpdate = general;
    general.distanceEducationStatusId = data;
    setGeneral({ ...modelToUpdate });
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let modelToUpdate = general;

    (general as any)[fieldId] = value;
    setGeneral({ ...modelToUpdate });

    if (fieldId == "isFirstTimePostSecSchool" && value?.id === 1) {
      handleFieldOnChange("isFirstTimeInSchool", value);
    }

    if (["educationCompletedId"].includes(fieldId)) {
      data.triggerValidation(fieldId);
    }
  };

  const handleCloseGradDateCalculator = () => {
    setShowGradDateCalculator(false);
  };
  const CalculateGraduationDate = () => {
    if (props.general.scheduleId !== undefined) {
      getContractedGradDate(
        props.general.scheduleId,
        new Date(general.startDate).toLocaleDateString(),
        campusId
      )
        .then((response: any) => {
          if (response.resultStatus === 0) {
            handleFieldChange("graduationDate", response.result);
            data.setValue(
              "graduationDate",
              response.result ? new Date(response.result) : null
            );
            handleFieldChange("revisedGraduationDate", response.result);
            data.setValue(
              "revisedGraduationDate",
              response.result ? new Date(response.result) : null
            );
            handleFieldChange("updatedGraduationDate", response.result);
            data.setValue(
              "updatedGraduationDate",
              response.result ? new Date(response.result) : null
            );

            data.triggerValidation([
              "startDate",
              "revisedGraduationDate",
              "graduationDate",
              "enrollmentDate",
            ]);
          }
        })
        .catch((exception: any) => {});
    }
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    if (fieldId === "enrollmentDate" || fieldId === "startDate") {
      value = new Date(value).toLocaleDateString();
    }
    let updatedModel = general;
    (updatedModel as any)[fieldId] = value;
    props.setGeneral({ ...updatedModel });

    if (props.isNewStudentWorkflow && fieldId === "startDate") {
      data.setValue("startDate", value ? new Date(value) : null);
      CalculateGraduationDate();
    }
    if (
      fieldId === "enrollmentDate" &&
      general.graduationDate != null &&
      general.revisedGraduationDate != null &&
      general.updatedGraduationDate != null
    ) {
      data.triggerValidation(fieldId);
    } else if (
      fieldId === "startDate" &&
      general.graduationDate != null &&
      general.revisedGraduationDate != null &&
      general.updatedGraduationDate != null
    ) {
      data.triggerValidation(fieldId);
    } else if (fieldId != "startDate" && fieldId != "enrollmentDate") {
      data.triggerValidation(fieldId);
    }
    if (fieldId === "enrollmentDate" || fieldId === "startDate") {
      props.handleDateChange({ ...updatedModel });
    }
  };
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  const onSendKlassApp = () => {
    KlassAppApi.PushStudentsUpdatesToKlassApp(
      selectedEnrollment.stuEnrollId
    ).then(
      (response: any) => {
        if (response == true) {
          setSnackBarProps({
            variant: "success",
            showSnackBar: true,
            messageInfo: "Students Updates To Klass App successfully.",
          });
        }
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleRebuildAcademics = () => {
    if (selectedEnrollment && selectedEnrollment.stuEnrollId)
      console.log(selectedEnrollment.stuEnrollId);
    quickInfoApi
      .GetRebuildAcademics(selectedEnrollment.stuEnrollId)
      .then((response) => {});
  };

  return (
    <div>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          &nbsp; General
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="enrollmentId"
                name="enrollmentId"
                label="Enrollment ID"
                className={classes.fullWidthField}
                value={general?.enrollmentId}
                onChange={handleEnrollmentIdChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="reenrollmentDate"
                name="reenrollmentDate"
                label="Reenrollment Date"
                className={classes.fullWidthField}
                value={
                  general && general.reEnrollmentDate
                    ? new Date(general.reEnrollmentDate).toLocaleDateString()
                    : ""
                }
                onChange={(e: any) => {
                  /*do nothing*/
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="lda"
                name="lda"
                label="LDA"
                className={classes.fullWidthField}
                value={
                  general && general.lda
                    ? new Date(general.lda).toLocaleDateString()
                    : ""
                }
                onChange={(e: any) => {
                  /*do nothing*/
                }}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="enrollmentDate"
                  label="Enrollment Date *"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  innerRef={(ref: any) => {
                    data.register("enrollmentDate", {
                      required: "Enrollment Date is required",
                      validate: {
                        EDbeforeGraduationDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["graduationDate"]) ||
                          "Enrollment Date must be before graduation date",
                        EDbeforeRevisedGraduationDate: (value) =>
                          new Date(value) <
                            new Date(
                              data.getValues()["revisedGraduationDate"]
                            ) ||
                          "Enrollment Date must be before expected graduation date",
                      },
                    });
                    data.setValue("enrollmentDate", general.enrollmentDate);
                  }}
                  error={!!data.errors.enrollmentDate}
                  helperText={
                    data.errors.enrollmentDate
                      ? (data.errors.enrollmentDate as FieldError).message
                      : ""
                  }
                  className={classes.fullWidthField}
                  value={general.enrollmentDate ? general.enrollmentDate : null}
                  onChange={(value: any) => {
                    data.setValue(
                      "enrollmentDate",
                      value ? new Date(value) : null
                    );
                    handleFieldChange("enrollmentDate", value ? value : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: "Start Date is required",

                      validate: {
                        beforeGraduationDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["graduationDate"]) ||
                          "Start Date must be before graduation date",
                        beforeRevisedGraduationDate: (value) =>
                          new Date(value) <
                            new Date(
                              data.getValues()["revisedGraduationDate"]
                            ) ||
                          "Start Date must be before expected graduation date",
                      },
                    });
                    data.setValue("startDate", general.startDate);
                  }}
                  error={!!data.errors.startDate}
                  helperText={
                    data.errors.startDate
                      ? (data.errors.startDate as FieldError).message
                      : ""
                  }
                  className={classes.fullWidthField}
                  value={general.startDate ? general.startDate : null}
                  onChange={(value: any) => {
                    let oldValue = new Date(general?.startDate);
                    let newValue = new Date(value);
                    let now = new Date(Date.now());
                    data.setValue("startDate", value ? new Date(value) : null);
                    handleFieldChange("startDate", value ? value : null);

                    if (
                      (oldValue <= now && newValue > now) ||
                      (oldValue > now && newValue <= now)
                    ) {
                      setEnrollmentStatus(undefined);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "start date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <EnrollmentStatusAutoComplete
                filterHandle={(data: any) => {
                  setEnrollmentStatus(data);
                }}
                id="enrollmentStatus"
                name="enrollmentStatus"
                label="Status *"
                inputRef={data.register({ required: true })}
                error={!!data.errors.enrollmentStatus}
                helperText={
                  data.errors.enrollmentStatus
                    ? "Enrollment Status is required."
                    : undefined
                }
                valueFilter={
                  general?.statusCodeId
                    ? {
                        key: "value",
                        values: [general?.statusCodeId],
                      }
                    : undefined
                }
                systemStatusId={
                  props.isNewStudentWorkflow
                    ? (props.loadOnlyFutureStartStatus &&
                        props.loadOnlyFutureStartStatus &&
                        new Date(general?.startDate) > new Date(Date.now())) ||
                      general?.startDate === null ||
                      general?.startDate === undefined ||
                      general?.startDate === ""
                      ? 7
                      : 9
                    : undefined
                }
                useOnlyDefaultLeadStatus={
                  props.isNewStudentWorkflow
                    ? (props.loadOnlyFutureStartStatus &&
                        props.loadOnlyFutureStartStatus &&
                        new Date(general?.startDate) > new Date(Date.now())) ||
                      general?.startDate === null ||
                      general?.startDate === undefined ||
                      general?.startDate === ""
                      ? false
                      : true
                    : undefined
                }
                disabled={
                  props.loadOnlyFutureStartStatus === undefined &&
                  new Date(general?.startDate) <= new Date(Date.now())
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="graduationDate"
                  name="graduationDate"
                  label="Contracted Graduation Date *"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  innerRef={(ref: any) => {
                    data.register("graduationDate", {
                      required: "Graduation Date is required",
                      validate: {
                        afterStartDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "Graduation date must be after start date",
                        afterEnrollmentDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["enrollmentDate"]) ||
                          "Graduation date must be after enrollment date",
                      },
                    });
                    data.setValue("graduationDate", general.graduationDate);
                  }}
                  error={!!data.errors.graduationDate}
                  helperText={
                    data.errors.graduationDate
                      ? (data.errors.graduationDate as FieldError).message
                      : ""
                  }
                  className={classes.fullWidthFieldWithIcon}
                  value={general.graduationDate ? general.graduationDate : null}
                  onChange={(value: any) => {
                    data.setValue(
                      "graduationDate",
                      value ? new Date(value) : null
                    );
                    handleFieldChange("graduationDate", value ? value : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <Tooltip
                title="Graduation date calculator"
                aria-label="Graduationdatecalculator"
              >
                <IconButton
                  color="primary"
                  component="span"
                  className={classes.iconWidth}
                  onClick={() => {
                    setShowGradDateCalculator(true);
                  }}
                >
                  <SchoolIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="updatedGraduationDate"
                  key="updatedGraduationDate"
                  name="updatedGraduationDate"
                  label="Amended Contract Graduation Date *"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  innerRef={(ref: any) => {
                    data.register("updatedGraduationDate", {
                      required: "Amended Contract Graduation Date is required",
                      validate: {
                        RGDafterStartDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "Amended contract graduation date must be after start date",
                        RGDafterEnrollmentDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["enrollmentDate"]) ||
                          "Amended contract graduation date must be after enrollment date",
                      },
                    });
                    data.setValue(
                      "updatedGraduationDate",
                      general.updatedGraduationDate
                    );
                  }}
                  error={!!data.errors.updatedGraduationDate}
                  helperText={
                    data.errors.updatedGraduationDate
                      ? (data.errors.updatedGraduationDate as FieldError)
                          .message
                      : ""
                  }
                  className={classes.fullWidthField}
                  value={
                    general.updatedGraduationDate
                      ? general.updatedGraduationDate
                      : null
                  }
                  onChange={(value: any) => {
                    data.setValue(
                      "updatedGraduationDate",
                      value ? new Date(value) : null
                    );
                    handleFieldChange(
                      "updatedGraduationDate",
                      value ? value : null
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="revisedGraduationDate"
                  name="revisedGraduationDate"
                  label="Expected Graduation Date *"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  innerRef={(ref: any) => {
                    data.register("revisedGraduationDate", {
                      required: "Expected Graduation Date is required",
                      validate: {
                        RGDafterStartDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "Expected Graduation date must be after start date",
                        RGDafterEnrollmentDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["enrollmentDate"]) ||
                          "Expected Graduation date must be after enrollment date",
                      },
                    });
                    data.setValue(
                      "revisedGraduationDate",
                      general.revisedGraduationDate
                    );
                  }}
                  error={!!data.errors.revisedGraduationDate}
                  helperText={
                    data.errors.revisedGraduationDate
                      ? (data.errors.revisedGraduationDate as FieldError)
                          .message
                      : ""
                  }
                  className={classes.fullWidthField}
                  value={
                    general.revisedGraduationDate
                      ? general.revisedGraduationDate
                      : null
                  }
                  onChange={(value: any) => {
                    data.setValue(
                      "revisedGraduationDate",
                      value ? new Date(value) : null
                    );
                    handleFieldChange(
                      "revisedGraduationDate",
                      value ? value : null
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <AttendanceStatusAutoComplete
                filterHandle={(data: any) => {
                  setAttendanceStatus(data ? data.value : undefined);
                }}
                id="attendanceStatus"
                name="attendanceStatus"
                label="Attendance Status *"
                inputRef={data.register({ required: true })}
                error={!!data.errors.attendanceStatus}
                helperText={
                  data.errors.attendanceStatus
                    ? "Attendance Status is required."
                    : undefined
                }
                valueFilter={
                  general?.attendanceTypeId
                    ? {
                        key: "value",
                        values: [general?.attendanceTypeId],
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FinAidAdvisorAutoComplete
                filterHandle={(data: any) => {
                  setFinAidAdv(data ? data.value : undefined);
                }}
                id="FaAdvisorId"
                name="FaAdvisorId"
                valueFilter={
                  general?.faadvisorId
                    ? {
                        key: "value",
                        values: [general?.faadvisorId],
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AcademicAdvisorAutoComplete
                filterHandle={(data: any) => {
                  setAcaDemicAdv(data ? data.value : undefined);
                }}
                id="academicAdvisor"
                name="academicAdvisor"
                valueFilter={
                  general?.academicAdvisor
                    ? {
                        key: "value",
                        values: [general?.academicAdvisor],
                      }
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <DegCertSeekingAutoComplete
                filterHandle={(data: any) => {
                  setDegCertSeeking(data ? data.value : undefined);
                }}
                id="degcertseekingid"
                name="degcertseekingid"
                valueFilter={
                  general?.degcertseekingid
                    ? {
                        key: "value",
                        values: [general?.degcertseekingid],
                      }
                    : undefined
                }
                label="Degree Certificate Seeking *"
                inputRef={data.register({ required: true })}
                error={!!data.errors.degcertseekingid}
                helperText={
                  data.errors.degcertseekingid
                    ? "Degree Certificate Seeking is required."
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AdminCriteriaAutoComplete
                filterHandle={(data: any) => {
                  setAdminCriteria(
                    data ? data.value : undefined,
                    data ? data.text : undefined
                  );
                }}
                id="adminCriteriaId"
                name="adminCriteriaId"
                valueFilter={
                  general?.adminCriteriaId
                    ? {
                        key: "value",
                        values: [general?.adminCriteriaId],
                      }
                    : undefined
                }
                label="Admission Criteria *"
                inputRef={data.register({ required: true })}
                error={!!data.errors.adminCriteriaId}
                helperText={
                  data.errors.adminCriteriaId
                    ? "Admission Criteria is required."
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <EducationCompletedAutoComplete
                id="educationCompletedId"
                label={
                  props.isNewEnrollment || props.showMandatory
                    ? "Education Completed *"
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange(
                    "educationCompletedId",
                    v ? v.value : null
                  );
                }}
                name="educationCompletedId"
                valueFilter={
                  general.educationCompletedId
                    ? {
                        key: "value",
                        values: [general.educationCompletedId],
                      }
                    : undefined
                }
                helperText={
                  data.errors.educationCompletedId
                    ? (data.errors.educationCompletedId as FieldError).message
                    : undefined
                }
                error={!!data.errors.educationCompletedId}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <DistanceEducationStatusAutoComplete
                id="distanceEdStatus"
                filterHandle={(data: any) => {
                  handleDistanceEdStatusChange(data ? data.value : undefined);
                }}
                name="distanceEdStatus"
                valueFilter={
                  general?.distanceEducationStatusId
                    ? {
                        key: "value",
                        values: [general?.distanceEducationStatusId],
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="sapId"
                label="SAP Policy"
                name="sapId"
                className={classes.fullWidthField}
                value={
                  programVersionSAPPolicyInfo !== undefined
                    ? programVersionSAPPolicyInfo.sapPolicyName
                    : ""
                }
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="titleIVSAPPolicyId"
                label="Title IV SAP Policy"
                name="titleIVSAPPolicyId"
                className={classes.fullWidthField}
                value={
                  programVersionSAPPolicyInfo !== undefined
                    ? programVersionSAPPolicyInfo.titleIVSAPPolicyName
                    : ""
                }
                disabled
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5></h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={"MM/dd/yyyy"}
                  id="entranceInterviewDate"
                  name="entranceInterviewDate"
                  className={classes.datePickerField}
                  label="Entrance Interview Date"
                  autoOk={true}
                  TextFieldComponent={ThemeTextField}
                  error={!!data.errors.entranceInterviewDate}
                  helperText={
                    data.errors.entranceInterviewDate ? "Invalid Format." : ""
                  }
                  innerRef={(ref: any) => {
                    data.register("entranceInterviewDate", { required: false });
                    data.setValue(
                      "entranceInterviewDate",
                      general.entranceInterviewDate
                    );
                  }}
                  value={
                    general.entranceInterviewDate
                      ? general.entranceInterviewDate
                      : null
                  }
                  onChange={(value: any) => {
                    handleFieldChange(
                      "entranceInterviewDate",
                      value ? value : null
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                {...firstTimePostSecSchoolAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "isFirstTimePostSecSchool",
                    value ? value : undefined
                  );
                }}
                value={general?.isFirstTimePostSecSchool}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="First time at any post secondary school *"
                    name="isFirstTimePostSecSchool"
                    error={!!data.errors.isFirstTimePostSecSchool}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.isFirstTimePostSecSchool
                        ? "First time at any post secondary school is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                {...firstTimeInSchoolAutoComplete}
                autoComplete
                disabled={
                  general?.isFirstTimePostSecSchool?.id === 1 ? true : false
                }
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "isFirstTimeInSchool",
                    value ? value : undefined
                  );
                }}
                value={general?.isFirstTimeInSchool}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="First time at this school *"
                    name="isFirstTimeInSchool"
                    error={!!data.errors.isFirstTimeInSchool}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.isFirstTimeInSchool
                        ? "First time at this school is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="thirdPartyPayer"
                name="thirdPartyPayer"
                label="Third Party Payer"
                className={classes.fullWidthField}
                value={general.thirdPartyPayer ?? ""}
                onChange={(v: any) => {
                  handleFieldOnChange(
                    "thirdPartyPayer",
                    v ? v.target.value : ""
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InstructorAutoComplete
                filterHandle={(data: any) => {
                  setInstructor(data ? data.value : undefined);
                }}
                id="InstructorId"
                name="InstructorId"
                disabled={
                  !checkPermissions([
                    security.permissions.maintenance.EditInstructorField,
                  ])
                }
                valueFilter={
                  general?.instructorId
                    ? {
                        key: "value",
                        values: [general?.instructorId],
                      }
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="thirdPartyContract"
                className={classes.switch}
                label="Attending through a contract with third party"
                control={
                  <Switch
                    checked={general?.thirdPartyContract}
                    value={general?.thirdPartyContract}
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "thirdPartyContract",
                        e.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="incarceratedWithinSixMonth"
                className={classes.switch}
                label="Incarcerated within 6 months of program completion"
                control={
                  <Switch
                    checked={general?.incarceratedWithinSixMonth}
                    value={general?.incarceratedWithinSixMonth}
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "incarceratedWithinSixMonth",
                        e.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Button variant="contained" onClick={handleRebuildAcademics}>
                Rebuild Academics
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="titleIvAuthorization"
                className={classes.switch}
                label="Title IV authorization received"
                control={
                  <Switch
                    checked={general.titleIvAuthorization}
                    value={general.titleIvAuthorization}
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "titleIvAuthorization",
                        e.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="covid19DropWithAttestation"
                className={classes.switch}
                label="COVID-19 drop with attestation"
                control={
                  <Switch
                    checked={general.covid19DropWithAttestation}
                    value={general.covid19DropWithAttestation}
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "covid19DropWithAttestation",
                        e.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} hidden={!usesKlassApp}>
              <Button
                variant="contained"
                onClick={() => {
                  onSendKlassApp();
                }}
              >
                Send to Klass App
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
        <Dialog
          fullScreen
          onClose={handleCloseGradDateCalculator}
          aria-labelledby="customized-dialog-title"
          open={showGradDateCalculator}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseGradDateCalculator}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.appBarTitle}>
                Close
              </Typography>
            </Toolbar>
          </AppBar>
          <AdvantageViewer
            url={gradDateCalculatorLink}
            params={{
              cmpId: campusId,
              desc: "Graduation Date Calculator",
              mod: "AR",
            }}
          />
        </Dialog>
      </Card>
    </div>
  );
};

export default GeneralInfo;
