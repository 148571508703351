import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
  CardContent,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { IClassScheduleGridRow } from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { ThemeTextField } from "../../../../components/_Layout/Inputs/ThemeTextField";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import { FormContext, useForm, FieldError } from "react-hook-form";
import ScheduleDetailsGrid from "./SchedulesDetailsGrid";
import * as ClassScheduleApi from "../../../../api/setup/academics/classScheduleApi";
import { EmptyGuid } from "../../../../utils/constants";
import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
      padding: theme.spacing(2),

      marginTop: theme.spacing(2),
    },
    saveButton: {
      width: 90,
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

type ScheduleDetailsProps = {
  model: IClassScheduleGridRow;
  setModel: any;
  isDirty: boolean;
  setDirty: any;
  confirmationPopupState: any;
  setConfirmationPopupState: any;
  setSnackBar: any;
  refreshSchedules: any;
  setSchedules: any;
  clearFields: any;
  resetChanges: any;
};
const ScheduleDetails = (props: ScheduleDetailsProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isDirty, setDirty } = props;
  const { confirmationPopupState, setConfirmationPopupState } = props;

  const handleCancel = () => {
    if (isDirty == true) {
      setConfirmationPopupState((state: any) => {
        return {
          ...state,
          open: true,
          confirmationText: "Are you sure you want to discard your changes?",
          confirmationButton: "Yes",
          title: "Discard Changes?",
          onConfirmEvent: () => {
            setDirty(false);
            if (model.classScheduleId === EmptyGuid) {
              props.clearFields();
            } else {
              props.resetChanges();
            }
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
          onCancelEvent: () => {
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
        };
      });
    } else {
      props.clearFields();
    }
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  useEffect(() => {
    handleFieldChange("totalHours", model.totalHours);
  }, []);

  const handleFieldChange = (fieldId: string, value: any) => {
    if (fieldId != "totalHours" && (model as any)[fieldId] != value) {
      setDirty(true);
    }
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);
  };

  const setScheduleDetails = (newValue: any) => {
    handleFieldChange("classScheduleDetails", newValue);
  };

  const validateMaximumTimeAllowed = (model: IClassScheduleGridRow) => {
    let isValid = true;
    let scheduleDetail = model.classScheduleDetails;
    scheduleDetail.map(function (v) {

      if ( v.maximumHoursPerDay && (v.maximumHoursPerDay > 24 || (v.total && Math.round(v.maximumHoursPerDay * 100) < Math.round(v.total * 100))))
        { 
          isValid = false;
        }
    });
    return isValid;
  };

  const onSubmit = (d: any) => {
    if (model) {
      let modelToSend = {...model};
      data.triggerValidation().then((validation: any) => {
        let isValid = validateMaximumTimeAllowed(model);
        if (validation && isValid) {
          setLoading(true);
          setDirty(false);
          ClassScheduleApi.upsertClassSchedule(modelToSend).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                props.refreshSchedules(response?.result);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
              setLoading(false);
            },
            (exception: any) => {
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
              setLoading(false);
            }
          );
        } else {
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Maximum time allowed must be less then 24 hours and greater then schedule hours.",
            };
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(loading && (
            <div className={classes.preLoader}>
              <CircularProgress />
            </div>
          )) || (
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="name"
                    label="Schedule *"
                    name="name"
                    className={classes.fullWidthField}
                    value={model.name}
                    onChange={(e: any) => {
                      handleFieldChange("name", e.target.value);
                    }}
                    error={!!data.errors.name}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.name
                        ? "Schedule name is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="code"
                    label="Code *"
                    name="code"
                    className={classes.fullWidthField}
                    value={model.code ? model.code : ""}
                    onChange={(e: any) => {
                      handleFieldChange("code", e.target.value);
                    }}
                    error={!!data.errors.code}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.code ? "Code is required." : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <StatusAutoComplete
                    id="statusId"
                    name="statusId"
                    label="Status *"
                    valueFilter={
                      model.statusId
                        ? {
                            key: "value",
                            values: [model.statusId],
                          }
                        : undefined
                    }
                    filterHandle={(v: any) => {
                      handleFieldChange("statusId", v ? v.value : null);
                    }}
                    error={!!data.errors.statusId}
                    inputRef={data.register({
                      required: "Status is required.",
                    })}
                    helperText={
                      data.errors.statusId
                        ? (data.errors.statusId as FieldError).message
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <InputField
                    type={InputType.NUMBER}
                    key="totalHours"
                    id="totalHours"
                    label="Total Hours *"
                    name="totalHours"
                    value={model.totalHours ? model.totalHours : 0}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.gridMargin}
                spacing={1}
              ></Grid>
              <ScheduleDetailsGrid
                model={props.model.classScheduleDetails}
                setModel={setScheduleDetails}
                readonly={false}
              />
              <Grid container direction="row" spacing={4}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <ProgressSaveButton
                        text="Save"
                        onClick={(e: any) => {}}
                        loading={loading}
                        disabled={false}
                        buttonClassName={classes.saveButton}
                        size="small"
                      ></ProgressSaveButton>
                    </Grid>
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        disabled={loading}
                        onClick={handleCancel}
                        size="small"
                        color="primary"
                        variant="contained"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleCancel}
                        style={{width:80}}
                      >
                        NEW
                      </Button>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </form>
      </FormContext>
      <ConfirmationModal {...confirmationPopupState} />
    </Fragment>
  );
};

export default ScheduleDetails;
