import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",      
      color: "black",
    },
    directionIcon: {
      fontSize: "22px",
    },
    textBlock: {
      padding: theme.spacing(1, 1),
    }
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab2 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>2. Percentage of Title IV Aid Earned</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="withdrawalDate"
                      name="withdrawalDate"
                      label="Withdrawal date"
                      key={"withdrawalDate1"}
                      disabled={override == true ? false : true}
                      value={
                        model?.withdrawalDate
                          ? model.withdrawalDate
                          : new Date()
                      }
                      onChange={(e: any, value: any) =>
                        handleFieldOnChange(
                          "withdrawalDate",
                          value ? value : undefined
                        )
                      }
                      className={classes.startDateField}
                      KeyboardButtonProps={{
                        "aria-label": "report date",
                      }}
                      helperText={
                        data.errors["withdrawalDate"]
                          ? (data.errors["withdrawalDate"] as FieldError)
                              .message
                          : undefined
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <b>H. Determine the percentage of period completed</b>
                  <div>
                    Divide the clock hours scheduled to have been completed as
                    of the withdrawal date in the period by the total clock
                    hours in the period.
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Hours scheduled to complete</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Total hours in period</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="completedTime"
                            key="completedTime"
                            inputProps={{ style: { textAlign: "right" } }}
                            disabled={override == true ? false : true}
                            defaultValue={model?.completedTime}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "completedTime",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.completedTime}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.completedTime
                                ? (data.errors.completedTime as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>÷</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="totalTime"
                            key="totalTime"
                            inputProps={{ style: { textAlign: "right" } }}
                            disabled={override == true ? false : true}
                            defaultValue={model?.totalTime}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("totalTime", v.target.value);
                            }}
                            error={!!data.errors.totalTime}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.totalTime
                                ? (data.errors.totalTime as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="percentageOfActualAttendance"
                            key="percentageOfActualAttendance"
                            inputProps={{ style: { textAlign: "right" } }}
                            disabled={override == true ? false : true}
                            defaultValue={model?.percentageOfActualAttendance}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "percentageOfActualAttendance",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.percentageOfActualAttendance}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.percentageOfActualAttendance
                                ? (
                                    data.errors
                                      .percentageOfActualAttendance as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className={classes.textBlock}>
                    <label className={classes.directionIcon}> ☞ </label> If this
                    percentage is greater than 60%, enter 100% in Box H and
                    proceed to Step 3.
                  </div>
                  <div className={classes.textBlock}>
                    <label className={classes.directionIcon}> ☞ </label> If this
                    percentage is less than or equal to 60%, enter that
                    percentage in Box H, and proceed to Step 3.
                  </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <InputField
                    type={InputType.NUMBER}
                    label=""
                    name="boxHResult"
                    key="boxHResult"
                    decimal
                    thousandSeparator
                    inputProps={{ style: { textAlign: "right" } }}
                    disabled={override == true ? false : true}
                    defaultValue={model?.boxHResult}
                    onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                      handleFieldOnChange("boxHResult", v.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <React.Fragment>
                          <div className={classes.leftLabelBox}><b>H.</b></div>
                        </React.Fragment>
                      ),
                    }}
                    error={!!data.errors.boxHResult}
                    inputRef={data.register({
                      required: "Field is required.",
                    })}
                    helperText={
                      data.errors.boxHResult
                        ? (data.errors.boxHResult as FieldError).message
                        : undefined
                    }
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <div className={classes.leftLabel}>%</div>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab2;
