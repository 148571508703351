import API from "../../apiWrapper";
// C:\_Git\phoenix_dtourn\UX\FAME.Phoenix.UX\FAME.Phoenix.UX\ClientApp\src\api\apiWrapper.tsx
// C:\_Git\phoenix_dtourn\UX\FAME.Phoenix.UX\FAME.Phoenix.UX\ClientApp\src\api\setup\appSettings\appSettingsApi.tsx

export const getAppSettingsByCampus = (CampusId: string) => {
  return API()
    .get("/SystemCatalog/AppSettings/GetAppSettingByCampus", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const DeleteAppSetting = (settingId: string, valueId:string, campusId: string) => {
  return API()
    .delete("/SystemCatalog/AppSettings/DeleteAppSetting", {
      params: {
        settingId,
        valueId,
        campusId,
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const UpdateAppSettingsAsync = (
  settingId: string,
  valueId:string,
  value: string,
  campusId: string,
  addNewRow?:boolean,
) => {
  const payload = {
    settingId,
    valueId,
    value,
    campusId,
    addNewRow,
  };
  return API()
    .post("/SystemCatalog/AppSettings/UpdateAppSettingsAsync", payload)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const printAppSettings = (
  keynameFilter: string, 
  campusDescripFilter: string, 
  descriptionFilter: string,
  page: number,
  rowsPerPage: number,
) => {
  return API()
    .post(
      "/SystemCatalog/AppSettings/GetAppSettingsReport",
      {
        keynameFilter,
        campusDescripFilter,
        descriptionFilter,
        page,
        rowsPerPage,
      },
      { responseType: "blob" }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "AppSettingsReport.pdf");
          document.body.appendChild(link);
          link.click();
        }
      },
      (error: any) => {
        return null;
      }
    );
};