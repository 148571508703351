import * as React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import InputField from "../../components/_Layout/Inputs/InputField";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import IClassSearchParam from "../../interfaces/setup/creditHour/IClassSearchParam";
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../interfaces/common/card/CustomCardTitle";
import * as TransactionCodeApi from "../../api/setup/financial/transactionCode";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import TransactionCodeDetails from "./TransactionCodeDetails";
import {
  ITransactionCode,
  createDefaultTransactionCode,
} from "../../interfaces/setup/financial/ITransactionCode";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    tableContainer: {
      maxHeight: "400px",
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    TransactionCodeSelectionCell: {
      color: "black",
      cursor: "pointer",
      fontWeight: 500,
    },
    TransactionCodeSelectionCellInActive: {
      color: "gray",
      cursor: "pointer",
      fontWeight: 500,
    },
    cardMain: {
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
  })
);

interface ListItem {
  value: number;
  text: string;
}

const TransactionCodes = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [isDirty, setDirty] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  
  const [selectedTransactionCode, setSelectedTransactionCode] = React.useState<
    ITransactionCode | undefined
  >(undefined);
  const [trackChangesModel, setTrackChangesModel] =
    React.useState<ITransactionCode>(createDefaultTransactionCode());
  const [transactionCodes, setTransactionCodes] = React.useState<
    Array<ITransactionCode>
  >(new Array<ITransactionCode>());

  const addNewRecord = () => {
    let newTransactionCode = createDefaultTransactionCode();
    setSelectedTransactionCode({ ...newTransactionCode });
  };

  const refreshTransactionCodes = () => {
    resetChanges();
    getTransactionCodes(userSelectedCampus);
  };

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  React.useEffect(() => {
    addNewRecord();
    getTransactionCodes(userSelectedCampus);
  }, [userSelectedCampus]);

  const getTransactionCodes = (campusId: string) => {
    TransactionCodeApi.GetTransactionCodesByCampusId(campusId).then(
      (response) => {
        if (response) {
          setTransactionCodes(response.result);
        }
      },
      (exception: any) => {}
    );
  };

  const resetChanges = () => {
    let resetModel: ITransactionCode = trackChangesModel;
    setSelectedTransactionCode({ ...resetModel });
  };

  const editTransactionCode = (TransactionCode: ITransactionCode) => {
    if (isDirty == true) {
      setConfirmationPopupState((state: any) => {
        return {
          ...state,
          open: true,
          confirmationText: "Are you sure you want to discard your changes?",
          confirmationButton: "Yes",
          title: "Discard Changes?",
          onConfirmEvent: () => {
            setDirty(false);
            setSelectedTransactionCode({ ...TransactionCode });
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
          onCancelEvent: () => {
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
        };
      });
    } else {
      setSelectedTransactionCode({ ...TransactionCode });
    }
  };

  const filteredTransCodes =
    searchText && searchText.length > 2
      ? transactionCodes.filter(
          (TransactionCode) =>
            TransactionCode.transCodeId === searchText ||
            (TransactionCode.transCodeDescrip ?? "")
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
        )
      : transactionCodes;

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true} elevation={12} className={classes.cardMain}>
        <CardContent>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <Grid container direction="column" spacing={8} wrap="nowrap">
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={3} md={3}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Transaction Codes"></CustomCardTitle>
                        <CardContent>
                          <InputField
                            value={searchText ?? ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchText(e.target.value)}
                            inputProps={{ className: classes.searchField }}
                            InputProps={{
                              startAdornment: (
                                <SearchIcon className={classes.iconColor} />
                              ),
                              endAdornment: searchText && (
                                <IconButton onClick={() => setSearchText("")}>
                                  <CloseIcon className={classes.iconColor} />
                                </IconButton>
                              ),
                            }}
                            placeholder={"Search Transaction Code"}
                          />
                          <TableCell align="left">
                            <FormControlLabel
                              id="active"
                              className={classes.switch}
                              labelPlacement="start"
                              label="Show All"
                              control={
                                <Switch
                                  checked={isActive}
                                  name="active"
                                  onChange={(e: any) => {
                                    setIsActive(e.target.checked);
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </TableCell>
                          <TableContainer
                            component={Paper}
                            square
                            className={classes.tableContainer}
                          >
                            <Table
                              stickyHeader
                              size="small"
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableBody>
                                {loading ? (
                                  <TableRow>
                                    <TableCell
                                      className={classes.preLoaderCell}
                                    >
                                      <CircularProgress />
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  filteredTransCodes
                                    .filter((row: ITransactionCode) =>
                                      isActive == false
                                        ? row.status == "Active"
                                        : true
                                    )
                                    .map((tc, index) => (
                                      <TableRow key={`row-${index}`}>
                                        <TableCell
                                          key={`TransactionCodeName-${index}`}
                                          className={
                                            tc.status == "Active"
                                              ? classes.TransactionCodeSelectionCell
                                              : classes.TransactionCodeSelectionCellInActive
                                          }
                                          onClick={() =>
                                            editTransactionCode(
                                              JSON.parse(JSON.stringify(tc))
                                            )
                                          }
                                        >
                                          {tc.transCodeDescrip}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Transaction Code Detail"></CustomCardTitle>
                        <CardContent>
                          {selectedTransactionCode !== undefined && (
                            <TransactionCodeDetails
                              resetChanges={resetChanges}
                              model={selectedTransactionCode}
                              setModel={setSelectedTransactionCode}
                              isDirty={isDirty}
                              setDirty={setDirty}
                              confirmationPopupState={confirmationPopupState}
                              campusId={userSelectedCampus}
                              setConfirmationPopupState={
                                setConfirmationPopupState
                              }
                              setSnackBar={setSnackBarProps}
                              refreshTransactionCodes={refreshTransactionCodes}
                              clearFields={addNewRecord}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TransactionCodes;
