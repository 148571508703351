import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, Tabs, Tab, Grid } from '@material-ui/core';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import Campuses from './campuses'; // <-- Update this import path as needed

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(1),
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.paper,
            flexDirection: 'column',
            borderRadius: theme.shape.borderRadius,
            '& .MuiTypography-root': {
                fontWeight: 'bold',
            },
        },
        card: {
            margin: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
        },
        cardContent: {
            padding: 14,
        },
        // Add other styles as needed...
    })
);

const CampusManagement = (props: any) => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = React.useState<number>(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <div className={classes.root}>
            <CardContent className={classes.cardContent}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Campuses" style={{ textTransform: 'none' }} />
                    {/* <Tab label="Another Tab" style={{ textTransform: 'none' }} /> */}
                </Tabs>

                {tabIndex === 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Campuses />
                        </Grid>
                    </Grid>
                )}

                {/* {tabIndex === 1 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Another component or content here
                        </Grid>
                    </Grid>
                )} */}

            </CardContent>
        </div>
    );
};

export default CampusManagement;
