import API from "../../apiWrapper";

export const GetCampusById = (campusId: string) => {
    return API()
        .get("/SystemCatalog/Campus/GetCampusById", {
            params: {
                campusId
            }
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data;
                }
            },
            (error: any) => {
                return error.response;
            }
        );
};

export const AddOrUpdateCampus = (campusData: any) => {
    var res = API()
        .post("/SystemCatalog/Campus/AddOrUpdateCampus", campusData)
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data;
                }
            },
            (error: any) => {
                return error.response;
            }
        );

    return res;
};
