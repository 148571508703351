import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardActions, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as chargeDefinitionApi from "../../../../api/setup/chargeDefinition/chargeDefinitionApi";
import { useSelector } from "react-redux";
import { ChargeDefinition } from "../../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import StudentChargeDefinitionPopup from "./StudentChargeDefinitionPopup";
import { cloneDeep } from "lodash";
import { EmptyGuid } from "../../../../utils/constants";
const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.paper.background,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2),
            },
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.paper.background,
        },
        cardTitle: {
            fontSize: 17,
            color: "Black",
            textDecoration: "bold",
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(1, 1),
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
        fullWidthField: {
            width: "100%",
        },
        button: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
            whiteSpace: "nowrap",
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        },
    })
);

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {
            "& .MuiFormLabel-root": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "80%",
            },
        },
    })
)(TextField);

interface ListItem {
    value: number;
    text: string;
}

type automaticChargingDetails = {
    chargeDefinition?: ChargeDefinition;
    setChargeDefinition: (chargeDefinition?: ChargeDefinition) => void;
    setSnackBar: (snackBarProps: any) => void;
    isNewEnrollment: boolean;
};

const EnrollmentChargeDefinition = (props: automaticChargingDetails) => {
    const classes = useStyles({});

    const selectedEnrollment = useSelector(
        (state: any) => state.student.selectedEnrollment?.stuEnrollId
    );

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [open, setOpen] = useState<boolean>(false);

    const getChargingMethodDetail = () => {
        if (!props.isNewEnrollment && !!selectedEnrollment) {
            chargeDefinitionApi
                .getChargeDefinitionByStuEnrollId(selectedEnrollment)
                .then((response: any) => {
                    if (!!response && !!response.chargeDefinitionId && response.chargeDefinitionId != EmptyGuid) {
                        let modelToUpdate = cloneDeep(response);
                        props.setChargeDefinition(modelToUpdate);
                    }else{
                        props.setChargeDefinition(undefined);
                    }
                });
        }

    };

    useEffect(() => {
        getChargingMethodDetail();
    }, [selectedEnrollment]);

    const onCustommizeIncrementClick = () => {
        setOpen(true);
    };

    return (
        <Card square={true}>
            <StudentChargeDefinitionPopup
                chargeDefinition={props.chargeDefinition}
                setChargeDefinition={props.setChargeDefinition}
                open={open}
                setOpen={setOpen}
                setSnackBar={props.setSnackBar}
                isNewEnrollment={props.isNewEnrollment}
                isEditable={true}
                stuEnrollId={selectedEnrollment}
                campusId={userSelectedCampus}
            />
            <Typography className={clsx(classes.cardTitle)}>
                &nbsp; Automatic Charging
            </Typography>
            <CardContent className={classes.cardContent}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <ThemeTextField
                            id="chargingDefinitionVersion"
                            name="chargingDefinitionVersion"
                            label="Charge Definition Version"
                            className={classes.textField}
                            value={props.chargeDefinition?.chargeDefinitionDescription ?? ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type={"button"}
                            onClick={onCustommizeIncrementClick}
                        >
                            CUSTOMIZE DEFINITION
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions></CardActions>
        </Card>
    );
};

export default EnrollmentChargeDefinition;
