import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab10 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>10. Return of Grant Funds by the Student</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    Except as noted below, the student must return the unearned
                    grant funds for which he or she is responsible (Box U). The
                    grant funds returned by the student are applied to the
                    following sources in the order indicated, up to the total
                    amount disbursed from that grant program minus any grant
                    funds the school is responsible for returning to that
                    program in Step 6.
                  </div>
                  <div>
                    <b>
                      Note that the student is not responsible for returning
                      funds to any program to which the student owes $50.00 or
                      less.
                    </b>
                  </div>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Grant Programs</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Amount to return</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>1. Pell Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantAmountToReturn"
                            key="pellGrantAmountToReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.pellGrantAmountToReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantAmountToReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantAmountToReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantAmountToReturn
                                ? (
                                    data.errors
                                      .pellGrantAmountToReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>2. Iraq Afghanistan Service Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantAmountToReturn"
                            key="iraqAfgGrantAmountToReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.iraqAfgGrantAmountToReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantAmountToReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantAmountToReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantAmountToReturn
                                ? (
                                    data.errors
                                      .iraqAfgGrantAmountToReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>3. FSEOG</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogAmountToReturn"
                            key="fseogAmountToReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.fseogAmountToReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogAmountToReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogAmountToReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogAmountToReturn
                                ? (data.errors.fseogAmountToReturn as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>4. TEACH Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantAmountToReturn"
                            key="teachGrantAmountToReturn"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.teachGrantAmountToReturn}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantAmountToReturn",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantAmountToReturn}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantAmountToReturn
                                ? (
                                    data.errors
                                      .teachGrantAmountToReturn as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab10;
