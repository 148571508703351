import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  CardActions,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Popover,
  Typography,
  Button,
  TextField,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { useSelector } from "react-redux";
import * as appSettingsApi from "../../../api/setup/appSettings/appSettingsApi";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import CampusGroupAutoComplete from "../../../components/AutoComplete/CampusGroupAutoComplete";
import TimeIntervalsAutoComplete from "../../../components/AutoComplete/TimeIntervalsAutoComplete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Add";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  AppSettings2,
  AppSettings3,
} from "../../../interfaces/setup/appSettings/appSettingInterface";
import SearchBox from "../../../../src/pages/student/grades/SearchBox";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { ca } from "date-fns/locale";
import { Pagination } from "@material-ui/lab";
import CampusWithExclusionAutoComplete from "../../../components/AutoComplete/CampusWithExclusionAutoComplete";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "center",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    tableContainer: {
      // height: "400px",
      height: "100%",
      overflowY: "auto",
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: "100%",
      height: "100%",
      tableLayout: "fixed",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: "#000000",
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    cardMain: {
      marginTop: theme.spacing(2),
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5,
    },
    typography: {
      padding: theme.spacing(2),
    },
    lineThru: {
      textDecoration: "line-through",
    },
    textField: {
      width: "100%",
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      paddingBottom: theme.spacing(0),
      height: theme.spacing(3),
    },
    dateField: {
      width: "100%",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    button: {
      marginTop: -theme.spacing(8),
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    tableCell: {
      maxWidth: "200px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tableColumn: {
      wordWrap: "break-word",
      overflowWrap: "break-word",
      whiteSpace: "normal",
      // verticalAlign: "top",
    },
    highlightedRow: {
      backgroundColor: "#f9fafa !important",
    },
  })
);

const ConfigAppSettings = (props: any) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });

  const [appSettings2, setAppSettings2] = React.useState<Array<AppSettings2>>(
    []
  );

  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const [isEditOn, setIsEditOn] = React.useState<boolean>(false);
  const [editIndex, setEditIndex] = React.useState<number | null>(null);

  const [searchDescripToolFilter, setSearchDescripToolFilter] =
    React.useState<string>("");
  const [searchCampusToolFilter, setSearchCampusToolFilter] =
    React.useState<string>("");
  const [searchSettingNameFilter, setSearchSettingNameFilter] =
    React.useState<string>("");

  interface ListItem {
    value: number;
    text: string;
  }

  const optionList = useSelector(
    (state: any) => state.ac_cache?.data?.statuses?.options ?? []
  ) as ListItem[];
  const activeStatusId = optionList
    .find((x) => x.text === "Active")
    ?.value.toString();
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);

  const [appSettingIdDelete, setAppSettingIdToDelete] = React.useState<
    string | null
  >(null);
  const [popOverAnchorEl, setPopOverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const popOverOpen = Boolean(popOverAnchorEl);
  const popOverId = popOverOpen ? "simple-popover-appSetting" : undefined;

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const loadAppSettings2 = () => {
    if (userSelectedCampus) {
      appSettingsApi.getAppSettingsByCampus(userSelectedCampus).then(
        (response: any) => {
          if (response && response.data) {
            setAppSettings2(response.data);
          }
        },
        (exception: any) => {}
      );
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    loadAppSettings2();
  }, [userSelectedCampus]);

  React.useEffect(() => {
    if (activeStatusId) {
      setStatusFilter(activeStatusId);
    }
  }, [activeStatusId]);

  const handleClosePopOver = () => {
    setAppSettingIdToDelete(null);
    setPopOverAnchorEl(null);
  };

  function handleModelChange(fieldId: string, value: any, valueId: string) {
    setAppSettings2((prevState) =>
      prevState.map((row) =>
        row.valueId === valueId ? { ...row, [fieldId]: value } : row
      )
    );
  }

  const handleDelete2 = async (settingId: any, valueId: any, campusId: any) => {
    const selectedCampus = campusId || userSelectedCampus;

    try {
      await appSettingsApi.DeleteAppSetting(settingId, valueId, selectedCampus);

      setSnackBarProps({
        variant: "success",
        showSnackBar: true,
        messageInfo: "Deleted successfully!",
      });

      await loadAppSettings2();
    } catch (error) {
      setSnackBarProps({
        variant: "error",
        showSnackBar: true,
        messageInfo: "Delete failed",
      });
    }
  };

  const handleEdit2 = async (
    settingId: any,
    valueId: any,
    value: any,
    campusId: any,
    addNewRow?: boolean
  ) => {
    console.log("Updating setting:", settingId, value, campusId);

    const selectedCampus = campusId || userSelectedCampus;

    try {
      await appSettingsApi.UpdateAppSettingsAsync(
        settingId,
        valueId,
        value,
        selectedCampus,
        addNewRow
      );

      setSnackBarProps({
        variant: "success",
        showSnackBar: true,
        messageInfo: "Settings updated successfully!",
      });

      await loadAppSettings2();
    } catch (error) {
      setSnackBarProps({
        variant: "error",
        showSnackBar: true,
        messageInfo: error.message || "Update failed",
      });
    }
  };

  const [newRow, setNewRow] = React.useState<AppSettings2 | null>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [highlightedRows, setHighlightedRows] = React.useState<Set<string>>(
    new Set()
  );
  const [originalValues, setOriginalValues] = React.useState<{
    [key: string]: string;
  }>({});

  const handleAddIconClick = (baseRowId: string) => {
    const baseRow = appSettings2.find((row) => row.settingId === baseRowId);

    if (baseRow) {
      setNewRow({
        ...baseRow,
        value: "",
      });
    }

    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    setHighlightedRows((prev) => new Set([...prev, baseRowId]));
  };

  const [appSettings3, setAppSettings3] = React.useState<AppSettings3[]>([]);

  const initialAppSettings3: AppSettings3 = {
    valueId: "",
    settingId: "",
    keyName: "",
    description: "",
    campusSpecific: false,
    extraConfirmation: false,
    value: "",
    campusId: "",
    valueOption: [],
    campusExists: [],
    campusName: "",
  };

  const handleNewChange = <K extends keyof AppSettings3>(
    field: K,
    value: AppSettings3[K]
  ) => {
    setNewRow((prev) => (prev ? { ...prev, [field]: value } : prev));
  };

  const handleSaveNewAppSetting = () => {
    console.log("saveMethod1", newRow);

    setNewRow(null);

    if (!newRow) return;

    const newAppSettingRow: AppSettings3 = {
      valueId: "",
      settingId: newRow.settingId || "",
      keyName: newRow.keyName || "",
      description: newRow.description || "",
      campusSpecific: newRow.campusSpecific,
      extraConfirmation: newRow.extraConfirmation,
      value: newRow.value || "",
      campusId: newRow.campusId || "",
      valueOption: newRow.valueOption || [],
      campusExists: newRow.campusExists || [],
      campusName: newRow.campusName || "",
    };

    setAppSettings3([newAppSettingRow, ...appSettings3]);

    setNewRow(initialAppSettings3);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setEditIndex(null);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [showVoid, setShowVoid] = React.useState(false);
  const handleClickPrint = () => {
    setLoading(true);
    let enrollmentId = selectedEnrollment.stuEnrollId;
    appSettingsApi
      .printAppSettings(
        searchSettingNameFilter,
        searchCampusToolFilter,
        searchDescripToolFilter,
        page + 1,
        rowsPerPage
      )
      .then(
        (response: any) => {
          setLoading(false);
        },
        (exception: any) => {}
      );
  };

  const filteredAppSettings =
    appSettings2
      ?.filter(
        (row) =>
          !row.keyName ||
          row.keyName
            ?.toLowerCase()
            .trim()
            .includes(searchSettingNameFilter.toLowerCase().trim())
      )
      .filter(
        (row) =>
          !row.campusName ||
          row.campusName
            ?.toLowerCase()
            .trim()
            .includes(searchCampusToolFilter.toLowerCase().trim())
      )
      .filter(
        (row) =>
          !row.description ||
          row.description
            ?.toLowerCase()
            .trim()
            .includes(searchDescripToolFilter.toLowerCase().trim())
      ) || [];

  const pagedAppSettings = filteredAppSettings.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //console.log("pagedAppSettings",pagedAppSettings) //NoDelete

  const numPages = Math.ceil(filteredAppSettings.length / rowsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
    setEditIndex(null);
  };

  const [deleteConfirm, setDeleteConfirm] = React.useState<{
    settingId: string;
    valueId: string;
    campusId: string;
  } | null>(null);

  const handleOpenPopOver = (
    event: React.MouseEvent<HTMLButtonElement>,
    settingId: string,
    valueId: string,
    campusId: string
  ) => {
    setDeleteConfirm({ settingId, valueId, campusId });
    setPopOverAnchorEl(event.currentTarget);
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirm) {
      await handleDelete2(
        deleteConfirm.settingId,
        deleteConfirm.valueId,
        deleteConfirm.campusId
      );
      setDeleteConfirm(null);
      setPopOverAnchorEl(null);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.paperRoot}>
        <CustomSnackbar
          variant={snackBarProps.variant}
          message={snackBarProps.messageInfo}
          open={snackBarProps.showSnackBar}
          onClose={(event?: React.SyntheticEvent, reason?: string) => {
            setSnackBarProps((props: any) => {
              return { ...props, showSnackBar: false };
            });
          }}
        ></CustomSnackbar>
        <Card square={true} elevation={3} className={classes.cardMain}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Card>
                    <CardContent>
                      <Grid item md={12}>
                        <Typography variant="h4" style={{ fontWeight: "bold" }}>
                          Manage Configuration Settings
                        </Typography>
                      </Grid>
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}></Grid>

                        <Grid item xs={4} sm={4} md={4}></Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid sm={12}>
                  <Button
                    onClick={handleClickPrint}
                    size="small"
                    style={{ padding: "10px", margin: "10px" }}
                    color="secondary"
                    variant="contained"
                  >
                    Export To PDF
                  </Button>
                </Grid>

                <TableContainer
                  ref={scrollRef}
                  component={Paper}
                  square
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    size="small"
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", width: "80px" }}
                        >
                          Add
                        </TableCell>

                        <TableCell
                          style={{ fontWeight: "bold", width: "80px" }}
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", width: "80px" }}
                        >
                          Delete
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: "160px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                            verticalAlign: "top",
                            fontWeight: "bold",
                          }}
                        >
                          Setting Name
                        </TableCell>
                        <TableCell
                          style={{
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                            verticalAlign: "top",
                            fontWeight: "bold",
                          }}
                        >
                          Value
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Campus
                        </TableCell>

                        <TableCell style={{ fontWeight: "bold" }}>
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <></>
                        </TableCell>

                        <TableCell>
                          <></>
                        </TableCell>
                        <TableCell>
                          <></>
                        </TableCell>

                        <TableCell>
                          <SearchBox
                            label={"Search Setting Name"}
                            value={searchSettingNameFilter}
                            onChange={(e: any) => {
                              setSearchSettingNameFilter(e.target.value);
                              setPage(0);
                              setEditIndex(null);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <></>
                        </TableCell>
                        <TableCell>
                          <SearchBox
                            label={"Search Campus"}
                            value={searchCampusToolFilter}
                            onChange={(e: any) => {
                              setSearchCampusToolFilter(e.target.value);
                              setPage(0);
                              setEditIndex(null);
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <SearchBox
                            label={"Search Description"}
                            value={searchDescripToolFilter}
                            onChange={(e: any) => {
                              setSearchDescripToolFilter(e.target.value);
                              setPage(0);
                              setEditIndex(null);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {/* ---New Row--- */}
                      {newRow && (
                        <TableRow className={classes.highlightedRow}>
                          <TableCell>
                            <Button
                              startIcon={<CheckIcon />}
                              onClick={() => {
                                handleSaveNewAppSetting();
                                handleEdit2(
                                  newRow.settingId,
                                  "",
                                  newRow.value,
                                  newRow.campusId,
                                  true
                                );
                                setNewRow(null);
                                loadAppSettings2();
                              }}
                            />
                            <Button
                              startIcon={<CancelIcon />}
                              onClick={() => {
                                setNewRow(null);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <></>
                          </TableCell>
                          <TableCell>
                            <></>
                          </TableCell>
                          <TableCell
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            {newRow.keyName}
                          </TableCell>
                          <TableCell
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            {newRow.valueOption &&
                            newRow.valueOption.length > 0 ? (
                              <select
                                value={newRow.value}
                                onChange={(e) => {
                                  console.log(
                                    "Before Assign Drop Down",
                                    newRow.value
                                  );
                                  handleNewChange(
                                    "value",
                                    e.target.value as string
                                  );
                                  newRow.value = e.target.value;
                                  console.log(
                                    "After Assign Drop Down",
                                    newRow.value
                                  );
                                }}
                              >
                                {newRow.valueOption.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <InputField
                                type={InputType.TEXT}
                                key="value"
                                id="value"
                                label=""
                                name="value"
                                value={newRow?.value || ""}
                                onChange={(e) =>
                                  handleNewChange("value", e.target.value)
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell style={{ paddingBottom: "22px" }}>
                            {
                              <CampusWithExclusionAutoComplete
                                id="campusId"
                                name="campusId"
                                // label=""
                                key="campusId"
                                filterHandle={(v: any) => {
                                  handleNewChange("campusId", v.value);
                                  newRow.campusId = v.value;
                                }}
                                valueFilter={
                                  newRow.campusId
                                    ? {
                                        key: "value",
                                        values: [newRow.campusId],
                                      }
                                    : undefined
                                }
                                campusExists={newRow.campusExists}
                              />
                            }
                          </TableCell>
                          <TableCell>{newRow.description}</TableCell>
                        </TableRow>
                      )}
                      {loaderState ? (
                        <TableRow>
                          <StyledTableCell
                            colSpan={8}
                            className={classes.preLoaderCell}
                          >
                            <CircularProgress />
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        pagedAppSettings?.map(
                          (row: AppSettings2, index: number) => {
                            return (
                              <TableRow key={row.valueId}>
                                <TableCell>
                                  {editIndex !== index ? (
                                    row.campusSpecific !== false && (
                                      <Button
                                        startIcon={<Add />}
                                        onClick={() => {
                                          handleAddIconClick(row.settingId);
                                        }}
                                      />
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {editIndex === index ? (
                                    <>
                                      <Button
                                        startIcon={<CheckIcon />}
                                        onClick={async () => {
                                          await handleEdit2(
                                            row.settingId,
                                            row.valueId,
                                            row.value,
                                            row.campusId
                                          );
                                          setEditIndex(null);
                                        }}
                                      />
                                      <Button
                                        startIcon={<CancelIcon />}
                                        onClick={() => {
                                          setEditIndex(null);
                                          if (
                                            originalValues[row.valueId] !==
                                            undefined
                                          ) {
                                            row.value =
                                              originalValues[row.valueId];
                                          }
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <Button
                                      startIcon={<EditIcon />}
                                      onClick={() => {
                                        setEditIndex(index);
                                        setOriginalValues((prev) => ({
                                          ...prev,
                                          [row.valueId]: row.value,
                                        }));
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {editIndex !== index ? (
                                    <Button
                                      startIcon={<DeleteIcon />}
                                      onClick={(event) =>
                                        handleOpenPopOver(
                                          event,
                                          row.settingId,
                                          row.valueId,
                                          row.campusId
                                        )
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                                <TableCell className={classes.tableColumn}>
                                  {row.keyName}
                                </TableCell>
                                <TableCell className={classes.tableColumn}>
                                  {editIndex === index ? (
                                    // --- EDIT MODE ---
                                    row.valueOption &&
                                    row.valueOption.length > 0 ? (
                                      <select
                                        value={row.value}
                                        onChange={(e) =>                               
                                          handleModelChange(
                                            "value",
                                            e.target.value,
                                            row.valueId
                                          )
                                        }
                                      >
                                        {row.valueOption.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      <InputField
                                        type={InputType.TEXT}
                                        key="value"
                                        id="value"
                                        label=""
                                        name="value"
                                        value={row.value || ""}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          handleModelChange(
                                            "value",
                                            e.target.value,
                                            row.valueId
                                          );
                                        }}
                                      />
                                    )
                                  ) : (
                                    // --- READ-ONLY MODE ---
                                    row.value
                                  )}
                                </TableCell>
                                <TableCell>{row.campusName}</TableCell>
                                {/* --keep */}
                                {/* <TableCell>
                                  {" "}
                                  {editIndex === index ? (
                                    <CampusWithExclusionAutoComplete
                                      id="campusId"
                                      name="campusId"
                                      label=""
                                      // label="Campus(es) *"
                                      // multiple
                                      // includeAllValue={true}
                                      // showIncludeAllValue={true}

                                      filterHandle={(v: any) => {
                                        console.log(
                                          "row.CampusExists",
                                          row.campusExists
                                        );
                                        console.log(
                                          "row.CampuName",
                                          row.campusName
                                        );
                                        handleModelChange(
                                          "campusId",
                                          v.value,
                                          index,
                                          row.campusId
                                        );
                                      }}
                                      valueFilter={
                                        row.campusId
                                          ? {
                                              key: "value",
                                              values: [row.campusId],
                                            }
                                          : undefined
                                      }
                                      campusExists={row.campusExists}
                                    />
                                  ) : (
                                    row.campusName
                                  )}
                                </TableCell> */}
                                <TableCell>{row.description}</TableCell>
                              </TableRow>
                            );
                          }
                        )
                      )}
                    </TableBody>
                  </Table>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ marginTop: 10 }}>
                      <Pagination
                        count={numPages}
                        page={page + 1}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TablePagination
                        component="div"
                        count={filteredAppSettings.length} //keep
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 20, 50]}
                      />
                    </Grid>
                  </Grid>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>

          <Popover
            id={popOverId}
            open={popOverOpen}
            anchorEl={popOverAnchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              color="textSecondary"
              className={classes.typography}
              variant="body1"
            >
              Are you sure you want to delete this campus-specific setting?
            </Typography>
            <div className={classes.popOverButtons}>
              <Button
                variant="contained"
                size="small"
                onClick={handleConfirmDelete}
                color="primary"
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleClosePopOver}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </Popover>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ConfigAppSettings;
