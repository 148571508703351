export interface ICampusDetails {
    id: string;
    code: string;
    statusId: string;
    opeid?: string;
    schoolName: string;
    campDescrip: string;
    isBranch: boolean;
    cmsId?: string;
    address?: string;
    address2?: string;
    city?: string;
    stateId?: string;
    zipCode?: string;
    countryId?: string;
    timeZoneId?: number;
    // phones
    phone1?: string;
    phone2?: string;
    phone3?: string;
    fax?: string;
    // contact information
    email?: string;
    website?: string;
    // Transcript Authorization
    transcriptAuthZnTitle?: string;
    transcriptAuthZnName?: string;
    // Time Clock Information
    isRemoteServer?: boolean;
    tcsourcePath?: string;
    tctargetPath?: string;
    remoteServerUsrNm?: string;
    remoteServerPwd?: string;
    // FAMELink Information
    isRemoteServerFl?: boolean;
    flsourcePath?: string;
    fltargetPath?: string;
    flexceptionPath?: string;
    remoteServerUsrNmFl?: string;
    remoteServerPwdFl?: string;
    // Import Leads Information
    isRemoteServerIl?: boolean;
    ilsourcePath?: string;
    ilarchivePath?: string;
    remoteServerUserNameIl?: string;
    remoteServerPasswordIl?: string;
    // Invoice Address
    useCampusAddress?: boolean;
    invAddress1?: string;
    invAddress2?: string;
    invCity?: string;
    invStateId?: string;
    invZip?: string;
    invCountryId?: string;
    invPhone1?: string;
    invFax?: string;
    // 1098T Information
    token1098Tservice?: string;
    schoolCodeKissSchoolId?: string;
    // Financial Information
    fseogmatchType?: string;
    syncNonTitleIv?: boolean;

}

export class CampusDetails implements ICampusDetails {
    id: string = "";
    code: string = "";
    statusId: string = "";
    opeid?: string = "";
    schoolName: string = "";
    campDescrip: string = "";
    isBranch: boolean = false;
    cmsId?: string = "";
    address?: string = "";
    address2?: string = "";
    city?: string = "";
    stateId?: string = "";
    zipCode?: string = "";
    countryId?: string = "";
    timeZoneId?: number = 0;

    // Phones
    phone1?: string = "";
    phone2?: string = "";
    phone3?: string = "";
    fax?: string = "";

    // Contact Information
    email?: string = "";
    website?: string = "";

    // Transcript Authorization
    transcriptAuthZnTitle?: string = "";
    transcriptAuthZnName?: string = "";

    // Time Clock Information
    isRemoteServer?: boolean = false
    tcsourcePath?: string = "";
    tctargetPath?: string = "";

    // FAMELink Information
    isRemoteServerFl?: boolean = false;
    flsourcePath?: string = "";
    fltargetPath?: string = "";
    flexceptionPath?: string = "";

    // Import Leads Information
    isRemoteServerIl?: boolean = false;
    ilsourcePath?: string = "";
    ilarchivePath?: string = "";

    // Invoice Address
    useCampusAddress?: boolean = false;
    invAddress1?: string = "";
    invAddress2?: string = "";
    invCity?: string = "";
    invStateId?: string = "";
    invZip?: string = "";
    invCountryId?: string = "";
    invPhone1?: string = "";
    invFax?: string = "";

    // 1098T Information
    token1098Tservice?: string = "";
    schoolCodeKissSchoolId?: string = "";

    // Financial Information
    fseogmatchType?: string = "";
    syncNonTitleIv?: boolean = false;
}
