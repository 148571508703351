import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Switch
} from '@material-ui/core';
import { useForm, FormContext, FieldError } from 'react-hook-form';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import { ICampusDetails, CampusDetails } from '../../../interfaces/setup/generalSetup/ICampusDetails';
import { ThemeTextField } from '../../../components/_Layout/Inputs/ThemeTextField';
import StatusAutoComplete from '../../../components/AutoComplete/StatusAutoComplete';
import { useEffect, useState } from 'react';
import StatesAutoComplete from '../../../components/AutoComplete/StatesAutoComplete';
import CountryAutoComplete from '../../../components/AutoComplete/CountryAutoComplete';
import { useSelector } from 'react-redux';
import * as campusManagementApi from "../../../api/setup/generalSetup/campusManagementApi";
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import { SnackBarStateProps } from '../../student/profile/studentInfo/StudentInfo';
import TimeZoneAutoComplete from '../../../components/AutoComplete/TimeZoneAutoComplete';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            // paddingTop: theme.spacing(1),
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.paper,
            flexDirection: 'column',
            borderRadius: theme.shape.borderRadius,
        },
        card: {
            margin: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
        },
        cardContent: {
            padding: '10px',
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontWeight: 600,
            paddingRight: theme.spacing(2),
        },
        dropdown: {
            marginLeft: '8px',
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
        switch: {
            display: "flex",
            alignItems: "flex",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
        actionContainer: {
            marginTop: theme.spacing(3),
            display: 'flex',
            gap: theme.spacing(2),
        },
    })
);


const Campuses = (props: any) => {
    const classes = useStyles();

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [loaderState, setLoaderState] = React.useState<boolean>(false);
    const [snackBarProps, setSnackBarProps] = React.useState<SnackBarStateProps>({
        showSnackBar: false,
        messageInfo: undefined,
        variant: "info",
    });

    const [model, setModel] = useState<ICampusDetails>(new CampusDetails());
    const [selectedCountry, setSelectedCountry] = useState<string>("");

    const data = useForm<any>({ mode: "onBlur" });

    const { handleSubmit } = data;
    const onSubmit = () => {
        if (model) {
            setLoaderState(true);
            campusManagementApi.AddOrUpdateCampus(model).then(
                (response: any) => {
                    setLoaderState(false);
                    if (response.resultStatus === 0) {
                        setSnackBarProps({
                            variant: "success",
                            showSnackBar: true,
                            messageInfo: "Campus successfully updated.",
                        });
                    } else {
                        setSnackBarProps({
                            variant: "error",
                            showSnackBar: true,
                            messageInfo: "Campus update could not be completed. Please try again.",
                        });
                    }
                },
                (exception: any) => {
                    setLoaderState(false);
                    setSnackBarProps({
                        variant: "error",
                        showSnackBar: true,
                        messageInfo: exception.message || "An error occurred while updating the campus.",
                    });
                }
            );
        } else if (data.errors.ssn) {
            data.setError("ssn", {
                type: "manual",
                message: (data.errors.ssn as FieldError).message,
            });
        }
    };

    const onReset = () => {
        setModel(new CampusDetails());

        setSelectedCountry("");

        data.reset();
    }


    const getCAmpusInformation = async () => {
        try {
            const response = await campusManagementApi.GetCampusById(userSelectedCampus);

            if (response?.result) {
                response.result.phone1 = PhoneValidation(response.result?.phone1.trim());
                response.result.phone2 = PhoneValidation(response.result?.phone2.trim());
                response.result.phone3 = PhoneValidation(response.result?.phone3.trim());
                setSelectedCountry(response.result.countryId)
                setModel(response.result);
                console.log("Campus details Model", response.result); // Log after setting state
            } else {
                props.setSnackBar((prev: any) => ({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response?.resultStatusMessage || "An error occurred",
                }));
            }
        } catch (exception) {
            props.setSnackBar((prev: any) => ({
                variant: "error",
                showSnackBar: true,
                messageInfo: exception || "An unexpected error occurred",
            }));
        }
    };

    React.useEffect(() => {
        if (userSelectedCampus) {
            getCAmpusInformation();
        }
    }, [userSelectedCampus]);

    const onValidation = (fieldId: string, value: any) => {
        if (data.errors && (model as any)[fieldId] != value) {
            handleFieldChange(fieldId, value);
        }
    };

    const CityValidation = (errorName: any) => {
        if (errorName?.type === "validate") {
            return "City is Required.";
        } else return undefined;
    };

    const StateValidation = (errorName: any) => {
        if (errorName?.type === "validate") {
            return "State is Required.";
        } else return undefined;
    };

    const ZipValidation = (errorName: any) => {
        if (errorName?.type === "validate") {
            return !model.zipCode
                ? 'Zip is Required.' : "The correct format of Zip is 5 digits or 5digits-4digits.";
        } else return null;
    };

    const CountryValidation = (errorName: any) => {
        if (errorName?.type === "validate") {
            return "Country is Required.";
        } else return undefined;
    };

    const PhoneValidation = (value: string) => {
        if (!value) return "";

        const cleaned = value.replace(/\D/g, "");

        if (cleaned.length > 10) {
            return "";
        }

        return `(${cleaned.slice(0, 3)})-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    };

    const EmailValidation = (errorName: any) => {
        if (errorName?.type === "validate") {
            return "Email is not in correct format.";
        } else return null;
    };

    const handleFieldChange = (fieldId: string, value: any, text?: any) => {
        let updatedModel = model;
        (updatedModel as any)[fieldId] = value;

        setModel({ ...updatedModel });
    };

    return (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Campus" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="code"
                                            name="code"
                                            className={classes.textField}
                                            label="Code *"
                                            value={model.code}
                                            error={!!data.errors.code}
                                            inputRef={data.register({
                                                validate: {
                                                    valid: (value) => {
                                                        onValidation("code", value);
                                                        return value !== "" || "Required";
                                                    },
                                                },
                                            })}
                                            helperText={
                                                data.errors.code
                                                    ? "Code is required"
                                                    : null
                                            }
                                            onChange={(e: any) => {
                                                handleFieldChange("code", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <StatusAutoComplete
                                            id="statusId"
                                            name="statusId"
                                            label="Status *"
                                            valueFilter={
                                                model.statusId
                                                    ? {
                                                        key: "value",
                                                        values: [model.statusId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("statusId", v ? v.value : null);
                                            }}
                                            error={!!data.errors.statusId}
                                            inputRef={data.register({ required: true })}
                                            helperText={
                                                data.errors.statusId ? "Status is required." : undefined
                                            }
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="opeid"
                                            name="opeid"
                                            className={classes.textField}
                                            label="OPE ID"
                                            value={model.opeid || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("opeid", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="schoolName"
                                            name="schoolName"
                                            className={classes.textField}
                                            label="School Name *"
                                            value={model.schoolName || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("schoolName", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="campDescrip"
                                            name="campDescrip"
                                            className={classes.textField}
                                            label="Campus Name *"
                                            value={model.campDescrip || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("campDescrip", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <InputLabel>Is a branch *</InputLabel>
                                        <Select
                                            name="isBranch"
                                            defaultValue="No"
                                            inputRef={data.register({ required: true })}
                                            error={!!data.errors.isBranch}
                                            fullWidth
                                        >
                                            <MenuItem value="Yes">Yes</MenuItem>
                                            <MenuItem value="No">No</MenuItem>
                                        </Select>
                                        {data.errors.isBranch && (
                                            <Typography color="error">{data.errors.isBranch.message}</Typography>
                                        )}
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="cmsId"
                                            name="cmsId"
                                            className={classes.textField}
                                            label="CMS ID"
                                            value={model.cmsId || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("cmsId", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="address"
                                            name="address"
                                            className={classes.textField}
                                            label="Address 1"
                                            value={model.address || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("address", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="address2"
                                            name="address2"
                                            className={classes.textField}
                                            label="Address 2"
                                            value={model.address2 || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("address2", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="City"
                                            name="City"
                                            className={classes.textField}
                                            label="City *"
                                            margin="none"
                                            value={model.city ?? ""}
                                            onChange={(v: any) => {
                                                handleFieldChange("city", v ? v.target.value : undefined);
                                            }}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    handleFieldChange("city", value);
                                                    return value.trim().length > 0;
                                                },
                                            })}
                                            helperText={CityValidation(data.errors.City)}
                                            error={!!data.errors.City}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12}>
                                        <StatesAutoComplete
                                            id="stateId"
                                            name="stateId"
                                            label={selectedCountry === 'USA' || !selectedCountry ? 'State *' : 'State'}
                                            countryId={model.countryId}
                                            valueFilter={
                                                model && model.stateId
                                                    ? {
                                                        key: "value",
                                                        values: [model.stateId.toLowerCase()],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("stateId", v ? v.value : undefined);
                                            }}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    return (selectedCountry === 'USA' || !selectedCountry ? value.trim().length > 0 : true);
                                                },
                                            })}
                                            helperText={StateValidation(data.errors.stateId)}
                                            error={!!data.errors.stateId}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="zipCode"
                                            name="zipCode"
                                            className={classes.textField}
                                            label="Zip Code *"
                                            margin="none"
                                            value={model.zipCode ?? ""}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    handleFieldChange("zipCode", value);
                                                    return value.trim().length > 0 && /^(\d{5}|\d{5}-\d{4})$/.test(value);
                                                },
                                            })}
                                            helperText={ZipValidation(data.errors.zipCode)}
                                            error={!!data.errors.zip}
                                            onChange={(v: any) => {
                                                handleFieldChange("zipCode", v ? v.target.value : undefined);
                                            }}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12} className={classes.dropdown}>
                                        <CountryAutoComplete
                                            filterHandle={(v: any) => {
                                                handleFieldChange("countryId", v ? v.value : undefined);
                                                setSelectedCountry(v ? v.text : undefined);
                                                data.clearError('States')
                                            }}
                                            id="countryId"
                                            name="countryId"
                                            valueFilter={
                                                model && model.countryId
                                                    ? {
                                                        key: "value",
                                                        values: [model.countryId.toLocaleLowerCase()],
                                                    }
                                                    : undefined
                                            }
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    return value.trim().length > 0;
                                                },
                                            })}
                                            helperText={CountryValidation(data.errors.countryId)}
                                            error={!!data.errors.countryId}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12} className={classes.dropdown}>
                                        <TimeZoneAutoComplete
                                            id="timeZoneId"
                                            label={"Time Zone *"}
                                            name="timeZoneId"
                                            valueFilter={
                                                model.timeZoneId
                                                    ? {
                                                        key: "value",
                                                        values: [model.timeZoneId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("timeZoneId", v ? v.value : null);
                                            }}
                                            error={!!data.errors.timeZoneId}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    return value.trim().length > 0;
                                                },
                                            })}
                                            helperText={
                                                data.errors.timeZoneId ? "Time Zone is required." : undefined
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Phones" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="phone1"
                                            name="phone1"
                                            className={classes.textField}
                                            label={"Phone 1*"}
                                            margin="none"
                                            value={model.phone1 ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("Phone")) {
                                                    handleFieldChange("phone1", formatted);
                                                } else {
                                                    handleFieldChange("phone1", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.phone1?.includes("Phone") ? model.phone1 : ""}
                                            error={model.phone1?.includes("Phone")}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="phone2"
                                            name="phone2"
                                            className={classes.textField}
                                            label={"Phone2"}
                                            margin="none"
                                            value={model.phone2 ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("Phone")) {
                                                    handleFieldChange("phone2", formatted);
                                                } else {
                                                    handleFieldChange("phone2", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.phone2?.includes("Phone") ? model.phone2 : ""}
                                            error={model.phone2?.includes("Phone")}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="phone3"
                                            name="phone3"
                                            className={classes.textField}
                                            label={"Phone 3"}
                                            margin="none"
                                            value={model.phone3 ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("Phone")) {
                                                    handleFieldChange("phone3", formatted);
                                                } else {
                                                    handleFieldChange("phone3", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.phone3?.includes("Phone") ? model.phone3 : ""}
                                            error={model.phone3?.includes("Phone")}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="fax"
                                            name="fax"
                                            className={classes.textField}
                                            label={"Fax"}
                                            margin="none"
                                            value={model.fax ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("fax")) {
                                                    handleFieldChange("fax", formatted);
                                                } else {
                                                    handleFieldChange("fax", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.fax?.includes("fax") ? model.fax : ""}
                                            error={model.fax?.includes("fax")}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Contact Information" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="email"
                                            name="email"
                                            className={classes.textField}
                                            label={"Email"}
                                            margin="none"
                                            value={model.email || ""}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    onValidation("email", value);
                                                    if (!value) return true;
                                                    else
                                                        return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*$/.test(
                                                            value
                                                        );
                                                },
                                            })}
                                            helperText={EmailValidation(data.errors.email)}
                                            error={!!data.errors.email || !!props.emailAlertMessage}
                                            onChange={(v: any) => {
                                                handleFieldChange("email", v ? v.target.value : undefined);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="website"
                                            name="website"
                                            className={classes.textField}
                                            label="Website"
                                            value={model.website || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("website", e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Transcript Authorization" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="transcriptAuthZnTitle"
                                            name="transcriptAuthZnTitle"
                                            className={classes.textField}
                                            label="Title"
                                            value={model.transcriptAuthZnTitle || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("transcriptAuthZnTitle", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="transcriptAuthZnName"
                                            name="natranscriptAuthZnNameme"
                                            className={classes.textField}
                                            label="Name"
                                            value={model.transcriptAuthZnName || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("transcriptAuthZnName", e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Time Clock Information" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={2} sm={2} xs={6}>
                                        <Typography>Remote Server</Typography>
                                    </Grid>
                                    <Grid item md={1} sm={2} xs={6} className={classes.dropdown}>
                                        <FormControlLabel
                                            id="isRemoteServer"
                                            className={classes.switch}
                                            control={<Switch
                                                checked={model.isRemoteServer}
                                                name="isRemoteServer"
                                                onChange={(e: any) => {
                                                    handleFieldChange("isRemoteServer", e.target.checked);
                                                }}
                                                color="primary" />
                                            }
                                            label={undefined} />
                                    </Grid>


                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="tcsourcePath"
                                            name="tcsourcePath"
                                            className={classes.textField}
                                            label="Import Path"
                                            value={model.tcsourcePath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("tcsourcePath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="tctargetPath"
                                            name="tctargetPath"
                                            className={classes.textField}
                                            label="Archive Path"
                                            value={model.tctargetPath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("tctargetPath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    {model.isRemoteServer && (
                                        <>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerUsrNm"
                                                    name="remoteServerUsrNm"
                                                    className={classes.textField}
                                                    label="Remote Server Username"
                                                    value={model.remoteServerUsrNm || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerUsrNm", e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerPwd"
                                                    name="remoteServerPwd"
                                                    className={classes.textField}
                                                    label="Remote Server Password"
                                                    type="password"
                                                    value={model.remoteServerPwd || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerPwd", e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="FAMELink Information" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={2} sm={2} xs={6}>
                                        <Typography>Remote Server</Typography>
                                    </Grid>
                                    <Grid item md={1} sm={2} xs={6} className={classes.dropdown}>
                                        <FormControlLabel
                                            id="isRemoteServerFl"
                                            className={classes.switch}
                                            control={<Switch
                                                checked={model.isRemoteServerFl}
                                                name="isRemoteServerFl"
                                                onChange={(e: any) => {
                                                    handleFieldChange("isRemoteServerFl", e.target.checked);
                                                }}
                                                color="primary" />} label={undefined} />
                                    </Grid>


                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="flsourcePath"
                                            name="flsourcePath"
                                            className={classes.textField}
                                            label="Import Path"
                                            value={model.flsourcePath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("flsourcePath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="fltargetPath"
                                            name="fltargetPath"
                                            className={classes.textField}
                                            label="Archive Path"
                                            value={model.fltargetPath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("fltargetPath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="flexceptionPath"
                                            name="flexceptionPath"
                                            className={classes.textField}
                                            label="Exception Path"
                                            value={model.flexceptionPath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("flexceptionPath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    {model.isRemoteServerFl && (
                                        <>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerUsrNmFl"
                                                    name="remoteServerUsrNmFl"
                                                    className={classes.textField}
                                                    label="Remote Server Username"
                                                    value={model.remoteServerUsrNmFl || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerUsrNmFl", e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerPwdFl"
                                                    name="remoteServerPwdFl"
                                                    className={classes.textField}
                                                    label="Remote Server Password"
                                                    type="password"
                                                    value={model.remoteServerPwdFl || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerPwdFl", e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Import Leads Information" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={2} sm={2} xs={6}>
                                        <Typography>Remote Server</Typography>
                                    </Grid>
                                    <Grid item md={1} sm={2} xs={6} className={classes.dropdown}>
                                        <FormControlLabel
                                            id="isRemoteServerIl"
                                            className={classes.switch}
                                            control={<Switch
                                                checked={model.isRemoteServerIl}
                                                name="isRemoteServerIl"
                                                onChange={(e: any) => {
                                                    handleFieldChange("isRemoteServerIl", e.target.checked);
                                                }}
                                                color="primary" />} label={undefined} />
                                    </Grid>


                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="ilsourcePath"
                                            name="ilsourcePath"
                                            className={classes.textField}
                                            label="Import Path"
                                            value={model.ilsourcePath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("ilsourcePath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="ilarchivePath"
                                            name="ilarchivePath"
                                            className={classes.textField}
                                            label="Archive Path"
                                            value={model.ilarchivePath || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("ilarchivePath", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    {model.isRemoteServerIl && (
                                        <>
                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerUserNameIl"
                                                    name="remoteServerUserNameIl"
                                                    className={classes.textField}
                                                    label="Remote Server Username"
                                                    value={model.remoteServerUserNameIl || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerUserNameIl", e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={3} sm={4} xs={12}>
                                                <ThemeTextField
                                                    id="remoteServerPasswordIl"
                                                    name="remoteServerPasswordIl"
                                                    className={classes.textField}
                                                    label="Remote Server Password"
                                                    type="password"
                                                    value={model.remoteServerPasswordIl || ""}
                                                    inputRef={data.register()}
                                                    onChange={(e: any) => {
                                                        handleFieldChange("remoteServerPasswordIl", e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Invoice Address" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={2} sm={2} xs={6}>
                                        <Typography>Use Campus Address</Typography>
                                    </Grid>
                                    <Grid item md={1} sm={2} xs={6} className={classes.dropdown}>
                                        <FormControlLabel
                                            id="useCampusAddress"
                                            className={classes.switch}
                                            control={<Switch
                                                checked={model.useCampusAddress}
                                                name="useCampusAddress"
                                                onChange={(e: any) => {
                                                    handleFieldChange("useCampusAddress", e.target.checked);
                                                }}
                                                color="primary" />} label={undefined} />
                                    </Grid>


                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invAddress1"
                                            name="invAddress1"
                                            className={classes.textField}
                                            label="Address 1"
                                            value={model.invAddress1 || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("invAddress1", e.target.value);
                                            }}
                                            disabled={model.useCampusAddress}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invAddress2"
                                            name="invAddress2"
                                            className={classes.textField}
                                            label="Address 2"
                                            value={model.invAddress2 || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("invAddress2", e.target.value);
                                            }}
                                            disabled={model.useCampusAddress}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invCity"
                                            name="invCity"
                                            className={classes.textField}
                                            label="City *"
                                            margin="none"
                                            value={model.invCity ?? ""}
                                            onChange={(v: any) => {
                                                handleFieldChange("invCity", v ? v.target.value : undefined);
                                            }}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    handleFieldChange("invCity", value);
                                                    return value.trim().length > 0;
                                                },
                                            })}
                                            helperText={CityValidation(data.errors.invCity)}
                                            error={!!data.errors.invCity}
                                            disabled={model.useCampusAddress}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12}>
                                        <StatesAutoComplete
                                            id="invStateId"
                                            name="invStateId"
                                            label={selectedCountry === 'USA' || !selectedCountry ? 'State *' : 'State'}
                                            countryId={model.countryId}
                                            valueFilter={
                                                model && model.invStateId
                                                    ? {
                                                        key: "value",
                                                        values: [model.invStateId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("invStateId", v ? v.value : undefined);
                                            }}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    return (selectedCountry === 'USA' || !selectedCountry ? value.trim().length > 0 : true);
                                                },
                                            })}
                                            helperText={StateValidation(data.errors.States)}
                                            error={!!data.errors.States}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invZip"
                                            name="invZip"
                                            className={classes.textField}
                                            label="Zip Code *"
                                            margin="none"
                                            value={model.invZip ?? ""}
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    handleFieldChange("invZip", value);
                                                    return value.trim().length > 0 && /^(\d{5}|\d{5}-\d{4})$/.test(value);
                                                },
                                            })}
                                            helperText={ZipValidation(data.errors.invZip)}
                                            error={!!data.errors.zip}
                                            onChange={(v: any) => {
                                                handleFieldChange("invZip", v ? v.target.value : undefined);
                                            }}
                                            disabled={model.useCampusAddress}
                                        />
                                    </Grid>

                                    <Grid md={3} sm={4} xs={12}>
                                        <CountryAutoComplete
                                            filterHandle={(v: any) => {
                                                handleFieldChange("invCountryId", v ? v.value : undefined);
                                                setSelectedCountry(v ? v.text : undefined);
                                            }}
                                            id="invCountryId"
                                            name="invCountryId"
                                            valueFilter={
                                                model && model.invCountryId
                                                    ? {
                                                        key: "value",
                                                        values: [model.invCountryId],
                                                    }
                                                    : undefined
                                            }
                                            inputRef={data.register({
                                                validate: (value: any) => {
                                                    return value.trim().length > 0;
                                                },
                                            })}
                                            helperText={CountryValidation(data.errors.invCountryId)}
                                            error={!!data.errors.invCountryId}
                                            disabled={model.useCampusAddress}
                                        />
                                    </Grid>



                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invPhone1"
                                            name="invPhone1"
                                            className={classes.textField}
                                            label={"Phone"}
                                            margin="none"
                                            value={model.invPhone1 ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("Phone")) {
                                                    handleFieldChange("invPhone1", formatted);
                                                } else {
                                                    handleFieldChange("invPhone1", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.invPhone1?.includes("Phone") ? model.invPhone1 : ""}
                                            error={model.invPhone1?.includes("Phone")}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="invFax"
                                            name="invFax"
                                            className={classes.textField}
                                            label={"invFax"}
                                            margin="none"
                                            value={model.invFax ?? ""}
                                            onChange={(e) => {
                                                const formatted = PhoneValidation(e.target.value);
                                                if (!formatted?.includes("Phone")) {
                                                    handleFieldChange("invFax", formatted);
                                                } else {
                                                    handleFieldChange("invFax", e.target.value); // Keep raw input if invalid
                                                }
                                            }}
                                            placeholder="(123)-456-7890"
                                            helperText={model.invFax?.includes("Phone") ? model.invFax : ""}
                                            error={model.invFax?.includes("Phone")}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="1098T Info" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="token1098Tservice"
                                            name="token1098Tservice"
                                            className={classes.textField}
                                            label="Token"
                                            value={model.token1098Tservice || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("token1098Tservice", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="schoolCodeKissSchoolId"
                                            name="schoolCodeKissSchoolId"
                                            className={classes.textField}
                                            label="Kiss Code"
                                            value={model.schoolCodeKissSchoolId || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("schoolCodeKissSchoolId", e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid spacing={2}>
                        <Card className={classes.card}>
                            <CustomCardTitle title="Financial Information" />
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <ThemeTextField
                                            id="fseogmatchType"
                                            name="fseogmatchType"
                                            className={classes.textField}
                                            label="FSEOG Match Type"
                                            value={model.fseogmatchType || ""}
                                            inputRef={data.register()}
                                            onChange={(e: any) => {
                                                handleFieldChange("fseogmatchType", e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={2} sm={2} xs={6}>
                                        <Typography>Sync Non Title IV Aid To AFA ?</Typography>
                                    </Grid>
                                    <Grid item md={1} sm={2} xs={6} className={classes.dropdown}>
                                        <FormControlLabel
                                            id="syncNonTitleIv"
                                            className={classes.switch}
                                            control={<Switch
                                                checked={model.syncNonTitleIv}
                                                name="syncNonTitleIv"
                                                onChange={(e: any) => {
                                                    handleFieldChange("syncNonTitleIv", e.target.checked);
                                                }}
                                                color="primary" />} label={undefined} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <div className={classes.actionContainer}>
                        <ProgressSaveButton
                            text="Save"
                            onClick={() => {
                                onSubmit();
                            }}
                            loading={loaderState}
                        ></ProgressSaveButton>

                        <ProgressSaveButton
                            text="Add New"
                            onClick={() => {
                                onReset();
                            }}
                            loading={false}
                        ></ProgressSaveButton>
                    </div>
                </form>
            </FormContext>
        </div>
    );
};

export default Campuses;
