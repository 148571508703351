import * as React from "react";
import { useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {
            width: "100%"
        },
        searchIcon: {
            fontSize: 30,
            paddingLeft: 10,
            color: theme.palette.icon.primary,
            cursor: "pointer"
        }
    })
);

const TimeZoneAutoComplete = (props: FilterParams) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [valueFilter, setValueFilter] = React.useState<any>(null);

    const campusOnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };
    const inputRef: any = useRef(null);

    return (
        <div className={clsx(classes.divWidth)}>
            <ApiAutoComplete
                id={props.id}
                loading={userSelectedCampus ? false : true}
                disabled={props.disabled ? true : false}
                config={apiAutoCompleteConfig.timeZone}
                classes={{
                    option: "text-black"
                }}
                label={props.label ?? ""}
                onChange={(e: any) => {
                    campusOnChange(e);
                }}
                disableClearable={true}
                multiple={props.multiple ? true : false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                includeAllValue={props.includeAllValue}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
            ></ApiAutoComplete>
        </div>
    );
};
export default TimeZoneAutoComplete;
