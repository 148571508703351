import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  CardContent,
  Card,
  Button,
  Paper,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import ChargeDefinitionGrid from "./ChargeDefinitionGrid";
import ChargeDefinitionDetail from "./ChargeDefinitionDetail";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import { useForm, FormContext, FieldError } from "react-hook-form";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { useSelector } from "react-redux";
import * as chargeDefinitionApi from "../../../api/setup/chargeDefinition/chargeDefinitionApi";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.paper.background,
      position:"relative"
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
    },

    cardTitle: {
      fontSize: 24,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(2, 0),
    },
    gridWrapper: {
     /*  width: "60%", */
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
      maxHeight: theme.spacing(100),
      width: "100%",
      minHeight: "50%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
  })
);

function ChargeDefinitionSetup() {
  const classes = useStyles();
  const [isCreatingNew, setIsCreatingNew] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [definitionGridData, setDefinitionGridData] = React.useState([] as any);
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const [chargingDefinitionId, setChargingDefinitionId] =
    React.useState<any>(null);

  const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  interface ListItem {
    value: number;
    text: string;
  }
  const optionList = useSelector(
    (state: any) => state.ac_cache?.data?.statuses?.options ?? []
  ) as ListItem[];

  const chargingDefinitionModelDefault: ChargeDefinition = {
    chargeDefinitionId: null,
    chargeDefinitionDescription: "",
    statusId: null,
    campusId: campusId,
    periodTypeId: "",
    incrementTypeId: "",
    chargeDefinitionVersions: new Array<ChargeDefinitionVersion>(),
    chargeDefinitionProgramVersions: new Array<string>(),
  };

  const [workingChargeDefinitionModel, setWorkingChargeDefinitionModel] =
    React.useState<ChargeDefinition>(chargingDefinitionModelDefault);

        const [saveDefinitionIdStatus, setSaveDefinitionIdStatus] =
        React.useState<boolean>(false);

  const newChargingDefinition = () => {
    setWorkingChargeDefinitionModel(chargingDefinitionModelDefault);
    setIsCreatingNew(true);
  };

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  React.useEffect(() => {
    if (campusId) {
      closeChargingDefinition();
      loadChargeDefinition();
    }
  }, [campusId]);

  const loadChargeDefinition = () => {
    chargeDefinitionApi.getChargeDefinitionList(campusId).then(
      (response: any) => {
        if (response) {
          setDefinitionGridData(response.data);
        }
      },
      (exception: any) => { }
    );
  };

  const saveChargingDefinition = () => {
    methods.triggerValidation().then((validation: any) => {
      if (validation == true) {
        setLoading(true);
        chargeDefinitionApi
          .UpsertChargeDefinition(workingChargeDefinitionModel)
          .then(
            (response: any) => {
              if (response.resultStatus === 0) {
                loadChargeDefinition();
                setWorkingChargeDefinitionModel(response.result);
                setSaveDefinitionIdStatus(true);
                setSnackBarProps((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                setSnackBarProps((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
              setLoading(false);
            },
            (exception: any) => {
              setSnackBarProps((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
              setLoading(false);
            }
          );
      }
    });
  };

  const editChargingDefinition = (definitionId: any) => {
    chargeDefinitionApi.getChargeDefinitionById(definitionId).then(
      (response: any) => {
        if (response && response.data) {
          closeChargingDefinition();
          setWorkingChargeDefinitionModel({
            ...response.data,
          });
          setChargingDefinitionId(response.data.chargeDefinitionId);
        }
      },
      (exception: any) => { }
    );
  };

  const closeChargingDefinition = () => {
    setChargingDefinitionId(null);
    setIsCreatingNew(false);
  };
  
  return (
    <Paper className={classes.root} square={true}>
      <FormContext {...methods}>
        <Card square={true} className={classes.cardWrapper}>
          <CustomCardTitle title={"Charge Definition"} />
          <CardContent>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <div className={classes.gridWrapper}>
                  <ChargeDefinitionGrid
                    editChargingDefinition={editChargingDefinition}
                    closeChargingDefinition={closeChargingDefinition}
                    definitionGridData={definitionGridData}
                    campusId={campusId}
                  ></ChargeDefinitionGrid>
                </div>
              </Grid>

              <Grid item xs={12}>
                {chargingDefinitionId === null && !isCreatingNew && (
                  <AccessManager
                    allowedPermissions={[
                      security.permissions.maintenance.UpdateChargeDefinition,
                    ]}
                    renderNoAccess={() => (
                      <Button
                        disabled={true}
                        className={classes.buttonColorPrimary}
                        onClick={() => {
                          newChargingDefinition();
                        }}
                        size="small"
                        type="button"
                        color="primary"
                      >
                        Add New
                      </Button>
                    )}
                  >
                    <Button
                      className={classes.buttonColorPrimary}
                      onClick={() => {
                        newChargingDefinition();
                      }}
                      size="small"
                      type="button"
                    >
                      Add New
                    </Button>
                  </AccessManager>
                )}
              </Grid>

              <Grid item xs={12}>
                {(chargingDefinitionId != null || isCreatingNew) && (
                  <ChargeDefinitionDetail
                    model={workingChargeDefinitionModel}
                    setModel={setWorkingChargeDefinitionModel}
                    isCreatingNew={isCreatingNew}
                    chargingDefinitionId={chargingDefinitionId}
                    newChargingDefinition={newChargingDefinition}
                    closeChargingDefinition={closeChargingDefinition}
                    saveChargingDefinition={saveChargingDefinition}
                    setLoading={setLoading}
                    loading={loading}
                    saveDefinitionIdStatus={saveDefinitionIdStatus}
                    setSaveDefinitionIdStatus={setSaveDefinitionIdStatus}
                    setSnackBarProps={setSnackBarProps}
                  ></ChargeDefinitionDetail>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </FormContext>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
    </Paper>
  );
}
export default ChargeDefinitionSetup;
