import { Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Button,
} from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import { ThemeTextField } from "../../../components/_Layout/Inputs/ThemeTextField";
import {
  ChargeDefinitionVersion,
  ChargeDefinitionPeriodsDef,
} from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import IncrementDetailsGrid from "./IncrementDetailsGrid";
import React from "react";
import { CreateNewGuid } from "../../../utils/constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    cardWrapper: {
      height: "100%",
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    switch: {
      marginLeft: "0px",
    },
    incrementRow: {
      marginBottom: theme.spacing(1),
    },
    label: {
      paddingTop: theme.spacing(2),
    },
    boxMargin: {
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(4),
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
  })
);

type IncrementDetailsCardProps = {
  model: ChargeDefinitionVersion;
  setModel: (model: ChargeDefinitionVersion) => void;
  isEditable: boolean;
  methods: any;
  readonly?: boolean;
  chargingDefinitionId?: any;
  cancelDefinitionIdStatus?:any;
  setCancelDefinitionIdStatus?:any;
};

const IncrementDetailsCard = (props: IncrementDetailsCardProps) => {
  const setDetails = (newValue: any) => {
    handleFieldChange("details", newValue);
  };
  const classes = useStyles({});
  const { model, setModel } = props;
  const data = useForm<any>({ mode: "onBlur" });
  const [prevChargingDefinitionId, setPrevChargingDefinitionId] =
    React.useState(props.chargingDefinitionId);
  const handleAddNewPeriod = () => {
    let modelToUpdate = { ...model };

    let newRow = new ChargeDefinitionPeriodsDef();
    newRow.incrementId = CreateNewGuid();
    newRow.chargeDefinitionVersionId =
      props.model.chargeDefinitionVersionId ?? CreateNewGuid();
    newRow.hours = 0;
    newRow.options = null;
    newRow.isEditModeOn = false;
    newRow.scheduledHours = 0;
    newRow.incrementValue = 0;
    newRow.transCodeId = "";
    let arrayUpdated = modelToUpdate.periods ?? [];
    arrayUpdated?.push(newRow);

    modelToUpdate.periods = [...arrayUpdated];
    setModel(modelToUpdate);
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    let modelToUpdate = model;
    (modelToUpdate as any)[fieldId] = value;
    setModel({ ...modelToUpdate });
    props.methods.triggerValidation();
  };

  const asOfDateTypes: Array<DropDownListItem> = [
    { text: "Start Date", id: "StartDate" },
    { text: "Enrollment Date", id: "EnrollmentDate" },
  ];

  const asOfDateTypeAutoComplete = {
    options: asOfDateTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    if (
      (fieldId == "reportType" ||
        fieldId == "exportType" ||
        fieldId == "asOfDateType") &&
      value == undefined
    )
      return;

    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "campusId") {
      (updatedModel as any)["programId"] = [];
      (updatedModel as any)["leadGroupIds"] = [];
    }
    setModel({ ...updatedModel });
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  React.useEffect(() => {
    if (prevChargingDefinitionId !== props.chargingDefinitionId || props.cancelDefinitionIdStatus == true) {
      setModel({
        chargeDefinitionVersionId: model.chargeDefinitionVersionId,
        chargeDefinitionId: model.chargeDefinitionId,
        effectiveDate: null,
        description: "",
        asOfDateType: "StartDate",
        periods: [],
        excAbsencesPercent: 0,
        totalChargeAmount: 0,
      });
      setPrevChargingDefinitionId(props.chargingDefinitionId);
      props.setCancelDefinitionIdStatus(false);
    }
  }, [
    props.chargingDefinitionId,
    prevChargingDefinitionId,
    model.chargeDefinitionVersionId,
    model.chargeDefinitionId,
    setModel,
    props.cancelDefinitionIdStatus,
  ]);

  return (
    <Fragment>
      <FormContext {...props.methods}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                clearable
                format={"MM/dd/yyyy"}
                placeholder="mm/dd/yyyy"
                id="description"
                name="description"
                key="description"
                label="Effective Date *"
                readOnly={props.readonly == true ? true : false}
                TextFieldComponent={ThemeTextField}
                className={classes.datePickerField}
                value={
                  model && model.effectiveDate
                    ? new Date(model.effectiveDate).toLocaleDateString()
                    : null
                }
                onChange={(value: any) => {
                  handleFieldChange("effectiveDate", value ? value : undefined);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
                disabled={!props.isEditable}
                //required
                error={!!props.methods.errors["effectiveDate"]}
                innerRef={(ref: any) => {
                  props.methods.register("effectiveDate", {
                    required: true,
                  });
                  props.methods.setValue("effectiveDate", model.effectiveDate);
                }}
                helperText={
                  props.methods.errors.effectiveDate
                    ? "Effective Date is required."
                    : undefined
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={4} sm={3} xs={12}>
            <Autocomplete
              {...asOfDateTypeAutoComplete}
              autoComplete
              includeInputInList
              disabled={props.readonly == true ? true : false}
              onChange={(e: any, value: any) => {
                handleFieldOnChange(
                  "asOfDateType",
                  value ? value : undefined,
                  (v: any) => v?.id
                );
              }}
              value={getSelectedItem(
                asOfDateTypes,
                model.asOfDateType.toString()
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="As Of"
                  name="asOfDateType"
                  error={!!data.errors.asOfDateType}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.asOfDateType
                      ? "As Of Date Type is required."
                      : undefined
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ThemeTextField
              id="description"
              label="Description *"
              name="description"
              className={classes.fullWidthField}
              onChange={(e: any) => {
                handleFieldChange(
                  "description",
                  e ? e.target.value : undefined
                );
              }}
              value={model ? model.description : ""}
              disabled={props.readonly || !props.isEditable}
              error={!!props.methods.errors["description"]}
              innerRef={(ref: any) => {
                props.methods.register("description", {
                  required: true,
                });
                props.methods.setValue("description", model.effectiveDate);
              }}
              helperText={
                props.methods.errors.description
                  ? "Description is required."
                  : undefined
              }
            />
          </Grid>
          {props.isEditable && (
            <Grid item container direction="row" spacing={4} xs={12}>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="button"
                  disabled={props?.readonly == true ? true : false}
                  onClick={handleAddNewPeriod}
                >
                  Add Increment
                </Button>
              </Grid>
            </Grid>
          )}
          <IncrementDetailsGrid
            details={model.periods ? model.periods : []}
            isEditable={props.isEditable}
            //setModel={setDetails}
            setModel={(newDetails: any) =>
              setModel({ ...model, periods: newDetails })
            }
            readonly={props.readonly}
          />
        </Grid>
      </FormContext>
    </Fragment>
  );
};

export default IncrementDetailsCard;
