import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import { ITransactionCode } from "../../interfaces/setup/financial/ITransactionCode";
import { ThemeTextField } from "../../components/_Layout/Inputs/ThemeTextField";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as TransactionCodeApi from "../../api/setup/financial/transactionCode";
import { EmptyGuid } from "../../utils/constants";
import ConfirmationModal from "../../components/_Layout/Modal/ConfirmationModal";
import StatusAutoComplete from "../../components/AutoComplete/StatusAutoComplete";
import CampusGroupAutoComplete from "../../components/AutoComplete/CampusGroupAutoComplete";
import SystemTransactionCodeAutoComplete from "../../components/AutoComplete/SystemTransactionCodeAutoComplete";
import BillTypeAutoComplete from "../../components/AutoComplete/BillTypeAutoComplete";
import CreditDebitAutoComplete from "../../components/AutoComplete/CreditDebitAutoComplete";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    saveButton: {
      width: 90,
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

type TransactionCodeDetailsProps = {
  model: ITransactionCode;
  setModel: any;
  isDirty: boolean;
  setDirty: any;
  confirmationPopupState: any;
  setConfirmationPopupState: any;
  setSnackBar: any;
  refreshTransactionCodes: any;
  clearFields: any;
  resetChanges: any;
  campusId: string;
};
const TransactionCodeDetails = (props: TransactionCodeDetailsProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isDirty, setDirty } = props;
  const { confirmationPopupState, setConfirmationPopupState } = props;

  const handleCancel = () => {
    if (isDirty == true) {
      setConfirmationPopupState((state: any) => {
        return {
          ...state,
          open: true,
          confirmationText: "Are you sure you want to discard your changes?",
          confirmationButton: "Yes",
          title: "Discard Changes?",
          onConfirmEvent: () => {
            setDirty(false);
            if (model.transCodeId === EmptyGuid) {
              props.clearFields();
            } else {
              props.resetChanges();
            }
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
          onCancelEvent: () => {
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
        };
      });
    } else {
      props.clearFields();
    }
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;

  const handleFieldChange = (fieldId: string, value: any) => {
    if ((model as any)[fieldId] != value) {
      setDirty(true);
    }
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);
  };

  const onSubmit = (d: any) => {
    if (model) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);
          setDirty(false);
          TransactionCodeApi.UpsertTransactionCode(model).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                props.refreshTransactionCodes();
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
              setLoading(false);
            },
            (exception: any) => {
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
              setLoading(false);
            }
          );
        } else {
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Please fill the required values.",
            };
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(loading && (
            <div className={classes.preLoader}>
              <CircularProgress />
            </div>
          )) || (
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="transCodeCode"
                    label="Code *"
                    name="transCodeCode"
                    className={classes.fullWidthField}
                    value={model.transCodeCode ? model.transCodeCode : ""}
                    onChange={(e: any) => {
                      handleFieldChange("transCodeCode", e.target.value);
                    }}
                    error={!!data.errors.transCodeCode}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.transCodeCode
                        ? "Code is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <StatusAutoComplete
                    id="statusId"
                    name="statusId"
                    label="Status *"
                    valueFilter={
                      model.statusId
                        ? {
                            key: "value",
                            values: [model.statusId],
                          }
                        : undefined
                    }
                    filterHandle={(v: any) => {
                      handleFieldChange("statusId", v ? v.value : null);
                    }}
                    error={!!data.errors.statusId}
                    inputRef={data.register({
                      required: "Status is required.",
                    })}
                    helperText={
                      data.errors.statusId
                        ? (data.errors.statusId as FieldError).message
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="transCodeDescrip"
                    label="Description *"
                    name="transCodeDescrip"
                    className={classes.fullWidthField}
                    value={model.transCodeDescrip ? model.transCodeDescrip : ""}
                    onChange={(e: any) => {
                      handleFieldChange("transCodeDescrip", e.target.value);
                    }}
                    error={!!data.errors.transCodeDescrip}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.transCodeDescrip
                        ? "Description is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <CampusGroupAutoComplete
                    id="campGrpId"
                    label="Campus Group *"
                    name="campGrpId"
                    campusId={props.campusId}
                    valueFilter={
                      model?.campGrpId
                        ? {
                            key: "value",
                            values: [model?.campGrpId],
                          }
                        : undefined
                    }
                    filterHandle={(v: any) => {
                      handleFieldChange("campGrpId", v ? v.value : null);
                    }}
                    error={!!data.errors.campGrpId}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.campGrpId
                        ? "Campus group is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <SystemTransactionCodeAutoComplete
                    id="sysTransCodeId"
                    name="sysTransCodeId"
                    label="System Transaction Code *"
                    key={"sysTransCodeId"}
                    params={{
                      fullWidth: true,
                    }}
                    filterHandle={(v: any) => {
                      handleFieldChange(
                        "sysTransCodeId",
                        v ? v.value : undefined
                      );
                    }}
                    valueFilter={
                      model.sysTransCodeId
                        ? {
                            key: "value",
                            values: [model.sysTransCodeId],
                          }
                        : undefined
                    }
                    error={!!data.errors.sysTransCodeId}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.sysTransCodeId
                        ? "System Transaction code is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <BillTypeAutoComplete
                    id="billTypeId"
                    name="billTypeId"
                    label="Bill Type *"
                    key={"billTypeId"}
                    params={{
                      fullWidth: true,
                    }}
                    filterHandle={(v: any) => {
                      handleFieldChange("billTypeId", v ? v.value : undefined);
                    }}
                    valueFilter={
                      model.billTypeId
                        ? {
                            key: "value",
                            values: [model.billTypeId],
                          }
                        : undefined
                    }
                    error={!!data.errors.billTypeId}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.billTypeId
                        ? "Bill type is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <CreditDebitAutoComplete
                    id="creditDebitId"
                    name="creditDebitId"
                    label="CreditDebit *"
                    key={"creditDebitId"}
                    params={{
                      fullWidth: true,
                    }}
                    filterHandle={(v: any) => {
                      handleFieldChange(
                        "creditDebitId",
                        v ? v.value : undefined
                      );
                    }}
                    valueFilter={
                      model.creditDebitId
                        ? {
                            key: "value",
                            values: [model.creditDebitId],
                          }
                        : undefined
                    }
                    error={!!data.errors.creditDebitId}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.creditDebitId
                        ? "CreditDebit type is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                  <FormControlLabel
                    label="Is Institutional Charge?"
                    labelPlacement="start"
                    key={"isInstCharge"}
                    control={
                      <Switch
                        checked={model.isInstCharge}
                        value="isInstCharge"
                        onChange={(e: any) => {
                          handleFieldChange(
                            "isInstCharge",
                            e ? e.target.checked : undefined
                          );
                        }}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                  <FormControlLabel
                    label="Is 1098-T?"
                    labelPlacement="start"
                    key={"is1098T"}
                    control={
                      <Switch
                        checked={model.is1098T}
                        value="is1098T"
                        onChange={(e: any) => {
                          handleFieldChange(
                            "is1098T",
                            e ? e.target.checked : undefined
                          );
                        }}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                  <FormControlLabel
                    label="Is Deposit?"
                    labelPlacement="start"
                    key={"isDeposit"}
                    control={
                      <Switch
                        checked={model.isDeposit}
                        value="isDeposit"
                        onChange={(e: any) => {
                          handleFieldChange(
                            "isDeposit",
                            e ? e.target.checked : undefined
                          );
                        }}
                        color="primary"
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.gridMargin}
                spacing={1}
              ></Grid>
              <Grid container direction="row" spacing={4}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <ProgressSaveButton
                        text="Save"
                        onClick={(e: any) => {}}
                        loading={loading}
                        disabled={false}
                        buttonClassName={classes.saveButton}
                        size="small"
                      ></ProgressSaveButton>
                    </Grid>
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        disabled={loading}
                        onClick={handleCancel}
                        size="small"
                        color="primary"
                        variant="contained"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleCancel}
                        style={{ width: 80 }}
                      >
                        NEW
                      </Button>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </form>
      </FormContext>
      <ConfirmationModal {...confirmationPopupState} />
    </Fragment>
  );
};

export default TransactionCodeDetails;
