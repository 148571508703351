import API from "../../apiWrapper";
import { IProgramVersionDetails } from "../../../interfaces/setup/academics/ProgramProgramVersion/IProgramVersionDetails";
import { bool } from "prop-types";
export const GetProgramVersionDetails = (programVersionId: string) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetProgramVersionDetails", {
      params: {
        programVersionId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const upsertProgramVersion = (model: IProgramVersionDetails) => {
  return API()
    .post("/AcademicRecords/ProgramVersions/UpsertProgramVersion", model, {
      responseType: "json",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetSchedules = (programVersionId: string) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetScheduleDetailsByProgramVersionId", {
      params: {
        programVersionId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetProgramVersionChargingMethodInformation = (programVersionId: string) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetProgramVersionCharginMethodInformation", {
      params: {
        programVersionId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetProgramVersionList = (campusId: string, isInactive: boolean) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetProgramVersionsByCampus", {
      params: {
        campusId,
        isInactive,
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetChargeDefinitionProgramVersionList = () => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetChargeDefinitionProgramVersion")
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetHoursInProgramByProgramVersionId = (programVersionId: string) => {
  return API()
    .get("/AcademicRecords/ProgramVersions/GetHoursInProgramByProgramVersionId", {
      params: {
        programVersionId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};