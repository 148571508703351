import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      color: "black",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab1 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>1. Student's Title IV Aid Information</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={7} sm={7} xs={12}>
                  {/* <TableContainer component={Paper}> */}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Grant Programs</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Amount disbursed</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Amount that could have been disbursed</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>1. Pell Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantDisbursed"
                            key="pellGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            disabled={override == true ? false : true}
                            defaultValue={model?.pellGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantDisbursed
                                ? (data.errors.pellGrantDisbursed as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantCouldDisbursed"
                            key="pellGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.pellGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .pellGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>2. FSEOG</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogDisbursed"
                            key="fseogDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.fseogDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogDisbursed
                                ? (data.errors.fseogDisbursed as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogCouldDisbursed"
                            key="fseogCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.fseogCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogCouldDisbursed
                                ? (
                                    data.errors
                                      .fseogCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>3. TEACH Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantDisbursed"
                            key="teachGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.teachGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantDisbursed
                                ? (
                                    data.errors
                                      .teachGrantDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantCouldDisbursed"
                            key="teachGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.teachGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .teachGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>4. Iraq and Afghanistan Service Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantDisbursed"
                            key="iraqAfgGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.iraqAfgGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantDisbursed
                                ? (
                                    data.errors
                                      .iraqAfgGrantDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantCouldDisbursed"
                            key="iraqAfgGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.iraqAfgGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .iraqAfgGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">
                          <b>Subtotal</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subTotalAmountDisbursedA"
                            key="subTotalAmountDisbursedA"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subTotalAmountDisbursedA}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subTotalAmountDisbursedA",
                                v.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>A</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.subTotalAmountDisbursedA}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subTotalAmountDisbursedA
                                ? (
                                    data.errors
                                      .subTotalAmountDisbursedA as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subTotalAmountCouldDisbursedC"
                            key="subTotalAmountCouldDisbursedC"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subTotalAmountCouldDisbursedC}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subTotalAmountCouldDisbursedC",
                                v.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>C</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.subTotalAmountCouldDisbursedC}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subTotalAmountCouldDisbursedC
                                ? (
                                    data.errors
                                      .subTotalAmountCouldDisbursedC as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </TableContainer> */}
                  {/* <TableContainer component={Paper}> */}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Loan Programs</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Net amount disbursed</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Net amount that could have been disbursed</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>5. Unsubsidized Direct Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="unsubLoanNetAmountDisbursed"
                            key="unsubLoanNetAmountDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.unsubLoanNetAmountDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "unsubLoanNetAmountDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.unsubLoanNetAmountDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.unsubLoanNetAmountDisbursed
                                ? (
                                    data.errors
                                      .unsubLoanNetAmountDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="unsubLoanNetAmountCouldDisbursed"
                            key="unsubLoanNetAmountCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={
                              model?.unsubLoanNetAmountCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "unsubLoanNetAmountCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.unsubLoanNetAmountCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.unsubLoanNetAmountCouldDisbursed
                                ? (
                                    data.errors
                                      .unsubLoanNetAmountCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>6. Subsidized Direct Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subLoanNetAmountDisbursed"
                            key="subLoanNetAmountDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subLoanNetAmountDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subLoanNetAmountDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.subLoanNetAmountDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subLoanNetAmountDisbursed
                                ? (
                                    data.errors
                                      .subLoanNetAmountDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subLoanNetAmountCouldDisbursed"
                            key="subLoanNetAmountCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subLoanNetAmountCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subLoanNetAmountCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.subLoanNetAmountCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subLoanNetAmountCouldDisbursed
                                ? (
                                    data.errors
                                      .subLoanNetAmountCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>7. Direct Graduate PLUS Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directGraduatePlusLoanDisbursed"
                            key="directGraduatePlusLoanDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={
                              model?.directGraduatePlusLoanDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directGraduatePlusLoanDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directGraduatePlusLoanDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directGraduatePlusLoanDisbursed
                                ? (
                                    data.errors
                                      .directGraduatePlusLoanDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directGraduatePlusLoanCouldDisbursed"
                            key="directGraduatePlusLoanCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={
                              model?.directGraduatePlusLoanCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directGraduatePlusLoanCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directGraduatePlusLoanCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directGraduatePlusLoanCouldDisbursed
                                ? (
                                    data.errors
                                      .directGraduatePlusLoanCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>8. Direct Parent PLUS Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directParentPlusLoanDisbursed"
                            key="directParentPlusLoanDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.directParentPlusLoanDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directParentPlusLoanDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.directParentPlusLoanDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directParentPlusLoanDisbursed
                                ? (
                                    data.errors
                                      .directParentPlusLoanDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directParentPlusLoanCouldDisbursed"
                            key="directParentPlusLoanCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={
                              model?.directParentPlusLoanCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directParentPlusLoanCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directParentPlusLoanCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directParentPlusLoanCouldDisbursed
                                ? (
                                    data.errors
                                      .directParentPlusLoanCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">
                          <b>Subtotal</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subTotalNetAmountDisbursedB"
                            key="subTotalNetAmountDisbursedB"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subTotalNetAmountDisbursedB}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subTotalNetAmountDisbursedB",
                                v.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>B</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.subTotalNetAmountDisbursedB}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subTotalNetAmountDisbursedB
                                ? (
                                    data.errors
                                      .subTotalNetAmountDisbursedB as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subTotalNetAmountDisbursedD"
                            key="subTotalNetAmountDisbursedD"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.subTotalNetAmountDisbursedD}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subTotalNetAmountDisbursedD",
                                v.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabel}>D</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.subTotalNetAmountDisbursedD}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subTotalNetAmountDisbursedD
                                ? (
                                    data.errors
                                      .subTotalNetAmountDisbursedD as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </TableContainer> */}
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                  <div>
                    <React.Fragment>
                      <span className={classes.leftLabel}>E</span> <b>Total Title IV aid disbursed for the period.</b>
                    </React.Fragment>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" style={{ width: 100 }}>
                            <b>A.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalAmountDisbursedA1"
                              key="subTotalAmountDisbursedA1"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalAmountDisbursedA}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalAmountDisbursedA",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalAmountDisbursedA}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalAmountDisbursedA
                                  ? (
                                      data.errors
                                        .subTotalAmountDisbursedA as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>+ B.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalNetAmountDisbursedB1"
                              key="subTotalNetAmountDisbursedB1"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalNetAmountDisbursedB}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalNetAmountDisbursedB",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalNetAmountDisbursedB}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalNetAmountDisbursedB
                                  ? (
                                      data.errors
                                        .subTotalNetAmountDisbursedB as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>= E. </b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="boxEResult"
                              key="boxEResult"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.boxEResult}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "boxEResult",
                                  v.target.value
                                );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <React.Fragment>
                                    <div className={classes.leftLabel}>$</div>
                                  </React.Fragment>
                                ),
                              }}
                              error={!!data.errors.boxEResult}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.boxEResult
                                  ? (data.errors.boxEResult as FieldError)
                                      .message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div>
                    <React.Fragment>
                      <span className={classes.leftLabel}>F</span> <b>Total Title
                      IV grant aid disbursed and that could have been disbursed
                      for the period.</b>
                    </React.Fragment>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" style={{ width: 100 }}>
                            <b>A.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalAmountDisbursedA2"
                              key="subTotalAmountDisbursedA2"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalAmountDisbursedA}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalAmountDisbursedA",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalAmountDisbursedA}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalAmountDisbursedA
                                  ? (
                                      data.errors
                                        .subTotalAmountDisbursedA as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>+ C.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalAmountCouldDisbursedC2"
                              key="subTotalAmountCouldDisbursedC2"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={
                                model?.subTotalAmountCouldDisbursedC
                              }
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalAmountCouldDisbursedC",
                                  v.target.value
                                );
                              }}
                              error={
                                !!data.errors.subTotalAmountCouldDisbursedC
                              }
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalAmountCouldDisbursedC
                                  ? (
                                      data.errors
                                        .subTotalAmountCouldDisbursedC as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>= F. </b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="boxFResult"
                              key="boxFResult"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.boxFResult}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "boxFResult",
                                  v.target.value
                                );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <React.Fragment>
                                    <div className={classes.leftLabel}>$</div>
                                  </React.Fragment>
                                ),
                              }}
                              error={!!data.errors.boxFResult}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.boxFResult
                                  ? (data.errors.boxFResult as FieldError)
                                      .message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div>
                    <React.Fragment>
                      <span className={classes.leftLabel}>G</span> <b>Total Title
                      IV aid disbursed and that could have been disbursed for
                      the period.</b>
                    </React.Fragment>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" style={{ width: 100 }}>
                            <b>A.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalAmountDisbursedA3"
                              key="subTotalAmountDisbursedA3"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalAmountDisbursedA}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalAmountDisbursedA",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalAmountDisbursedA}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalAmountDisbursedA
                                  ? (
                                      data.errors
                                        .subTotalAmountDisbursedA as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>B.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalNetAmountDisbursedBG"
                              key="subTotalNetAmountDisbursedBG"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalNetAmountDisbursedB}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalNetAmountDisbursedB",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalNetAmountDisbursedB}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalNetAmountDisbursedB
                                  ? (
                                      data.errors
                                        .subTotalNetAmountDisbursedB as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>C.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalAmountCouldDisbursedCG"
                              key="subTotalAmountCouldDisbursedCG"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={
                                model?.subTotalAmountCouldDisbursedC
                              }
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalAmountCouldDisbursedC",
                                  v.target.value
                                );
                              }}
                              error={
                                !!data.errors.subTotalAmountCouldDisbursedC
                              }
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalAmountCouldDisbursedC
                                  ? (
                                      data.errors
                                        .subTotalAmountCouldDisbursedC as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>+ D.</b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="subTotalNetAmountDisbursedDG"
                              key="subTotalNetAmountDisbursedDG"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.subTotalNetAmountDisbursedD}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "subTotalNetAmountDisbursedD",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.subTotalNetAmountDisbursedD}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.subTotalNetAmountDisbursedD
                                  ? (
                                      data.errors
                                        .subTotalNetAmountDisbursedD as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>= G. </b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="boxGResult"
                              key="boxGResult"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                               disabled={override == true ? false : true}
                              defaultValue={model?.boxGResult}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "boxGResult",
                                  v.target.value
                                );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <React.Fragment>
                                    <div className={classes.leftLabel}>$</div>
                                  </React.Fragment>
                                ),
                              }}
                              error={!!data.errors.boxGResult}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.boxGResult
                                  ? (data.errors.boxGResult as FieldError)
                                      .message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab1;
