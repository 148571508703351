import { IGradDateCalculator } from "../../interfaces/Tools/IGradDateCalculator";
import API from "../apiWrapper";

export const CalculateContractedGraduationDate = (model: any) => {
    return API()
        .post("Common/GraduationCalculator/CalculateContractedGraduationDate", model)
        .then((res: any) => {
            return res?.data ?? "Error calculating date";
        })
        .catch((error: any) => {
            const errorMessage = error?.message ?? "An unknown error occurred.";
            throw new Error(errorMessage);
        });
};