import DateFnsUtils from "@date-io/date-fns";
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { DoneAllOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import TimerIcon from "@material-ui/icons/Timer";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";
import { format, parse } from "date-fns";
import moment from "moment";
import * as React from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { CardTitle } from "reactstrap";
import * as enrollmentApi from "../../api/student/academics/enrollmentApi";
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import InputField from "../../components/_Layout/Inputs/InputField";
import { InputType } from "../../constants/uiConstants/inputConstants";
import IPostAttendacePunchRecords from "../../interfaces/attendance/IPostAttendacePunchRecords";
import IStudentClockAttendanceHistory from "../../interfaces/attendance/IStudentClockAttendanceHistory";
import { EnrollmentSchedule } from "../../interfaces/student/academics/EnrollmentProgram";
import { EmptyGuid } from "../../utils/constants";
import Schedule from "../student/profile/enrollments/Schedule";
import { system } from "../../constants/system/systemConstants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    dialogTitle: {
      padding: theme.spacing(1, 0.5),
    },
    dialogTitleText: {
      fontSize: theme.spacing(2.11),
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "calc(100% - 44px)",
      float: "left",
    },
    titleText: {
      fontSize: theme.spacing(2.11),
      fontWeight: theme.typography.fontWeightBold,
      maxWidth: "100%",
    },
    dialogCloseButton: {
      padding: theme.spacing(1, 1),
      float: "right",
    },
    dialogAction: {
      justifyContent: "flex-start",
    },
    paperSummary: {
      padding: theme.spacing(1),
    },
    papaerSummaryTitleText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    paperSummarySchedulerTitle: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
    },
    papaerSummaryValue: {
      display: "flex",
      flexDirection: "row",
    },
    papaerSummaryValueText: {
      fontWeight: theme.typography.fontWeightBold,
      minWidth: theme.spacing(5.625),
    },
    mainTableCell: {
      padding: theme.spacing(0),
    },
    detailsTableCell: {
      verticalAlign: "top",
      padding: theme.spacing(0, 0.5),
    },
    tableRow: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tableHead: {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.site.secondary,
      borderColor: theme.palette.tableHeaderColor,
      borderStyle: "solid",
      borderLeftWidth: theme.spacing(0.125),
      borderRightWidth: theme.spacing(0.125),
      borderTopWidth: theme.spacing(0),
      borderBottomWidth: theme.spacing(0),
      "&:last-child": { borderRightWidth: theme.spacing(0) },
      "&:first-child": { borderLeftWidth: theme.spacing(0) },
    },
    tableHeader: {
      boxShadow: theme.palette.boxShadow,
    },
    mainTableHeader: {
      backgroundColor: "transparent",
    },
    dateIconText: {
      fontSize: theme.spacing(2.11),
      fontWeight: theme.typography.fontWeightMedium,
      alignItems: "center",
      display: "flex",
    },
    dateHeaderIconText: {
      alignItems: "center",
      display: "flex",
      float: "right",
    },
    dateTitle: {
      fontSize: theme.spacing(2.11),
      fontWeight: theme.typography.fontWeightMedium,
    },
    timeField: {
      width: theme.spacing(15),
      height: theme.spacing(5.75),
    },
    superIcon: {
      fontSize: theme.spacing(3.125),
    },
    paddingLeft: {
      paddingLeft: theme.spacing(3),
    },
    buttonNoPadding: {
      padding: theme.spacing(0),
    },
    NoPaddingTop: {
      paddingTop: theme.spacing(0),
    },
    badge: {
      backgroundColor: theme.palette.badge,
      color: theme.palette.white,
      right: theme.spacing(0.75),
      bottom: theme.spacing(0.5),
    },
    avatar_icon: {
      height: theme.spacing(6.75),
      width: theme.spacing(6.75),
    },
    capitalize: {
      textTransform: "capitalize",
    },
  })
);

/**
 * Post Attendance Modal Props
 * @type {string} studentBadgeId
 * @type {Date} date
 * @type {() : void} onClose
 */
interface IPostAttendanceModalProps {
  studentBadgeId?: string;
  studetEnrollmentId?: string;
  studentName?: string;
  date?: Date;
  tardy?: boolean;
  isUpdated?: boolean;
  dirty?: boolean;
  statusCode?: string;
  scheduleHours?: number;
  saveScheduleDetails: any;
  saveNewRecord: (
    campusId: string,
    studEnrollId: string,
    badgeNumber: string,
    punchIn?: Date,
    punchOut?: Date
  ) => Promise<void>;
  validatePunches: (
    punchId: number[],
    campusId: string,
    studEnrollId: string,
    badgeNumber: string,
    punchIn: Date,
    punchOut?: Date,
    isDistEdPunch?: boolean
  ) => Promise<void>;
  deleteRecord: (
    punchId: number[],
    campusId: string,
    studEnrollId: string,
    badgeNumber: string
  ) => Promise<void>;
  updateRecord: (
    punchId: number[],
    campusId: string,
    studEnrollId: string,
    badgeNumber: string,
    punchIn: Date,
    punchOut?: Date
  ) => Promise<void>;
  postAbsent: (
    campusId: string,
    badgeNumber: string,
    date: Date
  ) => Promise<void>;
  getPunchDetails: (
    studetEnrollmentId: string,
    date: Date
  ) => Promise<IPostAttendacePunchRecords | undefined>;
  setSlotDirty: (dirtyValue?: boolean) => void;
  onClose?: () => void;
  showSnackBar: any;
}

/** Post Attendance Pop UP Modal
 * @type {IPostAttendanceModalProps} Props
 */
const PostCreditAttendanceModal = (
  props: IPostAttendanceModalProps
): React.ReactElement<IPostAttendanceModalProps> => {
  const classes = useStyles({});
  const [record, setRecord] = React.useState<IPostAttendacePunchRecords>();
  const [initialRecords, setInitialRecords] =
    React.useState<IPostAttendacePunchRecords>();
  const defaultSchedule: EnrollmentSchedule = {
    scheduleId: "",
    badgeId: "",
    programVersion: "",
  };
  const profile = useSelector((state: any) =>
    state.userstate[state.session.user.userId]
      ? state.userstate[state.session.user.userId].selectedStudent
      : null
  );
  const [shouldShowNotesConfirmation, setShouldShowNotesConfirmation] =
    React.useState<boolean>(false);
  const [newPunchIn, setNewPunchIn] = React.useState<string>();
  const [newPunchOut, setNewPunchOut] = React.useState<string>();
  const [newPunchDE, setNewPunchDE] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [scheduledHoursDirty, setScheduledHoursDirty] =
    React.useState<boolean>(false);
  const [punchUpdated, setPunchUpdated] = React.useState<boolean>(false);
  const [scheduleId, setScheduleId] =
    React.useState<EnrollmentSchedule>(defaultSchedule);
  const [loadingScheduleInfo, setLoadingScheduleInfo] =
    React.useState<boolean>(false);
  const [confirmationDialog, setconfirmationDialogProps] = React.useState<{
    open?: boolean;
    message?: string;
    onCancel?: () => void;
    onOk?: () => void;
  }>({ open: false });

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const isDistanceAttTrackingEnabled = (appSettings[system.settings.isDistanceAttTracking] as string || 'false').toLowerCase() === 'true';

  const [reasonDialog, setReasonDialogProps] = React.useState<{
    open?: boolean;
    onCancel?: () => void;
    onOk?: () => void;
  }>({ open: false });

  const methods = useForm({
    mode: "onBlur",
  });

  const selectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const newPunchInDateFieldRef = React.useRef<HTMLInputElement>();

  const loadDetails = async () => {
    if (!!(props.studetEnrollmentId && props.date)) {
      setLoading(true);
      const data = await props.getPunchDetails(
        props.studetEnrollmentId,
        props.date
      );

      setRecord({
        ...(data ?? {}),
        dateOfRecord: props.date,
        studentBadgeId: props.studentBadgeId,
        studentEnrollmentId: props.studetEnrollmentId,
        isEdit: data?.attendancePunch ? data.attendancePunch.length > 0 : false,
        scheduledHours: !!data?.scheduleId
          ? data.scheduledHours
          : props.scheduleHours,
      });
      setInitialRecords({
        ...(data ?? {}),
        dateOfRecord: props.date,
        studentBadgeId: props.studentBadgeId,
        studentEnrollmentId: props.studetEnrollmentId,
        isEdit: data?.attendancePunch ? data.attendancePunch.length > 0 : false,
        scheduledHours: !!data?.scheduleId
          ? data.scheduledHours
          : props.scheduleHours,
      });
      setShouldShowNotesConfirmation(false);
      methods.reset();
      setPunchUpdated(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setNewPunchIn("");
    setNewPunchOut("");
    setNewPunchDE(false);
    loadDetails();
  }, [props.studetEnrollmentId, props.date]);

  const [recordToEdit, setRecordToEdit] = React.useState<number>(0);

  const handleSave = async () => {
    await formSubmit();
  };
  
  const formSubmit = async () => {
    setLoading(true);
    const setting = appSettings[
      system.settings.alwaysPromptReasonAttendance
    ] as string;
    let alwaysShowReasonBox = false;
    if (setting) {
      alwaysShowReasonBox = setting.toLowerCase() === "true";
    }

    
    if (
      selectedCampus &&
      //props.studentBadgeId &&
      props.date &&
      (record?.scheduledHours || record?.scheduledHours === 0)
    ) {
      if (
        (record.isEdit ||
          record.isAbsent ||
          alwaysShowReasonBox ||
          (record?.studentClockAttendanceHistory?.length ?? 0 > 0)) &&
        !record.reason &&
        punchUpdated &&
        !record.isImport &&
        (record?.isEdit == true || shouldShowNotesConfirmation || alwaysShowReasonBox)
      ) {
        setReasonDialogProps({
          open: true,
          onCancel: cancelRequest,
          onOk: confirmReason,
        });
        return;
      }
      let attendanceData = record?.attendancePunch || [];
      let date = props.date || new Date();
      var newAttendanceData = attendanceData.map((punch) => ({
        ...punch,
        punchInValue: punch?.punchIn
          ? `${format(
              parse(punch.punchIn, "hh:mm a", date),
              "yyyy-MM-dd hh:mm a"
            )}`
          : undefined,
        punchOutValue: punch?.punchOut
          ? `${format(
              parse(punch.punchOut, "hh:mm a", date),
              "yyyy-MM-dd hh:mm a"
            )}`
          : undefined,
      }));

      const newRecord = {
        ...record,
        dateOfRecord: `${format(date, "yyyy-MM-dd")}`,
        campusId: selectedCampus,
        attendancePunch: newAttendanceData,
      };
      await props.saveScheduleDetails(newRecord);
    }
    loadDetails();
    if (!(newPunchIn || newPunchOut)) {
      props.setSlotDirty(false);
    }
  };

  const hadleNewFieldChange = (
    field: "newPunchIn" | "newPunchOut",
    value: string
  ): void => {
    if (record?.isAbsent) {
      setShouldShowNotesConfirmation(true);
    }
    if (value) {
      value = value.toUpperCase();
    }
    if (field === "newPunchIn") {
      setNewPunchIn(value);
    } else if (field === "newPunchOut") {
      setNewPunchOut(value);
    }
    props.setSlotDirty(true);
    methods.setValue(field, value);
  };

  const handleNewFieldDEChange = (
    field: "isDistEdPunch",
    value: boolean
  ): void => {
    if (record?.isAbsent) {
      setShouldShowNotesConfirmation(true);
    }
    setNewPunchDE(value);
    props.setSlotDirty(true);
    methods.setValue(field, value);
  };

  const closeConfirmation = () => {
    setconfirmationDialogProps({
      open: false,
      message: undefined,
      onCancel: () => undefined,
      onOk: () => undefined,
    });
  };

  const closeReasonDialog = (emptyReason: boolean) => {
    setReasonDialogProps({
      open: false,
      onCancel: () => undefined,
      onOk: () => undefined,
    });
    if (emptyReason) {
      setRecord({ ...record, reason: "" });
    }
  };

  const cancelRequest = (): void => {
    setLoading(false);
    closeConfirmation();
    closeReasonDialog(true);
  };

  const confirmReason = async () => {
    const success = await methods.triggerValidation("reason");
    if (success) {
      closeReasonDialog(false);
      await formSubmit();
    }
  };
  const validatePunches = async (index: number) => {    
    const punch =
      record && record?.attendancePunch && record?.attendancePunch[index];      
    const success = await methods.triggerValidation([
      `punchIn${index}`,
      `punchOut${index}`,
    ]);
    if (
      selectedCampus &&
      //props.studentBadgeId &&
      props.studetEnrollmentId &&
      props.date &&
      success &&
      punch &&
      punch.punchIn &&
      punch.punchInId &&
      punch.punchInId > 0 &&
      punch.punchOut &&
      punch.punchOutId &&
      punch.punchOutId > 0
    ) {
      setLoading(true);
      const ids: number[] = [];
      if (punch && punch.punchInId) {
        ids.push(punch.punchInId);
      }
      if (punch && punch.punchOutId) {
        ids.push(punch.punchOutId);
      }
      closeConfirmation();
      const pID = parse(punch.punchIn, "hh:mm a", props.date);
      const pOD = punch.punchOut
        ? parse(punch.punchOut, "hh:mm a", props.date)
        : undefined;
      await props.validatePunches(
        ids,
        selectedCampus as string,
        props.studetEnrollmentId,
        props.studentBadgeId ?? "",
        pID,
        pOD,
        punch.isDistEdPunch
      );
      loadDetails();
    }
  };

  const saveUpdatedRecord = async (
    index: number,
    punchOutCheck: number = 0
  ) => {
    const punch =
      record && record?.attendancePunch && record?.attendancePunch[index];
    const success = await methods.triggerValidation([
      `punchIn${index}`,
      `punchOut${index}`,
    ]);
    if (
      selectedCampus &&
      //props.studentBadgeId &&
      props.studetEnrollmentId &&
      props.date &&
      success &&
      punch &&
      punch.punchIn
    ) {
      if (!punchOutCheck) {
        punchOutCheck = !punch.punchOut ? 1 : 2;
      }
      if (punchOutCheck === 1) {
        setconfirmationDialogProps({
          open: true,
          message:
            "You are saving a punch with out any punch out time. Do you want to continue?",
          onCancel: cancelRequest,
          onOk: () => saveUpdatedRecord(index, 2),
        });
        return;
      }
      setLoading(true);
      closeConfirmation();

      const updateAttendancePunch = [...(record?.attendancePunch || [])];
      setRecord({ ...record, attendancePunch: updateAttendancePunch });
      setRecordToEdit(0);
      setPunchUpdated(true);
      setLoading(false);
      if (newPunchInDateFieldRef && newPunchInDateFieldRef.current) {
        newPunchInDateFieldRef.current?.focus();
      }
    }
  };

  const saveNewRecord = async (
    punchOutCheck: number = 0,
    outOfOrderCheck: number = 0,
    statusCheck: number = 0
  ) => {
    const success = await methods.triggerValidation([
      "newPunchIn",
      "newPunchOut",
    ]);
    if (
      success &&
      newPunchIn &&
      props.date &&
      selectedCampus &&
      //props.studentBadgeId &&
      props.studetEnrollmentId
    ) {
      setLoading(true);
      if (!punchOutCheck) {
        punchOutCheck = !newPunchOut ? 1 : 2;
      }
      if (!outOfOrderCheck) {
        const punchOutMissing =
          record &&
          record.attendancePunch &&
          record.attendancePunch.find(
            (item) => !item.punchOut && !item.punchOutId
          );
        outOfOrderCheck = punchOutMissing ? 1 : 2;
      }
      if (!statusCheck) {
        statusCheck =
          props.statusCode &&
          (props.statusCode === "LOA" || props.statusCode === "SUSP")
            ? 1
            : 2;
      }
      if (statusCheck === 1) {
        const message =
          props.statusCode === "LOA"
            ? "Student would no longer be on LOA, enrollment status would be changed to Currently Attending and absence hours for student may increase during this LOA time. Are you sure?"
            : "Student would no longer be on Suspension, enrollment status would be changed to Currently Attending and absence hours for student may increase during this Suspension time. Are you sure?";
        setconfirmationDialogProps({
          open: true,
          message: message,
          onCancel: cancelRequest,
          onOk: () => saveNewRecord(punchOutCheck, outOfOrderCheck, 2),
        });
        return;
      }
      if (outOfOrderCheck === 1) {
        setconfirmationDialogProps({
          open: true,
          message:
            "You are adding a punch out of order. Do you want to continue?",
          onCancel: cancelRequest,
          onOk: () => saveNewRecord(punchOutCheck, 2, statusCheck),
        });
        return;
      }
      if (punchOutCheck === 1) {
        setconfirmationDialogProps({
          open: true,
          message:
            "You are adding a punch with out any punch out time. Do you want to continue?",
          onCancel: cancelRequest,
          onOk: () => saveNewRecord(2, outOfOrderCheck, statusCheck),
        });
        return;
      }
      closeConfirmation();
      const pID = newPunchIn
        ? parse(newPunchIn, "hh:mm a", props.date)
        : undefined;
      const pOD = newPunchOut
        ? parse(newPunchOut, "hh:mm a", props.date)
        : undefined;
      const punch =
        record && record?.attendancePunch ? [...record?.attendancePunch] : [];
      punch.push({
        punchIn: newPunchIn,
        punchOut: newPunchOut,
        isPunchOutValid: true,
        isPunchInValid: true,
        isDistEdPunch: newPunchDE
      });
      setRecord({ ...record, attendancePunch: punch });
      setNewPunchIn("");
      setNewPunchOut("");
      setNewPunchDE(false);
      setPunchUpdated(true);
      setLoading(false);
      if (newPunchInDateFieldRef && newPunchInDateFieldRef.current) {
        newPunchInDateFieldRef.current?.focus();
      }
    }
  };

  const deleteGridItem = async (index: number) => {
    if (
      shouldShowNotesConfirmation === false &&
      index < (initialRecords?.attendancePunch?.length ?? 0)
    ) {
      setShouldShowNotesConfirmation(true);
    }
    const punch =
      record && record?.attendancePunch && record?.attendancePunch[index];
    if (
      selectedCampus &&
      //props.studentBadgeId &&
      props.studetEnrollmentId &&
      record &&
      record.attendancePunch &&
      punch
    ) {
      setLoading(true);
      const newPunchData = [...(record?.attendancePunch || [])];
      newPunchData.splice(index, 1);
      setRecord({ ...record, attendancePunch: newPunchData });
      setPunchUpdated(true);
      setLoading(false);
      if (newPunchInDateFieldRef && newPunchInDateFieldRef.current) {
        newPunchInDateFieldRef.current?.focus();
      }
    }
  };

  const postAbsent = async (): Promise<void> => {
    if (
      props.date &&
      selectedCampus &&
      props.studentBadgeId &&
      props.statusCode !== "SUSP" &&
      props.statusCode !== "LOA"
    ) {
     /*  setLoading(true);
      await props.postAbsent(
        selectedCampus as string,
        props.studentBadgeId,
        props.date
      );
      loadDetails(); */
    }
  };

  const hadleGridFieldChange = (
    field: "punchIn" | "punchOut",
    value: string,
    rowIndex: number
  ): void => {
    if (
      initialRecords &&
      initialRecords.attendancePunch &&
      initialRecords.attendancePunch[rowIndex]
    ) {
      if (
        initialRecords?.attendancePunch[rowIndex][field] !== "" &&
        initialRecords?.attendancePunch[rowIndex][field] !== null
      ) {
        if (shouldShowNotesConfirmation === false) {
          setShouldShowNotesConfirmation(true);
        }
      }
    }

    if (record?.isAbsent) {
      setShouldShowNotesConfirmation(true);
    }
    if (record && record.attendancePunch && record.attendancePunch[rowIndex]) {
      record.attendancePunch[rowIndex][field] = value
        ? value.toUpperCase()
        : value;
      props.setSlotDirty(true);
      setRecord({ ...record });
    }
    methods.setValue(`${field}${rowIndex}`, value);
  };

  const handleIsDistEdPunch = (
    field: "isDistEdPunch",
    value: boolean,
    rowIndex: number
  ): void => {
    if (
      initialRecords &&
      initialRecords.attendancePunch &&
      initialRecords.attendancePunch[rowIndex]
    ) {
      if (
        initialRecords?.attendancePunch[rowIndex][field] !== null
      ) {
        if (shouldShowNotesConfirmation === false) {
          setShouldShowNotesConfirmation(true);
        }
      }
    }

    if (record?.isAbsent) {
      setShouldShowNotesConfirmation(true);
    }
    if (record && record.attendancePunch && record.attendancePunch[rowIndex]) {
      record.attendancePunch[rowIndex][field] = value ?? false;
      props.setSlotDirty(true);
      setRecord({ ...record });
    }
    methods.setValue(`${field}${rowIndex}`, value);
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = record;
    (updatedModel as any)[fieldId] = value;
    setRecord({ ...updatedModel });
  };

  methods.register("newPunchIn", {
    validate: () => {
      if (!newPunchIn) {
        return "Punch In is required.";
      }
      if (
        newPunchIn &&
        !/^\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AP][M]))$/.test(newPunchIn)
      ) {
        return "Incorrect Time Format.";
      }
      if (
        newPunchOut &&
        newPunchIn &&
        parse(newPunchIn, "hh:mm a", new Date()) >=
          parse(newPunchOut, "hh:mm a", new Date())
      ) {
        return "Punch In must be smaller than punch out.";
      }
      if (record && record.attendancePunch) {
        const punchOverLap = record.attendancePunch.find(
          (punchItem) =>
            punchItem.punchIn &&
            (newPunchIn == punchItem.punchIn ||
              (parse(newPunchIn, "hh:mm a", new Date()) >
                parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                punchItem.punchOut &&
                parse(newPunchIn, "hh:mm a", new Date()) <
                  parse(punchItem.punchOut, "hh:mm a", new Date())) ||
              (newPunchOut &&
                parse(newPunchIn, "hh:mm a", new Date()) <
                  parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                parse(newPunchOut, "hh:mm a", new Date()) >
                  parse(punchItem.punchIn, "hh:mm a", new Date())))
        );
        if (punchOverLap) {
          return "Already a punch for this time slot.";
        }
      }
      return true;
    },
  });

  methods.register("newPunchOut", {
    validate: () => {
      if (
        newPunchOut &&
        !/^\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AP][M]))$/.test(newPunchOut)
      ) {
        return "Incorrect Time Format.";
      }
      if (
        newPunchOut &&
        newPunchIn &&
        parse(newPunchOut, "hh:mm a", new Date()) <=
          parse(newPunchIn, "hh:mm a", new Date())
      ) {
        return "Punch out must be greater than punch in.";
      }
      if (newPunchOut && record && record.attendancePunch) {
        const punchOverLap = record.attendancePunch.find(
          (punchItem) =>
            punchItem.punchIn &&
            (newPunchOut == punchItem.punchOut ||
              (parse(newPunchOut, "hh:mm a", new Date()) >
                parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                punchItem.punchOut &&
                parse(newPunchOut, "hh:mm a", new Date()) <
                  parse(punchItem.punchOut, "hh:mm a", new Date())) ||
              (newPunchIn &&
                parse(newPunchIn, "hh:mm a", new Date()) <
                  parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                parse(newPunchOut, "hh:mm a", new Date()) >
                  parse(punchItem.punchIn, "hh:mm a", new Date())))
        );
        if (punchOverLap) {
          return "Already a punch for this time slot.";
        }
      }
      return true;
    },
  });

  if (record && record.attendancePunch) {
    record.attendancePunch.map((item, index) => {
      methods.register(`punchIn${index}`, {
        validate: () => {
          if (record && record.attendancePunch) {
            if (!record?.attendancePunch[index]?.punchIn) {
              return "Punch in is required.";
            }
            if (
              record?.attendancePunch[index]?.punchIn &&
              !/^\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AP][M]))$/.test(
                record?.attendancePunch[index]?.punchIn ?? ""
              )
            ) {
              return "Incorrect Time Format.";
            }
            if (
              record?.attendancePunch[index]?.punchOut &&
              record?.attendancePunch[index]?.punchIn &&
              parse(
                record?.attendancePunch[index]?.punchIn ?? "",
                "hh:mm a",
                new Date()
              ) >=
                parse(
                  record?.attendancePunch[index]?.punchOut ?? "",
                  "hh:mm a",
                  new Date()
                )
            ) {
              return "Punch In must be smaller than punch out.";
            }
            const currPunch = record?.attendancePunch[index];
            if (currPunch && record && record.attendancePunch) {
              const punchOverLap = record.attendancePunch.find(
                (punchItem, i) => {
                  return (
                    index !== i &&
                    currPunch.punchIn &&
                    punchItem.punchIn &&
                    (currPunch.punchIn == punchItem.punchIn ||
                      (parse(currPunch.punchIn, "hh:mm a", new Date()) >
                        parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                        punchItem.punchOut &&
                        parse(currPunch.punchIn, "hh:mm a", new Date()) <
                          parse(punchItem.punchOut, "hh:mm a", new Date())) ||
                      (currPunch.punchOut &&
                        parse(currPunch.punchIn, "hh:mm a", new Date()) <
                          parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                        parse(currPunch.punchOut, "hh:mm a", new Date()) >
                          parse(punchItem.punchIn, "hh:mm a", new Date())))
                  );
                }
              );
              if (punchOverLap) {
                return "Already a punch for this time slot.";
              }
            }
          }
          return true;
        },
      });
      methods.register(`punchOut${index}`, {
        validate: () => {
          if (record && record.attendancePunch) {
            if (
              record?.attendancePunch[index]?.punchOutId &&
              !record?.attendancePunch[index]?.punchOut
            ) {
              return "Punch out is required.";
            }
            if (
              record?.attendancePunch[index]?.punchOut &&
              !/^\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AP][M]))$/.test(
                record?.attendancePunch[index]?.punchOut ?? ""
              )
            ) {
              return "Incorrect Time Format.";
            }
            if (
              record?.attendancePunch[index]?.punchOut &&
              record?.attendancePunch[index]?.punchIn &&
              parse(
                record?.attendancePunch[index]?.punchOut ?? "",
                "hh:mm a",
                new Date()
              ) <=
                parse(
                  record?.attendancePunch[index]?.punchIn ?? "",
                  "hh:mm a",
                  new Date()
                )
            ) {
              return "Punch out must be greater than punch in.";
            }
            const currPunch = record?.attendancePunch[index];
            if (currPunch && record && record.attendancePunch) {
              const punchOverLap = record.attendancePunch.find(
                (punchItem, i) => {
                  return (
                    index !== i &&
                    currPunch.punchOut &&
                    punchItem.punchIn &&
                    (currPunch.punchIn == punchItem.punchIn ||
                      (parse(currPunch.punchOut, "hh:mm a", new Date()) >
                        parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                        punchItem.punchOut &&
                        parse(currPunch.punchOut, "hh:mm a", new Date()) <
                          parse(punchItem.punchOut, "hh:mm a", new Date())) ||
                      (currPunch.punchIn &&
                        parse(currPunch.punchIn, "hh:mm a", new Date()) <
                          parse(punchItem.punchIn, "hh:mm a", new Date()) &&
                        parse(currPunch.punchOut, "hh:mm a", new Date()) >
                          parse(punchItem.punchIn, "hh:mm a", new Date())))
                  );
                }
              );
              if (punchOverLap) {
                return "Already a punch for this time slot.";
              }
            }
          }
          return true;
        },
      });
    });
  }

  const closeScheduleModal = () => {
    setScheduleId(defaultSchedule);
  };

  const openPopUpScheudle = async () => {
    if (props.studetEnrollmentId && !loadingScheduleInfo) {
      setLoadingScheduleInfo(true);
      try {
        const enrollMentDataResponse =
          await enrollmentApi.getEnrollmentProgramInfo(
            props.studetEnrollmentId
          );
        if (enrollMentDataResponse && enrollMentDataResponse.data) {
          setScheduleId({
            scheduleId: enrollMentDataResponse.data.scheduleId,
            badgeId: enrollMentDataResponse.data.badgeId,
            programVersion: enrollMentDataResponse.data.programVersionId,
          });
        }
      } catch (_error) {
        setScheduleId(defaultSchedule);
      }
      setLoadingScheduleInfo(false);
    }
  };

  const closeRow = (dirtyCheck: number = 0) => {
    if (props.onClose) {
      if (!dirtyCheck) {
        dirtyCheck = props.dirty ? 1 : 2;
      }
      if (dirtyCheck === 1) {
        setconfirmationDialogProps({
          open: true,
          message:
            "You are about to close the punches window. Any unsaved data will be lost. Are you sure you want to continue?",
          onCancel: cancelRequest,
          onOk: () => closeRow(2),
        });
        return;
      }
      props.onClose();
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const reasonCard = () => {
    return (record?.studentClockAttendanceHistory?.length ?? 0) > 0
      ? record?.studentClockAttendanceHistory
        ? record.studentClockAttendanceHistory.map(
            (clockHistory: IStudentClockAttendanceHistory) => (
              <Grid item md={4} xs={12}>
                <Card>
                  <CardHeader
                    title={
                      <Typography
                        className={classes.dateIconText}
                        component="p"
                      >
                        <span className={classes.capitalize}>
                          {`Updated By: ${clockHistory.modifiedByName} `}
                        </span>
                      </Typography>
                    }
                    subheader={
                      <Typography
                        className={classes.dateIconText}
                        component="p"
                      >
                        {moment(
                          clockHistory.modifiedDate,
                          "YYYY-MM-DD[T]HH:mm:ss.fff"
                        ).format("MMMM DD, YYYY hh:mm:ss A")}
                      </Typography>
                    }
                  />
                  <CardContent className={classes.NoPaddingTop}>
                    <Typography variant="subtitle2">
                      {`Actual Hours From: ${clockHistory.actualHoursFrom}`}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {`Actual Hours To: ${clockHistory.actualHoursTo}`}
                    </Typography>
                    <Typography variant="h6">Reason:</Typography>
                    <Typography variant="subtitle2">
                      {clockHistory.reason}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          )
        : null
      : null;
  };
  const dialogContent = () => {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography component="div" className={classes.titleText}>
              Attendance Record Change History
              <span className={classes.dateHeaderIconText}>
                <TimerIcon fontSize="small" />{" "}
                {props.date && format(props.date, "EEEE, MMM d yyyy")}
              </span>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component="div" className={classes.dialogTitleText}>
              # {props.studentBadgeId} {props.studentName}
            </Typography>
            <DialogContentText>
              <Grid container spacing={2}>
                {reasonCard()}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    methods.triggerValidation(["newPunchIn", "newPunchOut"]);
  }, [newPunchIn, newPunchOut]);

  React.useEffect(() => {
    methods.triggerValidation("scheduledHours");
  }, [record]);

  React.useEffect(() => {
    methods.triggerValidation("reason");
  }, [record?.reason]);
  return (
    <React.Fragment>
      {(record?.studentClockAttendanceHistory?.length ?? 0) > 0
        ? dialogContent()
        : null}
      <FormContext {...methods}>
        <form>
          <Card square>
            <CardTitle
              className={classes.dialogTitle}
              id="attendance-dialog-title"
            >
              <Typography
                className={[classes.dialogTitleText, classes.paddingLeft].join(
                  " "
                )}
              >
                # {props.studentBadgeId} {props.studentName}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => closeRow()}
                className={[
                  classes.dialogCloseButton,
                  classes.buttonNoPadding,
                ].join(" ")}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </CardTitle>
            <CardContent>
              <Table>
                <TableHead className={classes.mainTableHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan={2} className={classes.mainTableCell}>
                      <Typography className={classes.dateIconText}>
                        <TimerIcon fontSize="small" />{" "}
                        {props.date && format(props.date, "EEEE, MMM d yyyy")}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.mainTableCell}>
                      <FormControlLabel
                        classes={{ label: classes.dateTitle }}
                        control={
                          <Switch
                            checked={record?.tardy ?? false}
                            name="checkedB"
                            color="primary"
                            disabled={
                              !!loading ||
                              (record?.attendancePunch?.length ?? 0) == 0
                            }
                            onChange={() => {
                              props.setSlotDirty(true);
                              setRecord({
                                ...(record ?? {}),
                                tardy: !(record?.tardy ?? false),
                              });
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Tardy"
                      />
                    </TableCell>
                    <TableCell className={classes.mainTableCell} align="right">
                      {(record?.studentClockAttendanceHistory?.length ?? 0) >
                      0 ? (
                        <Badge
                          onClick={() => {
                            handleClickOpen();
                          }}
                          badgeContent={
                            record?.studentClockAttendanceHistory?.length
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          classes={{ badge: classes.badge }}
                        >
                          <HistoryIcon fontSize="large" />
                        </Badge>
                      ) : null}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      colSpan={3}
                      className={clsx(
                        classes.mainTableCell,
                        classes.detailsTableCell
                      )}
                      valign="top"
                    >
                      <Table>
                        <TableHead className={classes.tableHeader}>
                          <TableRow className={classes.tableRow}>
                            <TableCell
                              className={classes.tableHead}
                              variant="head"
                            >
                              Punch In
                            </TableCell>
                            <TableCell
                              className={classes.tableHead}
                              variant="head"
                            >
                              Punch Out
                            </TableCell>
                            {isDistanceAttTrackingEnabled ? <TableCell
                              className={classes.tableHead}
                              variant="head"
                            >
                             
                            </TableCell>: ""}
                            <TableCell
                              className={classes.tableHead}
                              variant="head"
                            ></TableCell>
                            {record &&
                              record?.attendancePunch &&
                              record?.attendancePunch.length > 0 && (
                                <TableCell
                                  className={classes.tableHead}
                                  variant="head"
                                ></TableCell>
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {record &&
                            record?.attendancePunch &&
                            record.attendancePunch.map((item, key) => (
                              <TableRow
                                className={classes.tableRow}
                                key={`row-${key}`}
                              >
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                      className={classes.timeField}
                                      mask="__:__ _M"
                                      disabled={
                                        (recordToEdit != key + 1
                                          ? true
                                          : false) || !!loading
                                      }
                                      name={`punchIn${key}`}
                                      value={
                                        item.punchIn
                                          ? parse(
                                              item.punchIn,
                                              "hh:mm a",
                                              new Date()
                                            )
                                          : null
                                      }
                                      onChange={(_date, value) =>
                                        hadleGridFieldChange(
                                          "punchIn",
                                          value || "",
                                          key
                                        )
                                      }
                                      error={
                                        !!(
                                          recordToEdit === key + 1 &&
                                          methods.errors[`punchIn${key}`]
                                        )
                                      }
                                      helperText={
                                        recordToEdit === key + 1 &&
                                        methods.errors[`punchIn${key}`]
                                          ? (
                                              methods.errors[
                                                `punchIn${key}`
                                              ] as FieldError
                                            ).message
                                          : undefined
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>

                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                      className={classes.timeField}
                                      mask="__:__ _M"
                                      name={`punchOut${key}`}
                                      disabled={
                                        (recordToEdit != key + 1
                                          ? true
                                          : false) || !!loading
                                      }
                                      value={
                                        item.punchOut
                                          ? parse(
                                              item.punchOut,
                                              "hh:mm a",
                                              new Date()
                                            )
                                          : null
                                      }
                                      onChange={(_date, value) =>
                                        hadleGridFieldChange(
                                          "punchOut",
                                          value || "",
                                          key
                                        )
                                      }
                                      error={
                                        !!(
                                          recordToEdit === key + 1 &&
                                          methods.errors[`punchOut${key}`]
                                        )
                                      }
                                      helperText={
                                        recordToEdit === key + 1 &&
                                        methods.errors[`punchOut${key}`]
                                          ? (
                                              methods.errors[
                                                `punchOut${key}`
                                              ] as FieldError
                                            ).message
                                          : undefined
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                {isDistanceAttTrackingEnabled ?<TableCell>
                                <Checkbox
                                  value="uncontrolled"
                                  color="primary"
                                  disabled={
                                    (recordToEdit != key + 1
                                      ? true
                                      : false) || !!loading
                                  }
                                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                  onClick={(e: any) => {
                                    handleIsDistEdPunch(
                                      "isDistEdPunch",
                                      e.target.checked || false,
                                      key
                                    )
                                  }}
                                  checked={item.isDistEdPunch} 
                                /> D/E
                                </TableCell> : ""}
                                <TableCell>
                                  <IconButton
                                    className={classes.dialogCloseButton}
                                    disabled={
                                      (recordToEdit != 0 &&
                                      recordToEdit != key + 1
                                        ? true
                                        : false) || !!loading
                                    }
                                    onClick={() =>
                                      recordToEdit
                                        ? saveUpdatedRecord(key)
                                        : setRecordToEdit(key + 1)
                                    }
                                  >
                                    {(recordToEdit != key + 1 && (
                                      <EditIcon />
                                    )) || <DoneIcon />}
                                  </IconButton>
                                  {(item.isPunchInValid === false ||
                                    item.isPunchOutValid === false) &&
                                    item.punchInId !== undefined &&
                                    item.punchInId > 0 &&
                                    item.punchOutId !== undefined &&
                                    item.punchOutId > 0 && (
                                      <Tooltip title="Click to Validate Punches">
                                        <IconButton
                                          className={classes.dialogCloseButton}
                                          onClick={() => validatePunches(key)}
                                        >
                                          <DoneAllOutlined />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    disabled={!!loading}
                                    className={classes.dialogCloseButton}
                                    onClick={() => deleteGridItem(key)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                  className={classes.timeField}
                                  disabled={recordToEdit !== 0 || !!loading}
                                  mask="__:__ _M"
                                  name="newPunchIn"
                                  inputRef={newPunchInDateFieldRef}
                                  value={
                                    newPunchIn
                                      ? parse(newPunchIn, "hh:mm a", new Date())
                                      : null
                                  }
                                  onChange={(_date, value) =>
                                    hadleNewFieldChange(
                                      "newPunchIn",
                                      value || ""
                                    )
                                  }
                                  error={
                                    !!(
                                      recordToEdit === 0 &&
                                      methods.errors.newPunchIn
                                    )
                                  }
                                  helperText={
                                    recordToEdit === 0 &&
                                    methods.errors.newPunchIn
                                      ? (
                                          methods.errors
                                            .newPunchIn as FieldError
                                        ).message
                                      : undefined
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </TableCell>

                            <TableCell>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                  disabled={recordToEdit !== 0 || !!loading}
                                  className={classes.timeField}
                                  mask="__:__ _M"
                                  name="newPunchOut"
                                  value={
                                    newPunchOut
                                      ? parse(
                                          newPunchOut,
                                          "hh:mm a",
                                          new Date()
                                        )
                                      : null
                                  }
                                  onChange={(_date, value) =>
                                    hadleNewFieldChange(
                                      "newPunchOut",
                                      value || ""
                                    )
                                  }
                                  error={
                                    !!(
                                      recordToEdit === 0 &&
                                      methods.errors.newPunchOut
                                    )
                                  }
                                  helperText={
                                    recordToEdit === 0 &&
                                    methods.errors.newPunchOut
                                      ? (
                                          methods.errors
                                            .newPunchOut as FieldError
                                        ).message
                                      : undefined
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </TableCell>
                            {isDistanceAttTrackingEnabled ?<TableCell>
                            <Checkbox
                                  value="uncontrolled"
                                  color="primary"
                                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                  onClick={(e: any) => {
                                    handleNewFieldDEChange(
                                      "isDistEdPunch",
                                      e.target.checked || false
                                    )
                                  }}
                                  checked={newPunchDE} 
                                /> D/E
                                </TableCell> : ""}
                            <TableCell>
                              <IconButton
                                className={classes.dialogCloseButton}
                                onClick={() => saveNewRecord()}
                                disabled={recordToEdit !== 0 || !!loading}
                              >
                                <DoneIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.mainTableCell,
                        classes.detailsTableCell
                      )}
                    >
                      <Table>
                        <TableHead className={classes.tableHeader}>
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              <Typography
                                className={clsx(
                                  classes.papaerSummaryTitleText,
                                  classes.paperSummarySchedulerTitle
                                )}
                                onClick={openPopUpScheudle}
                              >
                                Schedule
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.papaerSummaryValue}>
                              <Typography
                                component="div"
                                className={classes.papaerSummaryValueText}
                              >
                                :{" "}
                                {(!!loading &&
                                  record &&
                                  (
                                    ((!record?.scheduleId ||
                                      record.scheduleId === EmptyGuid) &&
                                    !scheduledHoursDirty
                                      ? props.scheduleHours
                                      : record?.scheduledHours) || 0
                                  ).toFixed(2)) || (
                                  <InputField
                                    disabled={!!loading}
                                    size="small"
                                    style={{ width: "42px" }}
                                    name="scheduledHours"
                                    type={InputType.NUMBER}
                                    inputProps={{
                                      style: { fontWeight: "bold" },
                                    }}
                                    decimal
                                    defaultValue={
                                      !record?.scheduleId ||
                                      record.scheduleId === EmptyGuid
                                        ? props.scheduleHours
                                        : record?.scheduledHours
                                    }
                                    error={
                                      methods.errors &&
                                      methods.errors.scheduledHours
                                    }
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      props.setSlotDirty(true);
                                      setRecord({
                                        ...record,
                                        scheduledHours: Number(e.target.value),
                                      });
                                      setScheduledHoursDirty(true);
                                    }}
                                    inputRef={methods.register({
                                      required: true,
                                    })}
                                  />
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              <Typography
                                className={classes.papaerSummaryTitleText}
                              >
                                Actual
                                {props.isUpdated ||
                                (record?.studentClockAttendanceHistory
                                  ?.length ?? 0) > 0 ? (
                                  <span className={classes.superIcon}>*</span>
                                ) : null}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.papaerSummaryValue}>
                              <Typography
                                className={classes.papaerSummaryValueText}
                              >
                                :{" "}
                                {record &&
                                  (record.actual || record.actual === 0) &&
                                  record.actual.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              <Typography
                                className={classes.papaerSummaryTitleText}
                              >
                                Absent
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.papaerSummaryValue}>
                              <Typography
                                className={classes.papaerSummaryValueText}
                              >
                                :{" "}
                                {record &&
                                  (record.absent || record.absent === 0) &&
                                  record.absent.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {record?.isSchoolUsingExcusedHours == true && (
                            <TableRow className={classes.tableRow}>
                              <TableCell>
                                <Typography
                                  className={classes.papaerSummaryTitleText}
                                >
                                  Excused
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.papaerSummaryValue}>
                                <Typography
                                  className={classes.papaerSummaryValueText}
                                >
                                  :{" "}
                                  {record &&
                                    (record.excusedHours ||
                                      record.excusedHours === 0) &&
                                    record.excusedHours.toFixed(2)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {isDistanceAttTrackingEnabled ? <TableRow className={classes.tableRow}>
                            <TableCell>
                              <Typography
                                className={classes.papaerSummaryTitleText}
                              >
                                DE
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.papaerSummaryValue}>
                              <Typography
                                className={classes.papaerSummaryValueText}
                              >
                                :{" "}
                                {record &&
                                  (record.distEdHours || record.distEdHours === 0) &&
                                  record.distEdHours.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow> : ""}
                          <TableRow className={classes.tableRow}>
                            <TableCell>
                              <Typography
                                className={classes.papaerSummaryTitleText}
                              >
                                Make Up
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.papaerSummaryValue}>
                              <Typography
                                className={classes.papaerSummaryValueText}
                              >
                                :{" "}
                                {record &&
                                  (record.makeUp || record.makeUp === 0) &&
                                  record.makeUp.toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
            <CardActions className={classes.dialogAction}>
              <ProgressSaveButton
                type={"button"}
                onClick={() => handleSave()}
                text="Save"
                loading={!!loading}
              />
              {props.statusCode !== "SUSP" &&
                props.statusCode !== "LOA" &&
                (!record?.attendancePunch ||
                  record.attendancePunch.length === 0) && (
                  <Button
                    disabled={!!loading}
                    onClick={postAbsent}
                    size="small"
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Post Absence
                  </Button>
                )}
            </CardActions>
          </Card>
          <Dialog
            open={!!(scheduleId.scheduleId && scheduleId.badgeId)}
            onClose={closeScheduleModal}
            PaperComponent={Paper}
            maxWidth="lg"
            fullWidth
            PaperProps={{ square: true }}
            aria-labelledby="attendance-dialog-title"
            aria-describedby="attendance-dialog-description"
          >
            <DialogTitle
              className={classes.dialogTitle}
              disableTypography
              id="attendance-dialog-title"
            >
              <IconButton
                aria-label="close"
                onClick={closeScheduleModal}
                className={classes.dialogCloseButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Schedule
                schedule={scheduleId}
                programVersion={scheduleId.programVersion}
                setSchedule={(scheduleInfo: EnrollmentSchedule) =>
                  setScheduleId({ ...scheduleInfo })
                }
                isNewEnrollment={false}
                disableSelection
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={confirmationDialog.open ? true : false}
            onClose={confirmationDialog.onCancel}
            PaperComponent={Paper}
            PaperProps={{ square: true }}
            aria-labelledby="attendance-dialog-title"
            aria-describedby="attendance-dialog-description"
          >
            <DialogTitle
              className={classes.dialogTitle}
              disableTypography
              id="attendance-dialog-title"
            >
              <Typography className={classes.dialogTitleText}>
                Warning
              </Typography>
              <IconButton
                aria-label="close"
                onClick={confirmationDialog.onCancel}
                className={classes.dialogCloseButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography>{confirmationDialog.message}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button
                onClick={confirmationDialog.onOk}
                size="small"
                type="submit"
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={confirmationDialog.onCancel}
                size="small"
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={reasonDialog.open ? true : false}
            onClose={reasonDialog.onCancel}
            PaperComponent={Paper}
            PaperProps={{ square: true }}
            aria-labelledby="attendance-reason-dialog-title"
            aria-describedby="attendance-reason-dialog-description"
          >
            <DialogTitle id="form-dialog-title">Reason</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are trying to add/update punches. Please provide reason for
                that.
              </DialogContentText>
              <InputField
                id="reason"
                name="reason"
                label="Reason *"
                defaultValue={
                  record
                    ? record.reason
                      ? record.reason
                      : undefined
                    : undefined
                }
                error={!!methods.errors.reason}
                inputRef={methods.register({
                  required: true,
                })}
                helperText={methods.errors.reason ? "Reason is required" : null}
                onBlur={(e: any) => {
                  handleFieldChange("reason", e ? e.target.value : undefined);
                }}
                type={InputType.TEXT}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={reasonDialog.onOk}
                size="small"
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={reasonDialog.onCancel}
                size="small"
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </FormContext>
    </React.Fragment>
  );
};

export default PostCreditAttendanceModal;
