import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
      color: "black",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab9 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>9. Grant Funds to be Returned</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    <b>
                      S. Initial amount of Title IV grants for student to return
                    </b>
                  </div>
                  <div>
                    Subtract the amount of loans to be repaid by the student
                    (Box R) from the initial amount of unearned Title IV aid due
                    from the student (Box Q).
                  </div>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box Q</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Box R</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt9BoxQ"
                            key="txt9BoxQ"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            disabled={override == true ? false : true}
                            defaultValue={model?.txt9BoxQ}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt9BoxQ", v.target.value);
                            }}
                            error={!!data.errors.txt9BoxQ}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt9BoxQ
                                ? (data.errors.txt9BoxQ as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt9BoxR"
                            key="txt9BoxR"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.txt9BoxR}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt9BoxR", v.target.value);
                            }}
                            error={!!data.errors.txt9BoxR}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt9BoxR
                                ? (data.errors.txt9BoxR as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxSResult"
                            key="boxSResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxSResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxSResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>S.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxSResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxSResult
                                ? (data.errors.boxSResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    <b>T. Amount of Title IV grant protection</b>
                  </div>
                  <div>
                  Multiply the total of Title IV grant aid that was disbursed and could have been disbursed for the payment period or period of enrollment (Box F) by 50%
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box F</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt9BoxF"
                            key="txt9BoxF"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.txt9BoxF}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt9BoxF", v.target.value);
                            }}
                            error={!!data.errors.txt9BoxF}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt9BoxF
                                ? (data.errors.txt9BoxF as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell align="center">
                         <b>50%</b>
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxTResult"
                            key="boxTResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxTResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxTResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>T.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxTResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxTResult
                                ? (data.errors.boxTResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    <b>U. Title IV grant funds for student to return</b>
                  </div>
                  <div>
                  From the Inital amount of Title IV grants for student to return (Box S) subtract the Amount of Title IV grant protection(Box T)
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box S</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Box T</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt9Boxs"
                            key="txt9Boxs"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.txt9Boxs}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt9Boxs", v.target.value);
                            }}
                            error={!!data.errors.txt9Boxs}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt9Boxs
                                ? (data.errors.txt9Boxs as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt9BoxT"
                            key="txt9BoxT"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.txt9BoxT}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt9BoxT", v.target.value);
                            }}
                            error={!!data.errors.txt9BoxT}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt9BoxT
                                ? (data.errors.txt9BoxT as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxUResult"
                            key="boxUResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxUResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxUResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>U.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxUResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxUResult
                                ? (data.errors.boxUResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab9;
