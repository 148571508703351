import { EmptyGuid } from "../../../utils/constants";

export class IR2T4Input {
  constructor() {
    this.r2T4InputId = EmptyGuid;
    this.terminationId = "";
    this.boardFee = 0;
    this.completedTime = 0;
    this.createdBy = EmptyGuid;
    this.createdDate = null;
    this.creditBalanceRefunded = 0;
    this.directGraduatePlusLoanCouldDisbursed = 0;
    this.directGraduatePlusLoanDisbursed = 0;
    this.directParentPlusLoanCouldDisbursed = 0;
    this.directParentPlusLoanDisbursed = 0;
    this.fseogCouldDisbursed = 0;
    this.fseogDisbursed = 0;
    this.iraqAfgGrantCouldDisbursed = 0;
    this.iraqAfgGrantDisbursed = 0;
    this.isAttendanceNotRequired = false;
    this.isTuitionChargedByPaymentPeriod = false;
    this.otherFee = 0;
    this.pellGrantCouldDisbursed = 0;
    this.pellGrantDisbursed = 0;
    this.perkinsLoanCouldDisbursed = 0;
    this.perkinsLoanDisbursed = 0;
    this.programUnitTypeId = 1;
    this.isSelfPaceProgram = false;
    this.roomFee = 0;
    this.scheduledEndDate = null;
    this.startDate = null;
    this.subLoanNetAmountCouldDisbursed = 0;
    this.subLoanNetAmountDisbursed = 0;
    this.teachGrantCouldDisbursed = 0;
    this.teachGrantDisbursed = 0;
    this.totalTime = 0;
    this.tuitionFee = 0;
    this.unsubLoanNetAmountCouldDisbursed = 0;
    this.unsubLoanNetAmountDisbursed = 0;
    this.updatedBy = "";
    this.updatedDate = null;
    this.withdrawalDate = null;
    this.updatedByFullName = "";
    this.resultStatus = "";
    this.isR2T4InputCompleted = false;
    this.paymentType = 1;
    this.ticketNumber = null;
    this.withdrawalReasonCode = "";
    this.isOverriden = false;
    this.isTitleIvAuthorized = false;
    this.nonInstitutionalBooksAndSupplies = 0;
    this.isR2T4ChargesByPaymentPeriod = false;
  }

  r2T4InputId: string = "";
  terminationId: string = "";
  boardFee: number = 0;
  completedTime: number = 0;
  createdBy: string = "";
  createdDate: Date | null = null;
  creditBalanceRefunded: number = 0;
  directGraduatePlusLoanCouldDisbursed: number = 0;
  directGraduatePlusLoanDisbursed: number = 0;
  directParentPlusLoanCouldDisbursed: number = 0;
  directParentPlusLoanDisbursed: number = 0;
  fseogCouldDisbursed: number = 0;
  fseogDisbursed: number = 0;
  iraqAfgGrantCouldDisbursed: number = 0;
  iraqAfgGrantDisbursed: number = 0;
  isAttendanceNotRequired: boolean = false;
  isTuitionChargedByPaymentPeriod: boolean = false;
  otherFee: number = 0;
  pellGrantCouldDisbursed: number = 0;
  pellGrantDisbursed: number = 0;
  perkinsLoanCouldDisbursed: number = 0;
  perkinsLoanDisbursed: number = 0;
  programUnitTypeId: number = 1;
  isSelfPaceProgram: boolean = false;
  roomFee: number = 0;
  scheduledEndDate: Date | null = null;
  startDate: Date | null = null;
  subLoanNetAmountCouldDisbursed: number = 0;
  subLoanNetAmountDisbursed: number = 0;
  teachGrantCouldDisbursed: number = 0;
  teachGrantDisbursed: number = 0;
  totalTime: number = 0;
  tuitionFee: number = 0;
  unsubLoanNetAmountCouldDisbursed: number = 0;
  unsubLoanNetAmountDisbursed: number = 0;
  updatedBy: string = "";
  updatedDate: Date | null = null;
  withdrawalDate: Date | null = null;
  updatedByFullName: string = "";
  resultStatus: string = "";
  isR2T4InputCompleted: boolean = false;
  paymentType: number = 1;
  ticketNumber: number | null;
  withdrawalReasonCode: string = "";
  isOverriden: boolean = false;
  isTitleIvAuthorized: boolean = false;
  nonInstitutionalBooksAndSupplies: number = 0;
  isR2T4ChargesByPaymentPeriod: boolean = false;
}

export interface IR2T4Step1Input {
  pellGrantDisbursed: number;
  pellGrantCouldDisbursed: number;
  fseogDisbursed: number;
  fseogCouldDisbursed: number;
  teachGrantDisbursed: number;
  teachGrantCouldDisbursed: number;
  iraqAfgGrantDisbursed: number;
  iraqAfgGrantCouldDisbursed: number;
  unsubLoanNetAmountDisbursed: number;
  unsubLoanNetAmountCouldDisbursed: number;
  subLoanNetAmountDisbursed: number;
  subLoanNetAmountCouldDisbursed: number;
  perkinsLoanDisbursed: number;
  perkinsLoanCouldDisbursed: number;
  directGraduatePlusLoanDisbursed: number;
  directGraduatePlusLoanCouldDisbursed: number;
  directParentPlusLoanDisbursed: number;
  directParentPlusLoanCouldDisbursed: number;
  step5Inputs: IStep5Inputs;
}

export interface IStep5Inputs {
  titleIVCreditBalanceRefunded: number;
  tuition: number;
  room: number;
  board: number;
  other: number;
  isTuitionChargedByCourse: boolean;
  isTuitionChargedByPaymentPeriod: boolean;
  nonInstitutionalBooksAndSupplies: number;
}

export interface IProgramVersionDetail {
  programVersionId: number;
  programId: number;
  academicCalendarId: number;
  withdrawalDate: number;
  campusId: number;
}

export interface IScheduleHoursToComplete {
  totalHoursInPeriod: number;
  hoursScheduledToComplete: number;
}

export interface IStudentTermination {
  terminationId: string;
  studentEnrollmentId: string;
  statusCodeId: string;
  dropReasonId: string;
  dateWithdrawalDetermined: Date | undefined;
  lastDateAttended: Date | undefined;
  isPerformingR2T4Calculator: boolean;
  calculationPeriodType: string;
  resultStatus: string;
  methodType: number;
  isR2T4ApproveTabEnabled: boolean;
  isTerminationReversed: boolean;
  rightToCancelNoticeGivenToStudent: number;
}

export interface IStudentEnrollments {
  dateDetermined: Date;
  effectiveDate: Date;
  enrollmentDate: Date;
  lastDateAttended: Date;
  programVersionDescription: string;
  startDate: Date;
  status: string;
  statusCode: string;
  statusCodeDescription: string;
  enrollmentId: string;
  studentId: string;
  unitTypeDescription: string;
  campusId: string;
  ssn: string;
  systemStatusId: string;
  resultStatus: string;
  dropReasonId: string;
  statusCodeId: string;
  prgVerId: string;
  transferHours: number;
  transferDate: Date;
  expectedGraduationDate: Date;
  calculationPeriodTypeId: string;
  firstName: string;
  lastName: string;
  studentNumber: string;
  enrollmentNewStatus: string;
  enrollmentNewStatusCodeId: string;
  isHoursCompleted: boolean;
  isAcademicReqCompleted: boolean;
  isNoLedgerBalance: boolean;
  isCreditHour: boolean;
}

export interface IR2T4Result {
  r2T4ResultsId: string;
  terminationId: string;
  subTotalAmountDisbursedA: string;
  subTotalAmountCouldDisbursedC: string;
  subTotalNetAmountDisbursedB: string;
  subTotalNetAmountDisbursedD: string;
  boxEResult: string;
  boxFResult: string;
  boxGResult: string;
  percentageOfActualAttendance: string;
  boxHResult: string;
  boxIResult: string;
  boxJResult: string;
  boxKResult: string;
  boxLResult: string;
  boxMResult: string;
  boxNResult: string;
  boxOResult: string;
  unsubDirectLoanSchoolReturn: string;
  subDirectLoanSchoolReturn: string;
  perkinsLoanSchoolReturn: string;
  directGraduatePlusLoanSchoolReturn: string;
  directParentPlusLoanSchoolReturn: string;
  boxPResult: string;
  pellGrantSchoolReturn: string;
  fseogSchoolReturn: string;
  teachGrantSchoolReturn: string;
  iraqAfgGrantSchoolReturn: string;
  boxQResult: string;
  boxRResult: string;
  boxSResult: string;
  boxTResult: string;
  boxUResult: string;
  pellGrantAmountToReturn: string;
  fseogAmountToReturn: string;
  teachGrantAmountToReturn: string;
  iraqAfgGrantAmountToReturn: string;
  boardFee: string;
  completedTime: number;
  directGraduatePlusLoanCouldDisbursed: string;
  directGraduatePlusLoanDisbursed: string;
  directParentPlusLoanCouldDisbursed: string;
  directParentPlusLoanDisbursed: string;
  fseogCouldDisbursed: string;
  fseogDisbursed: string;
  iraqAfgGrantCouldDisbursed: string;
  iraqAfgGrantDisbursed: string;
  otherFee: string;
  pellGrantCouldDisbursed: string;
  pellGrantDisbursed: number;
  perkinsLoanCouldDisbursed: number;
  perkinsLoanDisbursed: number;
  roomFee: string;
  scheduledEndDate: Date | null;
  startDate: Date | null;
  subLoanNetAmountCouldDisbursed: string;
  subLoanNetAmountDisbursed: string;
  teachGrantCouldDisbursed: string;
  teachGrantDisbursed: string;
  totalTime: number;
  tuitionFee: string;
  unsubLoanNetAmountCouldDisbursed: string;
  unsubLoanNetAmountDisbursed: string;
  withdrawalDate: Date | null;
  postWithdrawalData: string;
  overriddenData: string;
  isR2T4ResultsCompleted: boolean;
  isTuitionChargedByPaymentPeriod: boolean;
  box5LResult: string;
}


export interface ITerminationResult {
  r2T4ResultsId: string;
  terminationId: string;
  subTotalAmountDisbursedA: string;
  subTotalAmountCouldDisbursedC: string;
  subTotalNetAmountDisbursedB: string;
  subTotalNetAmountDisbursedD: string;
  boxEResult: string;
  boxFResult: string;
  boxGResult: string;
  percentageOfActualAttendance: string;
  boxHResult: string;
  boxIResult: string;
  boxJResult: string;
  boxKResult: string;
  boxLResult: string;
  boxMResult: string;
  boxNResult: string;
  boxOResult: string;
  unsubDirectLoanSchoolReturn: string;
  subDirectLoanSchoolReturn: string;
  perkinsLoanSchoolReturn: number;
  directGraduatePlusLoanSchoolReturn: string;
  directParentPlusLoanSchoolReturn: string;
  boxPResult: string;
  pellGrantSchoolReturn: string;
  fseogSchoolReturn: string;
  teachGrantSchoolReturn: string;
  iraqAfgGrantSchoolReturn: string;
  boxQResult: string;
  boxRResult: string;
  boxSResult: string;
  boxTResult: string;
  boxUResult: string;
  pellGrantAmountToReturn: string;
  fseogAmountToReturn: string;
  teachGrantAmountToReturn: string;
  iraqAfgGrantAmountToReturn: string;
  boardFee: number;
  completedTime: number;
  directGraduatePlusLoanCouldDisbursed: number;
  directGraduatePlusLoanDisbursed: number;
  directParentPlusLoanCouldDisbursed: number;
  directParentPlusLoanDisbursed: number;
  fseogCouldDisbursed: number;
  fseogDisbursed: number;
  iraqAfgGrantCouldDisbursed: number;
  iraqAfgGrantDisbursed: number;
  isAttendanceNotRequired: boolean;
  otherFee: number;
  pellGrantCouldDisbursed: number;
  pellGrantDisbursed: number;
  perkinsLoanCouldDisbursed: number;
  creditBalanceRefunded: number;
  perkinsLoanDisbursed: number;
  roomFee: number;
  scheduledEndDate: Date | null;
  startDate: Date | null;
  subLoanNetAmountCouldDisbursed: number;
  subLoanNetAmountDisbursed: number;
  teachGrantCouldDisbursed: number;
  teachGrantDisbursed: number;
  totalTime: number;
  tuitionFee: number;
  unsubLoanNetAmountCouldDisbursed: number;
  unsubLoanNetAmountDisbursed: number;
  withdrawalDate: Date | null;
  isInputIncluded: boolean;
  createdById: string;
  programUnitTypeId: number;
  createdDate: Date;
  updatedById: string;
  updatedDate: Date;
  ticketNumber: number | null;
  isoverride: boolean;
  updatedByFullName: string;
  resultStatus: string;
  postWithdrawalData: string;
  overriddenData: string;
  isR2T4ResultsCompleted: boolean;
  isTuitionChargedByPaymentPeriod: boolean;
  isR2T4OverrideResultsCompleted: boolean;
  txtEA: string;
  txtEB: string;
  txtFA: string;
  txtFC: string;
  txtStep1FA: string;
  txtStep1FB: string;
  txtStep1FC: string;
  txtStep1FD: string;
  txtBoxH: string;
  txtBoxG: string;
  txt4JI: string;
  txt4JE: string;
  txt4KE: string;
  txt4KI: string;
  txt5BoxH: string;
  txt5BoxL: string;
  txt5BoxM: string;
  txt7K: string;
  txt7O: string;
  txt8boxB: string;
  txt8P: string;
  txt9BoxQ: string;
  txt9BoxR: string;
  txt9BoxF: string;
  txt9Boxs: string;
  txt9BoxT: string;
  txtPWD: string;
  txtPWDBox2: string;
  txtPWDOffered: string;
  txtPWDBox2Offered: string;
  txtPWDBox3: string;
  pwdPell3: string;
  pwdPell6: string;
  pwdFSEOG3: string;
  pwdFSEOG6: string;
  pwdTeach3: string;
  pwdTeach6: string;
  pwdIASG3: string;
  pwdIASG6: string;
  pwdPerkins1: string;
  pwdPerkins2: string;
  pwdPerkins3: string;
  pwdPerkins4: string;
  pwdPerkins5: string;
  pwdPerkins6: string;
  pwdSub1: string;
  pwdSub2: string;
  pwdSub3: string;
  pwdSub4: string;
  pwdSub5: string;
  pwdSub6: string;
  pwdUnSub1: string;
  pwdUnSub2: string;
  pwdUnSub3: string;
  pwdUnSub4: string;
  pwdUnSub5: string;
  pwdUnSub6: string;
  pwdGrad1: string;
  pwdGrad2: string;
  pwdGrad3: string;
  pwdGrad4: string;
  pwdGrad5: string;
  pwdGrad6: string;
  pwdParent1: string;
  pwdParent2: string;
  pwdParent3: string;
  pwdParent4: string;
  pwdParent5: string;
  pwdParent6: string;
  pwdTotal1: string;
  pwdTotal2: string;
  pwdTotal3: string;
  pwdTotal4: string;
  pwdTotal5: string;
  pwdTotal6: string;
  dtPostWithdrwal: Date | null;
  dtDeadline: Date | null;
  chkResponseReceived: boolean;
  dtResponseReceived: Date | null;
  chkResponseNotReceived: boolean;
  chkNotAccept: boolean;
  dtGrantTransferred: string;
  dtLoanTransferred: string;
  studentInfo: string;
  box5LResult: string;
}

export interface IStudentLedger {
  transactionDate: Date;
  transactionCode: string;
  transactionDescription: string;
  period: string;
  amount: number;
  balance: number;
}

export interface IEvaluatedPolicies {
  evaluatedPolicies: IEvaluatedPolicy[];
}

export interface IEvaluatedPolicy {
  refundPolicyDescription: string;
  refundPeriodDescription: string;
  ruleNumberApplicableToStudent: string;
  withdrawalPeriod: string;
  programCompletion: string;
  programCompletionRule: string;
  amountEarnedAsPerRule: string;
  ruleApplicableMessage: string;
  earnedCalculationMessage: string;
  fixedAmountEarned: number;
  totalEarned: number;
  currentBalance: number;
  returnToTitleIV: number;
  postWithdrawalDisbursement: number;
  unearnedCharges: number;
  terminationFee: number;
  newStudentBalance: number;
  totalPostedCharges: number;
  isChosenPolicy: boolean;
  unearnedChargesTransactionCodeId: number;
  terminationTransactionCodeId: string;
  terminationTransactionAmount: number;
  institutionalRefundPolicyRuleId: number;
  charges: ICharges[];
  programCompletionRequiredByRuleAppliction: string;
  calculatedProgramCompletionForStudent: string;
  amountEarnedCalculationType: number;
  isFullRefund: boolean;
  separateEachChargeFullRefund: boolean;
  totalTuitionChargesPosted: number;
  totalTuitionChargesEarned: number;
  tuitionChargesEarnedPercentage: number;
  totalRegistrationAndApplicationFeeChargesPosted: number;
  totalRegistrationAndApplicationFeeChargesEarned: number;
  registrationAndApplicationFeeChargesEarnedPercentage: number;
  totalKitsAndBooksChargesPosted: number;
  totalKitsAndBooksChargesEarned: number;
  kitsAndBooksChargesEarnedPercentage: number;
  totalSalesTaxChargesPosted: number;
  totalSalesTaxChargesEarned: number;
  salesTaxChargesEarnedPercentage: number;
  totalOtherFeesChargesPosted: number;
  totalOtherFeesChargesEarned: number;
  otherFeesChargesEarnedPercentage: number;
  totalChargesEarnedAndTerminationFee: number;
  paymentsByStudent: number;
  paymentsFinancialAid: number;
  paymentsVAandScholarship: number;
  refundsToStudent: number;
  refundsToDeptOfEducation: number;
  totalPaymentsAndRefunds: number;
  totalDueToSchool: number;
  totalRefundToStudent: number;
  titleIVGrants: number;
  titleIVLoans: number;
  isAllPoliciesAreByProgramVersion: boolean;
  unsubDirectLoanSchoolReturn: number;
  subDirectLoanSchoolReturn: number;
  perkinsLoanSchoolReturn: number;
  directGraduatePlusLoanSchoolReturn: number;
  directParentPlusLoanSchoolReturn: number;
  pellGrantSchoolReturn: number;
  fseogSchoolReturn: number;
  teachGrantSchoolReturn: number;
  iraqAfgGrantSchoolReturn: number;
  hoursCompleted: number;
  programCompletionPercentage: string;
  policyId: string;
  chargesEarnedForNextPeriods: number;
  doesSchoolRetainsAnyPercentOfUnearnedCharges: boolean;
  percentOfUnearnedCharges: number;
  maximumRetainOnUnearnedCharges: number;
}

export interface ICharges {
  category: string;
  transCodeId: string;
  postedCharges: number;
  postedChargesAndFutureCharges: number;
  chargesEarnedForCompletedPeriods: number;
  chargesEarnedForLastPeriod: number;
  cumulativeChargesEarned: number;
  sysTransCodeId: number;
  percentageToCalculateChargesEarned: number;
}

export interface ITerminationDetail {
  leadId: string;
  studentName: string;
  studentSsn: string;
  studentIdentifier: string;
  studentIdentifierId: string;
  campusId: string;
  enrollmentName: string;
  status: string;
  dropReason: string;
  lastDateAttended: Date;
  withdrawalDate: Date;
  dateOfDetermination: Date;
  isR2T4: boolean;
  isAdditionalInfoRequired: boolean;
  unitTypeDescription: string;
  r2T4CalculationSummaryDetail: IR2T4CalculationSummary;
  additionalInformationDetail: IR2T4AdditionalDetail;
}

export interface ITerminationEnrollment {
  enrollmentId: string;
  statusCodeId: string;
  dateDetermined: Date;
  lastDateAttended: Date;
  calculationPeriodType: string;
  dropReasonId: string;
  isPerformingR2T4Calculator: boolean;
  terminationId: string;
  updatedBy: string;
  createdBy: string;
}

export interface ITerminationStatusChange {
  studentEnrollmentId: string;
  campusId: string;
  newStatusId: string;
  dropReasonId: string;
  isReversal: boolean;
  dateOfChange: Date;
  originalStatusId: string;
  isTransferred: boolean;
  toProgram: string | null;
  toProgramVersion: string | null;
  transferHours: number | null;
  transferDate: string | null;
}

export interface IList {
  value: string;
  text: string;
}

export interface IR2T4CalculationSummary {
  totalCharges: string;
  totalTitleIvAid: string;
  totalTitleIvAidDisbursed: string;
  percentageOfTitleIvAidEarned: string;
  postWithdrawalDisbursement: string;
  totalTitleIvAidEarned: string;
  totalTitleIvAidToReturn: string;
  amountToBeReturnedBySchool: IList[];
  totalAmountToBeReturnedBySchool: string;
  amountToBeReturnedByStudent: IList[];
  totalAmountToBeReturnedByStudent: string;
  r2T4ResultsBoxP: string;
  r2T4ResultsBoxO: string;
  unsubDirectLoanSchoolReturn: string;
  subDirectLoanSchoolReturn: string;
  perkinsLoanSchoolReturn: number;
  directGraduatePlusLoanSchoolReturn: number;
  directParentPlusLoanSchoolReturn: number;
  pellGrantSchoolReturn: number;
  fseogSchoolReturn: number;
  teachGrantSchoolReturn: number;
  iraqAfgGrantSchoolReturn: number;
}
export interface IR2T4AdditionalDetail {
  r2T4InputUserName: string;
  overriddenUserName: string;
  ticketNumber: string;
  titleIvGrantLessThan50Dollar: any[];
  r2T4ResultFieldsWithOverriddenValues: IOverriddenListItem[];
  isNotRequiredToTakeAattendance: boolean;
  isAttendance100Percent: boolean;
  isClockHour: boolean;
  isAdditionalInfoRequired: boolean;
  isTuitionByPaymentPeriod: boolean;
  creditBalanceRefunded: string;
  calculationPeriodTypeId: string;
  calculationPeriodType: string;
  isAdditionalInfoStep3Required: boolean;
  overriddenUserNameInput: string;
  ticketNumberInput: number;
  r2T4InputFieldsWithOverriddenValues: IOverriddenListItem[];
  overlappingLoanDetails: IOverlappingLoanDetails[];
  withdrawalPeriodBegin: Date;
  withdrawalPeriodEnd: Date
  withdrawalPaymentPeriod: number;
  withdrawalDueToCovid: boolean;
  postWithdrawalDisbursementAmount: string;
  totalAmountCouldHaveBeenDisbursed: number;
  aidBeforeDateOfDetermination: IAdditionalInformationAidRecord[];
}
export interface IAdditionalInformationAidRecord
{
  aid: string;
  disbursementPeriod: string;
  disbursementDate: string;
  disbursementAmount: string;
}
export interface IOverlappingLoanDetails
{
  loanPeriodBegin: string;
  loanPeriodEnd: string;
  fundSourceDescription: string;
}
export interface IOverriddenListItem
{
  fieldName: string;
  resultValue: string;
  overriddenResultValue: string;
}

export interface IFutureCharges {
    futureChargeId: string;
    terminationId: string;
    transCodeId: string;
    transCode: string;
    amount: number | null;
    transactionPeriodInfo: ITransactionPeriodInfo | undefined;
}

export interface ITransactionPeriodInfo {
    transactionPeriodInfoId: string;
    periodTypeId: number | null;
    periodType: string;
    period: number | null;
    begin: number | null;
    end: number | null;
}

export interface IPostWithdrawalData {
  txtPWD: string;
  txtPWDBox2: string;
  txtPWDOffered: string;
  txtPWDBox2Offered: string;
  txtPWDBox3: string;
  pwdPell3: string;
  pwdPell6: string;
  pwdFSEOG3: string;
  pwdFSEOG6: string;
  pwdTeach3: string;
  pwdTeach6: string;
  pwdIASG3: string;
  pwdIASG6: string;
  pwdPerkins1: string;
  pwdPerkins2: string;
  pwdPerkins3: string;
  pwdPerkins4: string;
  pwdPerkins5: string;
  pwdPerkins6: string;
  pwdSub1: string;
  pwdSub2: string;
  pwdSub3: string;
  pwdSub4: string;
  pwdSub5: string;
  pwdSub6: string;
  pwdUnSub1: string;
  pwdUnSub2: string;
  pwdUnSub3: string;
  pwdUnSub4: string;
  pwdUnSub5: string;
  pwdUnSub6: string;
  pwdGrad1: string;
  pwdGrad2: string;
  pwdGrad3: string;
  pwdGrad4: string;
  pwdGrad5: string;
  pwdGrad6: string;
  pwdParent1: string;
  pwdParent2: string;
  pwdParent3: string;
  pwdParent4: string;
  pwdParent5: string;
  pwdParent6: string;
  pwdTotal1: string;
  pwdTotal2: string;
  pwdTotal3: string;
  pwdTotal4: string;
  pwdTotal5: string;
  pwdTotal6: string;
  dtPostWithdrwal: Date | null;
  dtDeadline: Date | null;
  chkResponseReceived: boolean;
  dtResponseReceived: Date | null;
  chkResponseNotReceived: boolean;
  chkNotAccept: boolean;
  dtGrantTransferred: string;
  dtLoanTransferred: string;
}

export interface IOverriddenData {
  txtEA: string;
  txtEB: string;
  txtFA: string;
  txtFC: string;
  txtStep1FA: string;
  txtStep1FB: string;
  txtStep1FC: string;
  txtStep1FD: string;
  txtBoxH: string;
  txtBoxG: string;
  txt4JI: string;
  txt4JE: string;
  txt4KE: string;
  txt4KI: string;
  txt5BoxH: string;
  txt5BoxL: string;
  txt5BoxM: string;
  txt7K: string;
  txt7O: string;
  txt8boxB: string;
  txt8P: string;
  txt9BoxQ: string;
  txt9BoxR: string;
  txt9BoxF: string;
  txt9Boxs: string;
  txt9BoxT: string;
};


export interface IPeriodTypes {
  value: string;
  text: string;
}

export interface ITerminationReportProps {
  CampusId: string;
  StudentTerminationId: string;
  isPreviewReport: boolean;
  reportCategory: string;
  reportExtension: string;
  reportName: string;
}

interface IInstitutionalCharge {
  chargeName: string;
  amountCharged: string;
}

interface IGrant {
  grantName: string;
  amountDisbursed: string;
  amountExpected: string;
  priority: string;
  amountToBeReturned: string;
  amountToBeReturnedByStudent: string;
}

interface ILoan {
  loanName: string;
  amountDisbursed: string;
  amountExpected: string;
  priority: string;
  amountToBeReturned: string;
}

interface IStepResults {
  BoxA: string;
  BoxB: string;
  BoxC: string;
  BoxD: string;
  BoxE: string;
  BoxF: string;
  BoxG: string;
  BoxH: string;  
  BoxI: string;
  BoxJ: string;
  BoxK: string;  
  BoxL: string;
  BoxM: string;
  BoxN: string;
  BoxO: string;
  BoxP: string;  
  BoxQ: string;
  BoxR: string;
  BoxS: string;
  BoxT: string;
  BoxU: string;
  Pell: string;
  Box5LResult: string;
  ActualAttendence: string;
  TotalinstitutionalCharges: string;
  UnsubsidizedFFELDirectStaffordLoan: string;
  SubsidizedFFELDirectStaffordLoan: string;
  FFELDirectPLUSStudent: string;
  FFELDirectPLUSParent: string;
  TEACHGrant: string;
  FSEOG: string;
  IraqAfghanistanServiceGrant: string;
}

interface IResults {
  validationResult: number;
  validationMessage: string;
  stepResults: IStepResults;
  inputResults: any;
}

export interface IStudentFinancialData {
  student: {
    studentName: string | null;
    ssn: string | null;
    withdrawDate: string;
    scheduledHours: number;
    totalHours: number;
    scheduledDays: number;
    totalDays: number;
    isAttendanceRequired: boolean;
    institutionalCharges: IInstitutionalCharge[];
    isTutionCharged: boolean;
    creditBalanceRefunded: number;
    withdrawalReasonCode: string;
  };
  grants: IGrant[];
  loans: ILoan[];
  results: IResults;
  programType: number;
  paymentType: number;
  isCalculationRequired: boolean;
  isStep4Required: boolean;
  isStep9Required: boolean;
  isStep10Required: boolean;
  roomAndBoardAmount: string;
  isTitleIvAuthorized: boolean;
  nonInstitutionalBooksAndSupplies: number;
  titleIVStipends: number;
  isR2T4ChargesByPaymentPeriod: boolean;
}


export function mapR2T4Result(source: IStudentFinancialData, input: IR2T4Input): ITerminationResult {
  let result: ITerminationResult = {
      r2T4ResultsId: "",
      terminationId: "",
      subTotalAmountDisbursedA: source.results?.stepResults.BoxA,
      subTotalAmountCouldDisbursedC: source.results?.stepResults.BoxC,
      subTotalNetAmountDisbursedB: source.results?.stepResults.BoxB,
      subTotalNetAmountDisbursedD: source.results?.stepResults.BoxD,
      boxEResult: source.results?.stepResults.BoxE,
      boxFResult: source.results?.stepResults.BoxF,
      boxGResult: source.results?.stepResults.BoxG,
      percentageOfActualAttendance: source.results?.stepResults.ActualAttendence,
      boxHResult: source.results?.stepResults.BoxH,
      boxIResult: source.results?.stepResults.BoxI,
      boxJResult: "",
      boxKResult: "",
      boxLResult: "",
      boxMResult: "",
      boxNResult: "",
      boxOResult: "",
      unsubDirectLoanSchoolReturn: source.results?.stepResults.UnsubsidizedFFELDirectStaffordLoan,
      subDirectLoanSchoolReturn: source.results?.stepResults.SubsidizedFFELDirectStaffordLoan,
      perkinsLoanSchoolReturn: 0,
      directGraduatePlusLoanSchoolReturn: source.results?.stepResults.FFELDirectPLUSStudent,
      directParentPlusLoanSchoolReturn: source.results?.stepResults.FFELDirectPLUSParent,
      boxPResult: source.results?.stepResults.BoxP,
      pellGrantSchoolReturn: source.results?.stepResults.Pell,
      fseogSchoolReturn: source.results?.stepResults.FSEOG,
      teachGrantSchoolReturn:  source.results?.stepResults.TEACHGrant,
      iraqAfgGrantSchoolReturn: source.results?.stepResults.IraqAfghanistanServiceGrant,
      boxQResult: source.results?.stepResults.BoxQ,
      boxRResult: "",
      boxSResult: "",
      boxTResult: "",
      boxUResult: "",
      pellGrantAmountToReturn: "",
      fseogAmountToReturn: "",
      teachGrantAmountToReturn: "",
      iraqAfgGrantAmountToReturn: "",
      boardFee: input?.boardFee,
      completedTime: input?.completedTime,
      directGraduatePlusLoanCouldDisbursed:  input?.directGraduatePlusLoanCouldDisbursed,
      directGraduatePlusLoanDisbursed: input?.directGraduatePlusLoanDisbursed,
      directParentPlusLoanCouldDisbursed:  input?.directParentPlusLoanCouldDisbursed,
      directParentPlusLoanDisbursed: input?.directParentPlusLoanDisbursed,
      fseogCouldDisbursed: input?.fseogCouldDisbursed,
      fseogDisbursed: input?.fseogDisbursed,
      iraqAfgGrantCouldDisbursed: input?.iraqAfgGrantCouldDisbursed,
      iraqAfgGrantDisbursed: input?.iraqAfgGrantDisbursed,
      isAttendanceNotRequired: source.student?.isAttendanceRequired === false,
      otherFee: input?.otherFee,
      pellGrantCouldDisbursed: input?.pellGrantCouldDisbursed,
      pellGrantDisbursed: input?.pellGrantDisbursed,
      perkinsLoanCouldDisbursed: 0,
      creditBalanceRefunded: source.student?.creditBalanceRefunded,
      perkinsLoanDisbursed: 0,
      roomFee: input?.roomFee,
      scheduledEndDate: null,
      startDate: null,
      subLoanNetAmountCouldDisbursed: input?.subLoanNetAmountCouldDisbursed,
      subLoanNetAmountDisbursed:  input?.subLoanNetAmountDisbursed,
      teachGrantCouldDisbursed: input?.teachGrantCouldDisbursed,
      teachGrantDisbursed: input?.teachGrantDisbursed,
      totalTime: input?.totalTime,
      tuitionFee: input?.tuitionFee,
      unsubLoanNetAmountCouldDisbursed: input?.unsubLoanNetAmountCouldDisbursed,
      unsubLoanNetAmountDisbursed: input?.unsubLoanNetAmountDisbursed,
      withdrawalDate: input?.withdrawalDate,
      isInputIncluded: false,
      createdById: "",
      programUnitTypeId: source.programType,
      createdDate: new Date(),
      updatedById: "",
      updatedDate: new Date(),
      ticketNumber: null,
      isoverride: false,
      updatedByFullName: "",
      resultStatus: "",
      postWithdrawalData: "",
      overriddenData: "",
      isR2T4ResultsCompleted: false,
      isTuitionChargedByPaymentPeriod: false,
      isR2T4OverrideResultsCompleted: false,
      txtEA: source.results?.stepResults.BoxA,
      txtEB: source.results?.stepResults.BoxB,
      txtFA: source.results?.stepResults.BoxA,
      txtFC: source.results?.stepResults.BoxC,
      txtStep1FA: "",
      txtStep1FB: "",
      txtStep1FC: "",
      txtStep1FD: "",
      txtBoxH: "",
      txtBoxG: "",
      txt4JI: "",
      txt4JE: "",
      txt4KE: "",
      txt4KI: "",
      txt5BoxH: "",
      txt5BoxL: "",
      txt5BoxM: "",
      txt7K: source.results?.stepResults?.BoxK,
      txt7O: source.results?.stepResults?.BoxO,
      txt8boxB: "",
      txt8P: "",
      txt9BoxQ: "",
      txt9BoxR: "",
      txt9BoxF: "",
      txt9Boxs: "",
      txt9BoxT: "",
      txtPWD: source.results?.stepResults?.BoxJ,
      txtPWDBox2: "",
      txtPWDOffered: source.results?.stepResults?.BoxJ,
      txtPWDBox2Offered: "",
      txtPWDBox3: "",
      pwdPell3: "",
      pwdPell6: "",
      pwdFSEOG3: "",
      pwdFSEOG6: "",
      pwdTeach3: "",
      pwdTeach6: "",
      pwdIASG3: "",
      pwdIASG6: "",
      pwdPerkins1: "",
      pwdPerkins2: "",
      pwdPerkins3: "",
      pwdPerkins4: "",
      pwdPerkins5: "",
      pwdPerkins6: "",
      pwdSub1: "",
      pwdSub2: "",
      pwdSub3: "",
      pwdSub4: "",
      pwdSub5: "",
      pwdSub6: "",
      pwdUnSub1: "",
      pwdUnSub2: "",
      pwdUnSub3: "",
      pwdUnSub4: "",
      pwdUnSub5: "",
      pwdUnSub6: "",
      pwdGrad1: "",
      pwdGrad2: "",
      pwdGrad3: "",
      pwdGrad4: "",
      pwdGrad5: "",
      pwdGrad6: "",
      pwdParent1: "",
      pwdParent2: "",
      pwdParent3: "",
      pwdParent4: "",
      pwdParent5: "",
      pwdParent6: "",
      pwdTotal1: "",
      pwdTotal2: "",
      pwdTotal3: "",
      pwdTotal4: "",
      pwdTotal5: "",
      pwdTotal6: "",
      dtPostWithdrwal: null,
      dtDeadline: null,
      chkResponseReceived: false,
      dtResponseReceived: null,
      chkResponseNotReceived: false,
      chkNotAccept: false,
      dtGrantTransferred: "",
      dtLoanTransferred: "",
      studentInfo: "",
      box5LResult: "",
    }

    if(source.isStep4Required == true)
    {
        if(source.results.stepResults.BoxJ)
        {
          result.txt4JI = source.results?.stepResults?.BoxI;
          result.txt4JE = source.results?.stepResults?.BoxE;
          result.boxJResult = source.results?.stepResults?.BoxJ;
        }

        if(source.results.stepResults.BoxK)
        {
          result.txt4KE = source.results?.stepResults?.BoxE;
          result.txt4KI = source.results?.stepResults?.BoxE;
          result.boxKResult = source.results?.stepResults?.BoxK;
        }
    }

    if(source.results.stepResults.BoxL)
    {
        if(source.results.stepResults.TotalinstitutionalCharges)
        {
          result.boxLResult = source.results.stepResults.TotalinstitutionalCharges;
        }

        result.txt5BoxH = source.results?.stepResults?.BoxH;
        result.boxMResult = source.results?.stepResults?.BoxM;
        result.txt5BoxL = source.results?.stepResults?.Box5LResult;
        result.txt5BoxM = source.results?.stepResults?.BoxM;
        result.boxNResult = source.results?.stepResults?.BoxN;
        result.boxOResult = source.results?.stepResults?.BoxO;

    }

    if(source.results.stepResults.BoxR)
    {
      result.txt8boxB = source.results?.stepResults?.BoxB;
      result.txt8P = source.results?.stepResults?.BoxP;
      result.boxRResult = source.results?.stepResults?.BoxR;
    }

    if(source.isStep9Required == true)
    {
      result.txt9BoxQ = source.results?.stepResults?.BoxQ;
      result.txt9BoxR = source.results?.stepResults?.BoxR;
      result.boxSResult = source.results?.stepResults?.BoxS;
      result.txt9BoxF = source.results?.stepResults?.BoxF;
      result.boxTResult = source.results?.stepResults?.BoxT;
      result.txt9Boxs = source.results?.stepResults?.BoxS;
      result.txt9BoxT = source.results?.stepResults?.BoxT;
      result.boxUResult = source.results?.stepResults?.BoxU;
    }

    if(source.isStep10Required == true)
    {
      result.pellGrantAmountToReturn= source.grants?.find(grant => grant.grantName === "Pell")?.amountToBeReturned ?? "";
      result.fseogAmountToReturn= source.grants?.find(grant => grant.grantName === "FSEOG")?.amountToBeReturned ?? "";
      result.teachGrantAmountToReturn= source.grants?.find(grant => grant.grantName === "TEACHGrant")?.amountToBeReturned ?? "";
      result.iraqAfgGrantAmountToReturn= source.grants?.find(grant => grant.grantName === "IraqAfghanistanServiceGrant")?.amountToBeReturned ?? "";
    }

    return result;
}