import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
      color: "black",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
  override: boolean;
}

const ResultsTab3 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const {model, setModel} = props;
  const [override, setOverride] = React.useState<boolean>(props.override);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setOverride(props.override);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>3. Amount of Title IV Aid Earned by the Student</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    Multiply the percentage of Title IV aid earned (Box H) by
                    the total of the Title IV aid disbursed and the Title IV aid
                    that could have been disbursed for the period (Box G).
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box H</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell  align="center">
                          <b>Box G</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxHResult"
                            key="boxHResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxHResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxHResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.rightLabel}>%</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxHResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxHResult
                                ? (data.errors.boxHResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>x</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxGResult"
                            key="boxGResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxGResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxGResult", v.target.value);
                            }}
                            error={!!data.errors.boxGResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxGResult
                                ? (data.errors.boxGResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxIResult"
                            key="boxIResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                             disabled={override == true ? false : true}
                            defaultValue={model?.boxIResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxIResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}><b>I.</b></div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxIResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxIResult
                                ? (data.errors.boxIResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab3;
