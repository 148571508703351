import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Paper,
  Link,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as chargeDefinitionApi from "../../../api/setup/chargeDefinition/chargeDefinitionApi";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
  })
);

function ChargeDefinitionGrid(props: any) {
  const classes = useStyles();
  const [isActive, setIsActive] = React.useState<boolean>(false);

  const editChargingDefinition = (definitionId: any) => {
    props.editChargingDefinition(definitionId);
  };

  return (
    <Card className={classes.cardSeparator}>
      <TableContainer
        component={Paper}
        style={{ height: 300, overflowY: "scroll" }}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Charge Definition</TableCell>
              <TableCell align="left">Program Version</TableCell>
              <TableCell align="left">
                <FormControlLabel
                  id="active"
                  className={classes.switch}
                  labelPlacement="start"
                  label="Show All"
                  control={
                    <Switch
                      checked={isActive}
                      name="active"
                      onChange={(e: any) => {
                        setIsActive(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                />
              </TableCell>
              <TableCell align="left">Increment Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.definitionGridData != null &&
            props.definitionGridData.length > 0 ? (
              props.definitionGridData.filter((row: any) => isActive == false ? row.status == "Active" : true).map((row: any) => {
                return (
                  <TableRow>
                    <TableCell align="left">
                      <Link
                        component="button"
                        color="primary"
                        onClick={() => {
                          editChargingDefinition(row.chargeDefinitionId);
                        }}
                      >
                        {row.chargeDefinitionDescription}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <ProgramVersionAutoComplete
                        name="programId"
                        label={" "}
                        key={row.chargeDefinitionId}
                        campusId={props.campusId}
                        readonly={true}
                        disableClearable={true}
                        filterHandle={(value: any) => {}}
                        chipSize={"small"}
                        maxTags={2}
                        valueFilter={
                          row.chargeDefinitionProgramVersion?.length > 0
                            ? {
                                key: "value",
                                values: row.chargeDefinitionProgramVersion.map(
                                  (item: any) => item.programVersionId
                                ),
                              }
                            : undefined
                        }
                        multiple={true}
                        showInactivePrograms={true}
                      />
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">{row.incrementType}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  No records to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
export default ChargeDefinitionGrid;
