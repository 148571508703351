import API from "../../apiWrapper";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import { ChargeDefinitionVersion } from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import { start } from "repl";

export const getChargeDefinitionList = (CampusId: string) => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetChargeDefinitionList", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getChargeDefinitionByStuEnrollId = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetChargeDefinitionByStuEnrollId", {
      params: {
        stuEnrollId: stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getChargeDefinitionById = (ChargeDefinitionId: string) => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetChargeDefinitionById", {
      params: {
        ChargeDefinitionId: ChargeDefinitionId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const UpsertChargeDefinition = (model: ChargeDefinition) => {
  return API()
    .post("/AcademicRecords/ChargeDefinition/UpsertChargeDefinition", model, {
      responseType: "json",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const UpsertStudentChargeDefinition = (stuEnrollId:string, model: ChargeDefinition) => {
  return API()
    .post(`/AcademicRecords/ChargeDefinition/UpsertStudentChargeDefinition?stuEnrollId=${stuEnrollId}`, model, {
      responseType: "json",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const UpsertChargeDefinitionVersion = (model: ChargeDefinitionVersion) => {
  return API()
    .post("/AcademicRecords/ChargeDefinition/UpsertVersion", model, {
      responseType: "json",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};


export const GetChargeDefinitionVersionById = (ChargeDefinitionId: string) => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetChargeDefinitionVersionById", {
      params: {
        ChargeDefinitionId: ChargeDefinitionId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};


export const GetLatestChargeDefinitionVersionByProgramVersionId = (prgVerId: string, campusId: string, startDate: any, enrollmentDate: any) => {
  return API()
    .get("/AcademicRecords/ChargeDefinition/GetLatestChargeDefinitionVersionByProgramVersionId", {
      params: {
        prgVerId: prgVerId,
        campusId: campusId,
        startDate: startDate,
        enrollmentDate: enrollmentDate,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
