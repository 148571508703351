import { EmptyGuid } from "../../../utils/constants";

export interface ITransactionCode {
    transCodeId: string;
    transCodeCode: string;
    statusId: string;
    status: string;
    transCodeDescrip: string;
    campGrpId: string;
    billTypeId: string;
    defEarnings: boolean;
    isInstCharge: boolean;
    sysTransCodeId?: number;
    is1098T: boolean;
    creditDebitId?: number;
    isDeposit?: boolean;
}

export function createDefaultTransactionCode(): ITransactionCode {
    return {
        transCodeId: EmptyGuid,
        transCodeCode: '',
        statusId: '',
        status: '',
        transCodeDescrip: '',
        campGrpId: '',
        billTypeId: '',
        defEarnings: false,
        isInstCharge: false,
        sysTransCodeId: undefined,
        is1098T: false,
        creditDebitId: undefined,
        isDeposit: false,
    };
}