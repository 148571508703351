import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Button,	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, } from "@material-ui/core";

import { useSelector } from "react-redux";
import { useForm, FormContext, FieldError } from "react-hook-form";

import {
  IStudentInfo,
  IStudentInfoGeneral,
  IStudentInfoDemographics,
  IStudentInfoAdmissions,
  NewIStudentInfoModel,
} from "../../../interfaces/student/profile/studentInfo/IStudentInfo";
import { BestContactInfo } from "../../../interfaces/student/profile/contactInfo/BestContactInfo";

import General from "../profile/studentInfo/General";
import BestPhone from "../profile/contactInfo/BestPhone";
import BestEmail from "../profile/contactInfo/BestEmail";
import BestAddress from "../profile/contactInfo/BestAddress";

import * as studentApi from "../../../api/search/studentSearchApi";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    contactInfo: {
      marginTop: theme.spacing(2),
    },
    contactInfoHeader: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
      marginRight: theme.spacing(1),
    },
  })
);

type StudentInfoProps = {
  activeStep: number;
  steps: number;
  isTransferredStudent?: boolean;
  handleBack: () => void;
  handleNext: (
    studentInfo: IStudentInfo,
    contactInfo: BestContactInfo | null
  ) => void;
  isNewEnrollment: boolean;
  leadId?: string;
  confimationModalProps?: any;
  setConfirmationModalProps?: any;
  setStudentName?: any;
  studentInfoModelPassthrough?: any;
  studentContactModelPassthrough?: any;
  emailAlert?:any;
  setEmailAlert?:any;
  emailAlertMessage?:any;
  setEmailAlertMessage?:any;
  bestEmailTemp?:any;
  setBestEmailTemp?:any;
  
};

const StudentInfo = (props: StudentInfoProps) => {
  const classes = useStyles({});
  const { leadId, studentInfoModelPassthrough, studentContactModelPassthrough } = props;
  const selectedStudent = useSelector((state: any) => state.student);
  const methods = useForm<any>({
    mode: "onBlur",
  });
  const { handleSubmit } = methods;
  const [refreshPhoneData, setRefreshPhoneData] = useState<boolean>(false);
  const [refreshEmailData, setRefreshEmailData] = useState<boolean>(false);
  const [refreshAddressData, setRefreshAddressData] = useState<boolean>(false);
  const [studentInfoModel, setStudentInfoModel] = React.useState<IStudentInfo>(
    NewIStudentInfoModel() as IStudentInfo
  );
  const forStudentInfoPage = React.useRef(true);
  
  const loadFormData = () => {
    if (
      (selectedStudent &&
        selectedStudent.studentId &&
        props.isTransferredStudent == true) ||
      props.isNewEnrollment === false ||
      !!leadId
    ) {
      if (!!leadId) {
        studentApi.GetStudentProfileInfo(leadId, true).then(
          (response: any) => {
            if (!response || !response.result) {
              return;
            }
            let data = response.result;
            let enrollmentProgramData: IStudentInfo = {
              ...data,
            };
            setStudentInfoModel(enrollmentProgramData);
          },
          (exception: any) => { }
        );
      } else {
        studentApi.GetStudentsProfileInfo([selectedStudent.studentId], true).then(
          (response: any) => {
            if (!response || !response.result) {
              return;
            }
            let data = response.result[0];
            let enrollmentProgramData: IStudentInfo = {
              ...data,
            };
            setStudentInfoModel(enrollmentProgramData);
          },
          (exception: any) => { }
        );
      }
    } else {
      setStudentInfoModel(NewIStudentInfoModel() as IStudentInfo);
    }
    setRefreshAddressData(true);
    setRefreshEmailData(true);
    setRefreshPhoneData(true);
  };

  React.useEffect(() => {
    props.setStudentName(studentInfoModel?.firstName + " " + studentInfoModel?.lastName);
  }, [studentInfoModel?.firstName, studentInfoModel?.lastName]);

  React.useEffect(() => {
    if (studentInfoModelPassthrough) {
      setStudentInfoModel(studentInfoModelPassthrough);
    }
  }, [studentInfoModelPassthrough]);

  React.useEffect(() => {
    if (studentContactModelPassthrough) {
      setBestEmail(studentContactModelPassthrough);
      setBestAddress(studentContactModelPassthrough);
      setBestPhone(studentContactModelPassthrough);
    }
  }, [studentContactModelPassthrough]);

  React.useEffect(() => {
    loadFormData();
    setshowOffEyeIcon(!props.isNewEnrollment)
  }, [selectedStudent, props.isNewEnrollment, leadId]);

  const [showOffEyeIcon, setshowOffEyeIcon] = React.useState<boolean>(
    !props.isNewEnrollment
  );
  const [
    contactInfoData,
    setcontactInfoData,
  ] = React.useState<BestContactInfo | null>(null);

  const setGeneral = (data: IStudentInfoGeneral | null) => {
    setStudentInfoModel((state: any) => {
      return { ...state, ...data };
    });
  };

  const setBestEmail = (BestEmail: BestContactInfo) => {
    setcontactInfoData((state: BestContactInfo | null) => {
      return { ...state, ...BestEmail };
    });
  };
  const setBestPhone = (BestPhone: BestContactInfo) => {
    setcontactInfoData((state: BestContactInfo | null) => {
      return { ...state, ...BestPhone };
    });
  };

  const setBestAddress = (BestAddress: BestContactInfo) => {
    setcontactInfoData((state: BestContactInfo | null) => {
      return { ...state, ...BestAddress };
    });
  };

  const onSubmit = (data: any) => {
    methods.triggerValidation().then((valid) => {
      let checkInternationalStudent = true
      if (studentInfoModel.maskedSSN != null) {
        if (studentInfoModel.maskedSSN.length > 9) {
          checkInternationalStudent = false
        }
      }
      if (checkInternationalStudent) {
        handleIsInternationalStudent(studentInfoModel.ssn)
      }

      if (valid && methods.errors.ssn === undefined) {
        props.handleNext(studentInfoModel, contactInfoData);
      } else if (methods.errors.ssn !== undefined) {
        methods.setError(
          "ssn",
          "ssn",
          (methods.errors.ssn as FieldError).message
        );
      }
    });
  };
  const handleIsInternationalStudent = (value: string) => {
    let checkSsn = value ? value.replace(/-/g, "") : "";
    let model = studentInfoModel
    if (
      (checkSsn.trim().length === 0 || checkSsn.trim() === "*****") &&
      (model.isInternationalStudent === false || model.isInternationalStudent === undefined || model.isInternationalStudent === null)
    ) {

      methods.setError("ssn", "ssn", "SSN Required for non-International Students");
      methods.errors.ssn = true

    }
    else if (checkSsn.trim().length !== 9 && checkSsn.trim().length > 0) {
      methods.setError("ssn", "ssn", "SSN is not in correct format");
      methods.errors.ssn = true

    }

    else {
      methods.clearError("ssn");

    }
  };

  const handleCancel = () => {
    props.setConfirmationModalProps((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to reset your changes?",
        confirmationButton: "Yes",
        title: "Confirmation",
        onConfirmEvent: () => {
          loadFormData();
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };
  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.cardSeparator}>
          <General
            isNewEnrollment={props.isNewEnrollment}
            model={studentInfoModel as IStudentInfoGeneral}
            setModel={setGeneral}
            showOffEyeIcon={showOffEyeIcon as boolean}
            setshowOffEyeIcon={setshowOffEyeIcon}
          />
        </div>
        <div className={classes.contactInfo}>
          <Typography className={classes.contactInfoHeader}>
            Contact Info
          </Typography>

          <div className={classes.cardSeparator}>
            <BestPhone
              isNewEnrollment={props.isNewEnrollment}
              setPhoneModel={setBestPhone}
              leadId={leadId}
              isTransferredStudent={props.isTransferredStudent}
              refreshData={refreshPhoneData}
              setRefreshData={setRefreshPhoneData}
              bestPhoneModelPassthrough={contactInfoData}
            />
          </div>
          <div className={classes.cardSeparator}>
            <BestEmail
              isNewEnrollment={props.isNewEnrollment}
              leadId={leadId}
              setEmailModel={setBestEmail}
              isTransferredStudent={props.isTransferredStudent}
              refreshData={refreshEmailData}
              setRefreshData={setRefreshEmailData}
              bestEmailModelPassthrough={contactInfoData}
              emailAlert={props.emailAlert}
              setEmailAlert={props.setEmailAlert}
              emailAlertMessage={props.emailAlertMessage}
              setEmailAlertMessage={props.setEmailAlertMessage}
              bestEmailTemp={props.bestEmailTemp}
              setBestEmailTemp={props.setBestEmailTemp}
              forStudentInfoPage={forStudentInfoPage}
            />
          </div>
          <div>
            <BestAddress
              isNewEnrollment={props.isNewEnrollment}
              leadId={leadId}
              setAddressModel={setBestAddress}
              isTransferredStudent={props.isTransferredStudent}
              refreshData={refreshAddressData}
              setRefreshData={setRefreshAddressData}
              bestAddressModelPassthrough={contactInfoData}
            />
          </div>
        </div>
        <div>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            className={classes.button}
          >
            Next
          </Button>
          <Button
            onClick={handleCancel}
            className={classes.button}
            color="secondary"
            variant="contained"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormContext>
  );
};
export default StudentInfo;
